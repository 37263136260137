import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAPIPost } from '../API';
import { useLocalizedStrings } from '../Localization';
import { AddPhoneDashes, useLinksOnLocalizedStrings, SpacedSpinner } from '../Util';

function DataRequestSender(props: { 
  uid: string, 
  applicantInfo: Record<string, string>,
  refreshApplicant: () => void}) {

  const L = useLocalizedStrings();
  const dataRequest = useAPIPost("/applicant/" + props.uid + "/request_previous_data");
  const [sending, setSending] = useState(false);

  async function doRequestData(do_email?: boolean) {
    setSending(true);
    const result = await dataRequest({do_email});
    if (result && result.status && result.status === 'text sent') {
      props.refreshApplicant();
    }else if(result && result.status && result.status.indexOf('No phone') !== -1) {
      toast.error(result.status);
    }else if (result && result.status && result.status === 'email sent') {
      props.refreshApplicant();
    }
    setSending(false);
  }

  return (
    <Dropdown>
      <Dropdown.Toggle>
        {sending && <SpacedSpinner />}
        <span>{props.applicantInfo['data_request'] ? L.applicant.data_request.resend_code : L.applicant.data_request.submit_request}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => doRequestData(false)}>
          <span>{L.applicant.data_request.send_via_text}</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => doRequestData(true)}>
          <span>{L.applicant.data_request.send_via_email}</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>)
}

export function DataRequestBox(props: {
  uid: string,
  applicant: any,
  applicantInfo: Record<string, string>,
  refreshApplicant: () => void
}) {

  const L = useLocalizedStrings();
  const links = useLinksOnLocalizedStrings(L.applicant.data_request.data_is_from_previous_program, 
    `https://${props.applicantInfo['previous_deployment']}.aidkit.org/applicant/${props.applicantInfo['previous_app']}`);

  const boxColor = !props.applicantInfo["data_request"] || 
        props.applicantInfo["data_request"] !== 'imported' ? 'bg-yellow-50' : 'bg-green-50';

  const importData = useAPIPost("/applicant/" + props.uid + "/import_data");
  const [importing, setImporting] = useState(false);
  const [dataCode, setDataCode] = useState('');

  async function doImportData() {
    setImporting(true);
    const result = await importData({code: dataCode});
    setImporting(false);
    if (result && result.status){
      props.refreshApplicant();
    }else if(result && result.error){
      // toast.error(result.message || result.error);
    }
  }

  return (
    <div className={`${boxColor} p-4 mb-2`}>
      {(!props.applicantInfo["data_request"] || props.applicantInfo["data_request"] === 'expired') && (
        <>
          <h5>{L.applicant.data_request.submit_request_title}</h5>
          <span>{L.applicant.data_request.submit_request_body}</span>
          <br/>
          <div className={'mt-2'}>
            <DataRequestSender applicantInfo={props.applicantInfo} uid={props.uid} refreshApplicant={props.refreshApplicant} />
          </div>
        </>
      )}
      {props.applicantInfo["data_request"] && props.applicantInfo["data_request"] === 'sent' && (
        <>
          <h5>{L.applicant.data_request.import_in_progress}</h5>
          <span>{L.applicant.data_request.we_have_sent_the_code.replace('%v',AddPhoneDashes(props.applicantInfo['previous_phone']))}</span>
          <br/>
          <div className={'mt-2'}>
            <div className={'w-50 inline-flex'}>
              <input onChange={(e) => setDataCode(e.target.value)} className={'form-control'} />&nbsp;
            </div>
            <Button onClick={doImportData} className={'inline-flex'}>
              {importing && <SpacedSpinner />}<span>{L.applicant.data_request.finish_import}</span>
            </Button>
            <div className={'mt-2'}>
              <DataRequestSender applicantInfo={props.applicantInfo} uid={props.uid} refreshApplicant={props.refreshApplicant} />
            </div>
          </div>
        </>
      )}
      {props.applicantInfo["data_request"] && props.applicantInfo["data_request"] === 'imported' && (
        <>
          <h5>{L.applicant.data_request.applicant_based_off_data.replace('%v',props.applicantInfo["previous_deployment"])}</h5>
          <span>{links}
          </span>
        </>
      )}
    </div>
  )

}