import { useRef, useState, useContext, useMemo } from "react";
import { usePost } from "../API";
import { QuestionProps } from "./Props";
import { useLocalizedStrings } from "../Localization";
import InterfaceContext, { AuthContext } from "../Context";
import { ValidatorMessage } from "../Components/ValidatorMessage";
import { languageContent, saveKeyToCookieAndRelocateToUrl, safeParse } from "../Util";
import { CandidateChooser } from "../Components/CandidateChooser";
import * as v0 from "@aidkitorg/types/lib/survey";

export function LoginWidget(props: QuestionProps) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const auth = useContext(AuthContext);

  const [contact, setContact] = useState('');
  const [resuming, setResuming] = useState(false);
  const [challenge, setChallenge] = useState<string | undefined>('');
  const getApplicantCandidates = usePost("/applicant/get_candidates", {
    token: () => {
      return token.current;
    }
  });
  const initialLoginCandidates = [] as Awaited<ReturnType<typeof getApplicantCandidates>>['candidates'];
  const [loginCandidates, setLoginCandidates] = useState(initialLoginCandidates);
  const [showCandidates, setShowCandidates] = useState(false);
  const [dialogDescription, setDialogDescription] = useState<undefined | v0.RichText>(undefined);

  const token = useRef('')

  const rawMetadata = props["Metadata"]!;
  const metadata: v0.LoginWidget = useMemo(() => safeParse(rawMetadata), [rawMetadata]);

  const sendConfirmation = usePost("/confirm/phone");
  const checkCode = usePost("/confirm/phone_code");
  const redirect = usePost("/subsurvey/redirect", {
    token: () => {
      return token.current;
    }
  });
  const resume = usePost("/subsurvey/resume", {
    token: () => {
      return token.current;
    }
  })

  const [code, setCode] = useState('');
  const [validationError, setValidationError] = useState(''); // for storing user errors for better UX

  const contactRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);

  const phoneOnly = (props["Additional Options"] || []).indexOf("Phone Only") !== -1;

  if (props.Viewer === 'screener') {
    return <></>;
  }

  return <>
    {loginCandidates && showCandidates ?
      <CandidateChooser label={dialogDescription?.[context.lang] || L.apply.multiple_apps_found}
        candidates={loginCandidates.map(c => {
          return {
            ...c,
            onClick: async () => {
              token.current = c.resumeToken;
              const previous = await redirect({
                form_name: metadata.page
              });

              if (previous && previous.length > 0) {
                saveKeyToCookieAndRelocateToUrl(previous[0].link);
              } else {
                // Here we throw because this should never happen
                throw new Error("Unable to redirect");
              }
            }
          }
        })}
        onClose={() => {
          setResuming(false);
          setChallenge(undefined);
          setCode('');
          setLoginCandidates(initialLoginCandidates);
          setShowCandidates(false);
        }}
      /> : null}
    {!resuming &&
      <>
        <button
          type="button"
          onClick={(e) => {
            setResuming(true);
            setTimeout(() => {
              contactRef.current!.focus();
            }, 100)
          }}
          className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {props[languageContent(context.lang)]}
        </button></>}

    {resuming && !challenge &&
      <form onSubmit={async (e) => {
        e.preventDefault();
        const challenge = await sendConfirmation({
          phone: contact,
          language: context.lang
        })
        console.log("challenge", challenge)
        if (challenge.token) {
          setChallenge(challenge.token);
          setTimeout(() => {
            codeRef.current!.focus();
          }, 100)
        }
      }}>
        <fieldset className="mt-2">
          <legend>{L.apply.please_enter_previous}</legend>
          <input
            ref={contactRef}
            value={contact}
            onChange={(e) => { setContact(e.target.value); }}
            placeholder={phoneOnly ? "Phone" : "Email or Phone"}
            className="max-w-md block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400" />
        </fieldset>
        <button
          className={`inline-flex items-center mr-2 mt-2 px-4 py-2 border border-transparent 
                    ${!contact || (contact.length !== 10 && !contact.includes('@')) ? 'disabled:bg-gray-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none' :
                        'hover:bg-indigo-700 bg-indigo-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'}
                    text-sm font-medium rounded-md shadow-sm text-white `}
          type="submit"
          disabled={!contact || (contact.length !== 10 && !contact.includes('@'))}
          onSubmit={(e) => e.preventDefault()}>{L.apply.send_confirmation_code}</button>
        <button
          className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={(e) => setResuming(false)}>{L.apply.cancel}</button>
        {contact !== "" && contact.length !== 10 && !contact.includes('@') && <ValidatorMessage message={L.questions.textentry.please_enter_valid_us_number} />}
      </form>
    }
    {challenge &&
      <form onSubmit={async (e) => {
        e.preventDefault();
        if (code.length !== 6) {
          setValidationError(L.questions.contact_confirmation.code_is_not_6_digits);
          return;
        }
        const result = await checkCode({
          token: challenge,
          answer: code
        })
        if (result.attestation) {
          token.current = result.attestation;

          // Find candidates
          const resp = await getApplicantCandidates({
            info: props.info,
            mode: 'login',
          });

          const { candidates } = resp;

          setLoginCandidates(candidates);
          setDialogDescription(resp.dialogDescription);
          const anyCandidateHasSubsurveys = candidates.some(c => c.subsurveys?.length);

          if (candidates.length && (anyCandidateHasSubsurveys || candidates.length > 1)) {
            setShowCandidates(true);
          } else if (candidates.length === 1) {
            token.current = candidates[0].resumeToken;
            // Allow login widget to resume an in-progress app
            // TODO: apply this logic to the candidate chooser as well
            if (candidates[0].applicant?.kind === 'pending') {
              if (window.location.pathname.startsWith('/p/')) {
                alert('We are processing your application. Thank you for your patience.')
                setResuming(false);
                setChallenge(undefined);
                setCode('');
                return;
              }
              const previous = await resume({ form_name: 'apply' });
              if (previous?.info) {
                props.LoadInfo!(previous.info, token.current);
                alert(L.questions.contact_confirmation.confirmation_successful_also_save)
              } else {
                window.confirm(L.questions.contact_confirmation.no_app_to_resume);
              }
              setResuming(false);
              setChallenge(undefined);
              setCode('');
              return;
            }

            const previous = await redirect({
              form_name: metadata.page,
              ...(('id' in metadata && metadata.id) ? { loginNodeId: metadata.id } : {}),
            });

            if (previous && previous.length > 0) {
              saveKeyToCookieAndRelocateToUrl(previous[0].link);
            } else {
              alert('No submissions found')
              setResuming(false);
              setChallenge(undefined);
              setCode('');
            }
          } else if (candidates.length === 0) {
            alert('No submissions found')
            setResuming(false);
            setChallenge(undefined);
            setCode('');
          }
        } else {
          if (result.error === 'challenge_expired') {
            setChallenge(undefined);
            setCode('');
          }
          // AK already toasts
          //toast(result.error);
        }
      }}>
        <fieldset>
          <legend>{L.apply.please_enter_code}</legend>
          <input
            ref={codeRef}
            value={code}
            autoComplete="one-time-code"
            onChange={(e) => {
              const value = (e.target.value || '').replace(/[^0-9]/g, '');
              if (value.length === 6) {
                setValidationError('');
              }
              setCode(value);
            }}
            placeholder="6-digit code"
            className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400" />
        </fieldset>
        {validationError && <ValidatorMessage message={validationError} />}
        <button
          className="inline-flex items-center mr-2 mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          type="submit" onSubmit={(e) => e.preventDefault()}>{L.apply.confirm_code}</button>
        <button
          className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={(e) => { setCode(''); setChallenge(undefined); setResuming(false) }}>{L.apply.cancel}</button>
      </form>
    }
  </>
}