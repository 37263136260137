import React, { useState, useEffect, useRef, useContext, useMemo, ReactElement } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAPIPost, usePost } from "../API";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionProps } from "./Props";

import { languageContent, safeParse } from "../Util";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";
import InterfaceContext from "../Context";
import { useLocalizedStrings } from "../Localization";
import { legalNameValidator } from "../utils/nameValidator";
import { ValidatorMessage } from "../Components/ValidatorMessage";
import { CheckCircleIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { ButtonGroup } from "react-bootstrap";
import { lang } from "moment";

const inputClasses = "block w-full rounded rounded-l-md border-0 py-1.5 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm";

function BaseTextEntry(props: QuestionProps & ({ 
  validator?: (v: string, p: typeof props['info']) => boolean, 
  sanitizer?: (v: string) => string, 
  textarea?: boolean, 
  noLabel?: boolean,
  display?: (v: string) => string,
  prefix?: string,
  onBlurFormatter?: (v: string) => string,
})) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);

  const marked = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
    replacer: (str) => str.replace(' **\n', '**\n').replace('^** ', '**')
  });
  
  const val = useMemo(() => {
    return props.validator || ((v: string, p: typeof props['info']) => true);
  }, []);

  const [valid, setValid] = useState(val(props.info[props["Target Field"]!] || "", props.info));

  const [pending, setPending] = useState("");

  // For some reason conditioning these only on [props.info] causes us to end up with stale validity data
  useEffect(() => {
    const newValid = val(props.info[props["Target Field"]!] || "", props.info);
    if (newValid !== valid) {
      setValid(newValid);
    }
  }, [props, valid]);

  if ((props["Additional Options"] || []).indexOf("Hidden") !== -1) {
    return <></>
  }

  let formatted = false;
  if ((props["Additional Options"] || []).indexOf("Formatted") !== -1) {
    formatted = true;
  }

  const inputElement = React.createElement(props.textarea === true ? 'textarea' : 'input', {
    className: 
        (props.extraClass || "") +
        (props.prefix ? "block w-full rounded-md shadow-sm border-solid py-2 pl-7 pr-12 sm:text-sm sm:leading-6" : "") +
        (!props.prefix ? " max-w-lg block w-full shadow-sm border-solid mt-1 mb-1 p-2 sm:max-w-xs sm:text-sm rounded-md" : "") + (
          pending === "" && (valid || (props["Additional Options"] || []).indexOf("Optional") !== -1)
            ? ((pending || props.info[props["Target Field"]!]) ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
              " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")
            : " border-2 border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400"),
    value: props.display ? props.display(pending || props.info[props["Target Field"]!] || "") : (pending || props.info[props["Target Field"]!] || ""),
    onBlur: (e: any) => {
      if (props.onBlurFormatter) {
        const formatted = props.onBlurFormatter(pending || props.info[props["Target Field"]!] || "");
        const valid = val(formatted, props.info);
        if (formatted === '' || (formatted !== props.info[props["Target Field"]!])) {
          valid ? 
            props.setInfoKey(props["Target Field"]!, formatted, valid, false) : 
            (props.info[props["Target Field"]!] !== '' && props.setInfoKey(props["Target Field"]!, '', valid, false))
        }
        setValid(valid);
        valid ? setPending("") : setPending(formatted);
      }
    },
    onChange: (e: any) => {
      const sanitized = (props.sanitizer || ((v) => v))(e.target?.value || "");
      const valid = val(sanitized, props.info);
      if (sanitized === '' || (sanitized !== props.info[props["Target Field"]!])) {
        //console.log("Setting", sanitized, valid);
        if (valid) {
          props.setInfoKey(props["Target Field"]!, sanitized, valid, false);
        } else {
          if (props.info[props["Target Field"]!] !== '') {
            props.setInfoKey(props["Target Field"]!, '', valid, false);
          }
        }
      } 
      setValid(valid);
      if (!valid) {
        setPending(sanitized);
      } else {
        setPending("");
      }
    },
    required: (props["Additional Options"] || []).indexOf("Optional") === -1 ? true : undefined,
    placeholder: props.placeholder || ""
  })

  return (
    <fieldset>
      {!props.noLabel &&
        <legend>
          {formatted ? marked : 
            <b>{props[languageContent(context.lang)]}</b>}
        </legend>
      }
      <div className="flex flex-row">
        {props.prefix ?
          <div className="relative mt-1 mb-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            {inputElement}
          </div>
          : inputElement}
        
      </div>
      {pending !== "" && !valid && <ValidatorMessage message={props.validatorMessage as string | undefined || L.questions.textentry.this_field_is_required} />}
    </fieldset>
  );
}

function clearConfirmedContact(props: QuestionProps) {
  const contactKey = props["Target Field"]!
  const confirmedFieldName =
    safeParse(props.info[`${props["Target Field"]!}_confirmed_info_`] || "{}").field
    || safeParse(props.Metadata || '{}').confirmation_field;

  props.setInfoKey(confirmedFieldName, '', false, false);
  props.setInfoKey(contactKey, '', false, false);

  window.localStorage.removeItem('challenge_contact');
};

function PhoneNumberQuestion(props: QuestionProps) {
  const L = useLocalizedStrings();
  const confirmedFieldName =
    // The Contact Confirmation question now saves the associated confirmation field name in a "confirmed_info_" key
    safeParse(props.info[`${props["Target Field"]!}_confirmed_info_`] || "{}").field
    // The field name can be added to metadata (handles contact info confirmed before that change)
    || safeParse(props.Metadata || '{}').confirmation_field;
  
  return React.createElement(BaseTextEntry, {
    ...props,
    placeholder: "555-555-5555",
    sanitizer: (v) => {
      const shouldConfirmChange = props.info[props["Target Field"]!] && props.info[confirmedFieldName];
      if (shouldConfirmChange) {
        if (window.confirm(L.questions.contact_confirmation.are_you_sure_you_want_to_clear)) {
          clearConfirmedContact(props);
          return '';
        }
        return props.info[props["Target Field"]!];
      }
      return v.replace(
        /^([0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?)(.*)$/g,
        "$1"
      );
    },
    validator: (v) => v.length >= 10 && v.length <= 12,
    validatorMessage: L.questions.textentry.please_enter_valid_us_number
  });
}

function EmailQuestion(props: QuestionProps) {
  const L = useLocalizedStrings();
  const confirmedFieldName =
    safeParse(props.info[`${props["Target Field"]!}_confirmed_info_`] || '{}').field
    || safeParse(props.Metadata || '{}').confirmation_field;

  return React.createElement(BaseTextEntry, {
    ...props,
    placeholder: "user@domain.com",
    sanitizer: (v) => {
      const shouldConfirmChange = props.info[props["Target Field"]!] && props.info[confirmedFieldName];
      if (shouldConfirmChange) {
        if (window.confirm(L.questions.contact_confirmation.are_you_sure_you_want_to_clear)) {
          clearConfirmedContact(props);
          return '';
        }
        return props.info[props["Target Field"]!];
      }
      return v.toLowerCase().replace(/[,\s]/g,"");
    },
    validator: (v) => !!v.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
    validatorMessage: L.questions.textentry.must_be_a_valid_email,
  });
}

function BankRoutingNumberQuestion(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const aba = useAPIPost("/abanumber");

  const [updateTimer, setUpdateTimer] = useState<typeof setTimeout | null>(
    null
  );
  const [number, setNumber] = useState(props.info[props["Target Field"]!]);
  const [lastNumber, setLastNumber] = useState(props.info[props["Target Field"]!]);
  const [result, setResult] = useState({} as any);

  const [bankName, setBankName] = useState(props.info[props["Target Field"]! + "_bankname"]);

  const numberRef = useRef(number);

  useEffect(() => {
    numberRef.current = number;

    // Pull any changes from remove if we haven't modified anything 
    if (number === lastNumber)  {
      setNumber(props.info[props["Target Field"]!]);
    }
    setLastNumber(props.info[props["Target Field"]!]);
  }, [number, bankName, lastNumber, result.number, result.name, updateTimer, props]);

  function queueCheck(number: string) {
    setNumber(number);
    if (updateTimer) {
      clearTimeout(updateTimer! as any);
    }
    setUpdateTimer(
      (setTimeout(async () => {
        console.log("Checking", numberRef.current);
        // Allow this field to be cleared out
        if (numberRef.current.length === 0) {
          props.setInfoKey(props["Target Field"]!, '', false, false);
          props.setInfoKey(props["Target Field"]! + "_bankname", '', false, false);
          setBankName(undefined);
        }
        if (numberRef.current.length === 9) {
          console.log("Checking", bankName);

          const res = await aba({ number: numberRef.current });
          
          if (res.number === numberRef.current) {
            if (res.valid) {
              props.setInfoKey(
                props["Target Field"]!,
                numberRef.current,
                res.valid,
                false
              );
              props.setInfoKey(
                props["Target Field"]! + "_bankname",
                res.name || "Unknown",
                res.valid,
                false
              );
              setBankName(res.name);
            }

            numberRef.current = undefined;
            setResult({...res, number: undefined});
          }

          setTimeout((null as unknown) as typeof setTimeout);
          setUpdateTimer(null);
        } 
      }, 1000) as unknown) as typeof setTimeout
    );
  }

  // Update bank name if not found and ignore selected to our config here:
  function ignoreNotFound() {
    props.setInfoKey(
      props["Target Field"]! + "_bankname",
      'Unknown',
      true,
      false
    );
    setBankName('Unknown');
  }

  // Show confirmation if:
  // - bank name has not been confirmed by user
  // - bank name found does not match the saved bank info
  // - no bank name found for the entered number
  const bankNameValid = bankName && bankName.length > 0 && bankName !== 'Unknown'

  const showConfirmation =  
    (result.name !== undefined && result.name !== bankName && number.length === 9) || 
    (result.name === undefined && result.valid === true && !bankNameValid);

  const valid = 
    (bankNameValid && props.info[props["Target Field"]!] === number) ||
    (props.info[props["Target Field"]!] === number &&
    (props.info[props["Target Field"]!] || "").length === 9);

  const canIgnore =
    (props["Additional Options"] || []).indexOf("Known Banks Only") === -1;

  const formattedContent = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
  });
  const content = (props["Additional Options"] || []).indexOf("Formatted") !== -1
    ? formattedContent
    : <b>{props[languageContent(context.lang)]}</b>;

  return (
    <>
      {content}
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <input 
          value={number}
          onChange={(e: any) => queueCheck(e.target.value)}
          className={          
            "max-w-lg block w-full shadow-sm border-solid mt-1 mb-1 p-2 sm:max-w-xs sm:text-sm rounded-md"
              + (valid || (props["Additional Options"] || []).indexOf("Optional") !== -1
                ? (valid || props.info[props["Target Field"]!])
                  ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400"
                  :  " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400"
                : " border-2 border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400")
          }
        />
      </div>
      <div>
        {valid && bankNameValid && (
          <>
            <p>({props.info[props["Target Field"]!] + ': ' + bankName})</p>
          </>
        )}
        {showConfirmation && (result.name || bankNameValid) && (
          <>
            <p>
              {L.questions.textentry.if_the_above_is_not_correct}
            </p>
          </>
        )}
        {showConfirmation && (!result.name || !bankNameValid) && (
          <>
            <p>
              <span>{L.questions.textentry.no_bank_name_could_be_found}</span>&nbsp;{number}&nbsp;<span>{L.questions.textentry.please_ensure}</span>
            </p>
            {canIgnore && (
              <Button onClick={ignoreNotFound} variant="warning">{L.questions.textentry.use_entered_number_anyway}</Button>
            )}
          </>
        )}
        {!valid && !showConfirmation && (
          <>
            <p>
              {L.questions.textentry.this_isnt_a_valid_routing_number}
            </p>
          </>
        )}
      </div>
    </>
  );
}

function MultilineQuestion(props: QuestionProps) {
  const L = useLocalizedStrings();
  const { min_length, max_length } = safeParse(props.Metadata || '{}');

  let validator = (v: string) => v.length > 0;

  let validatorMessage = L.questions.textentry.this_field_is_required;
  if (min_length || max_length) {
    const data = getCharLengthValidatorAndMessage(min_length, max_length, L);
    validator = data.validator;
    validatorMessage = data.validatorMessage;
  }

  return React.createElement(BaseTextEntry, {
    ...props,
    sanitizer: (v) => v,
    validator,
    validatorMessage,
    textarea: true,
  });
}

function DateQuestion(props: QuestionProps) {
  const L = useLocalizedStrings();
  return React.createElement(BaseTextEntry, {
    ...props,
    placeholder: "MM/DD/YYYY",
    sanitizer: (v) =>
      v.replace(
        /^([0-1]?[0-9]?(\/[0-3]?[0-9]?)?(\/[1-2]?[0-9]?[0-9]?[0-9]?)?)(.*)$/g,
        "$1"
      ),
    validator: (v) => v.length === 10 && !isNaN((new Date(v)).getDate()),
    validatorMessage: L.questions.textentry.must_be_in_mmddyyyy
  });
}

function EncryptedTextEntry(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();

  const [showEdit, setShowEdit] = useState(
    props.info[props["Target Field"]!] ? false : true
  );
  const [nextValue, setNextValue] = useState("");
  const [nextValueConfirmed, setNextValueConfirmed] = useState("");

  const encrypt = usePost('/encrypt');

  const [cachedValue, setCachedValue] = useState(props.info[props["Target Field"]!]);
  const content = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info
  });

  const [oneError, setOneError] = useState("");
  const [twoError, setTwoError] = useState("");

  const [showValue, setShowValue] = useState(false);

  useEffect(() => {
    if (cachedValue !== props.info[props["Target Field"]!]) {
      if (showEdit && nextValue === '' && nextValueConfirmed === '') {
        setShowEdit(false);
      }
    }
    setCachedValue(props.info[props["Target Field"]!]);
  }, [props, cachedValue, nextValue, nextValueConfirmed, showEdit]);

  function updateValue(value: string, field: 1 | 2) {
    const metadata = safeParse(props["Metadata"] || "{}");
    if (metadata?.integers_only) {
      value = value.replace(/[^0-9]/g, "");
    }
    if (field === 1) {
      setNextValue(value);
    } else {
      setNextValueConfirmed(value);
    }

    // TODO(Riley): this looks identical to the logic in NumberQuestion. verify, then extract it to one place
    let errorMessage = ""; 
    if (metadata?.min_length || metadata?.max_length) {
      if (metadata?.min_length && metadata?.max_length && metadata?.min_length === metadata?.max_length) {
        if (value.length !== metadata.min_length) {
          errorMessage = L.questions.textentry.must_be_x_characters.replace('$x', metadata.min_length);
        }
      }
      // Set an error message if the value is too short or too long
      if (value.length < (metadata?.min_length || -Infinity)) {
        if (!errorMessage) {
          errorMessage = L.questions.textentry.must_be_at_least_x_characters.replace('$min', metadata.min_length);
        }
      } else if (metadata.max_length && value.length > metadata.max_length) {
        if (!errorMessage) {
          errorMessage = L.questions.textentry.must_be_at_most_x_characters.replace('$max', metadata.max_length);
        }
      }
    }
    if (metadata?.startsWith) {
      if (!value.startsWith(metadata.startsWith)) {
        errorMessage = L.questions.textentry.must_start_with_x.replace('$x', metadata.startsWith);
      }
    }
    if (metadata?.doesNotStartWith) {
      if (value.startsWith(metadata.doesNotStartWith)) {
        errorMessage = L.questions.textentry.must_not_start_with_x.replace('$x', metadata.doesNotStartWith);
      }
    }
    
    if (field === 1) {
      setOneError(errorMessage); // Note this will set error to "" if no errors were found.
    } else {
      setTwoError(errorMessage);
    }
  }

  async function saveValue() {
    const encryptedValue = await encrypt({ text: nextValueConfirmed });
    await props.setInfoKey(
      props["Target Field"]!,
      encryptedValue.value,
      true,
      false
    );
    setShowEdit(false);
    setNextValue("");
    setNextValueConfirmed("");
    setShowValue(false);
  }

  let formatted = false;
  if ((props["Additional Options"] || []).indexOf("Formatted") !== -1) {
    formatted = true;
  }

  return (
    <fieldset>
      <legend>
        {formatted ? content : 
          <b>{props[languageContent(context.lang)]}</b>}
      </legend>
      {props.info[props["Target Field"]!] && (
        <div>
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <input 
              value={"(" + L.questions.textentry.encrypted + " " + props.info[props["Target Field"]!] + ")"}
              onChange={(e) => updateValue(e.target.value, 1)}
              name="searchEncrypted" 
              id="searchEncrypted" 
              autoComplete="off"
              disabled={true}
              className={inputClasses} />
          </div>
        </div>
      )}
      {!showEdit && (
        <>
          <br />
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" 
            onClick={() => setShowEdit(true)}>{L.questions.textentry.edit}</button>
        </>
      )}
      {showEdit && (
        <>
          <br />
          <b>{L.questions.textentry.enter_value}</b>
          <div>
            <div className="mt-2 flex border border-solid border-black rounded-md shadow-sm">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <input 
                  type={showValue ? "input" : "password"}
                  value={nextValue}
                  onChange={(e) => updateValue(e.target.value, 1)}
                  name="searchEncrypted" 
                  id="searchEncrypted" 
                  autoComplete="off"
                  className={inputClasses} />
                { showValue 
                  ? <EyeIcon className="mt-1 mr-1 hover:cursor-pointer" height={25} width={25} onClick={() => setShowValue(!showValue)} /> 
                  : <EyeSlashIcon className="mt-1 mr-1 hover:cursor-pointer" height={25} width={25} onClick={() => setShowValue(!showValue)} /> }
              </div>
            </div>
          </div>
          {oneError && <ValidatorMessage message={oneError} />}
          <br />
          <b>{L.questions.textentry.to_confirm}</b>
          <div>
            <div className="mt-2 ring-1 flex border border-solid border-black rounded-md shadow-sm">
              <input 
                type={showValue ? "input" : "password"}
                value={nextValueConfirmed}
                onChange={(e) => updateValue(e.target.value, 2)}
                name="searchEncrypted" 
                id="searchEncrypted" 
                autoComplete="off"
                className={inputClasses} />
              {showValue
                ? <EyeIcon className="mt-1 mr-1 hover:cursor-pointer" height={25} width={25} onClick={() => setShowValue(!showValue)} />
                : <EyeSlashIcon className="mt-1 mr-1 hover:cursor-pointer" height={25} width={25} onClick={() => setShowValue(!showValue)} />}
            </div>
          </div>
          {twoError && <ValidatorMessage message={twoError} />}
          <br />
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={saveValue}
            disabled={
              nextValueConfirmed === "" || nextValue !== nextValueConfirmed || oneError.length > 0 || twoError.length > 0
            }
          >
            {L.questions.textentry.save}
          </button>
          <button className="float-right bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded" 
            onClick={() => setShowValue(!showValue)}>
            {showValue ? L.questions.textentry.hide : L.questions.textentry.show}
          </button>
          {" "}
          <span>
            {nextValueConfirmed !== "" &&
            nextValue !== nextValueConfirmed &&
            L.questions.textentry.values_dont_match}
          </span>
        </>
      )}
    </fieldset>
  );
}

function EncryptedValueVerification(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const [value, setValue] = useState("");
  const verifyEncryptedValue = usePost("/verify_encrypted_value");
  const metadata = safeParse(props["Metadata"] || "{}")
  const fieldToVerify = metadata.sourceField;
  const lastFourOnly = metadata.lastFourOnly;
  const encryptedValue = props.info[fieldToVerify];
  const [showValue, setShowValue] = useState(false);
  const verified = props.info[props["Target Field"]!];

  const handleVerification = async () => {
    const isVerifiedResponse = await verifyEncryptedValue({value, encryptedValue, lastFourOnly});
    props.setInfoKey(props["Target Field"]!, isVerifiedResponse.matched ? 'true' : 'false', false, false);
  };

  const clearEntry = () => {
    setValue("");
    setShowValue(false);
    props.setInfoKey(props["Target Field"]!, '', false, false);
  }

  const content = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info
  });
  const formatted = (props["Additional Options"] || []).indexOf("Formatted") !== -1;

  return (
    <fieldset>
      <legend>
        {formatted ? content : 
          <b>{props[languageContent(context.lang)]}</b>}
      </legend>
      {verified === 'true' ?
        <div>
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-10 w-10 text-green-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <p className="text-md font-medium text-green-800">{L.questions.textentry.value_check_successful}</p>
              </div>
            </div>
          </div>
          <br />
          <Button variant='light' onClick={clearEntry} >
            {L.questions.textentry.clear_entry}
          </Button>
        </div> : 
        <div>
          <div className="mt-2 flex border border-solid border-black rounded-md shadow-sm">
            <input 
              type={showValue ? "input" : "password"}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              name="verifyEncrypted" 
              id="verifyEncrypted" 
              autoComplete="off"
              className={inputClasses} />
            {showValue
              ? <EyeIcon className="mt-1 mr-1 hover:cursor-pointer" height={25} width={25} onClick={() => setShowValue(!showValue)} />
              : <EyeSlashIcon className="mt-1 mr-1 hover:cursor-pointer" height={25} width={25} onClick={() => setShowValue(!showValue)} />}
          </div>
          <br />
          <Button
            onClick={handleVerification}
            disabled={value === ""}
            variant="primary">
            {verified === 'true' ? <CheckCircleIcon className="h-6 text-green-400"/> : L.questions.textentry.check_value}
          </Button>
          {verified === 'false' && <ValidatorMessage message={L.questions.textentry.values_dont_match} />}
        </div>
      }
    </fieldset>
  );
};

function NumberQuestion(props: QuestionProps) {
  const L = useLocalizedStrings();
  const metadata = useMemo(() => props['Metadata'] ? safeParse(props['Metadata']) : {}, []);

  if ((props["Additional Options"] || []).indexOf("Encrypted") !== -1) {
    return React.createElement(EncryptedTextEntry, props);
  }

  let validators: ((v: string) => boolean)[] = [];
  let validatorMessages = [];

  // If length requirements are specified, set a length validator and message
  if (metadata.max_length || metadata.min_length) {      
    if (!metadata.min_length) {
      validators.push((v) => !!v.length && v.length <= metadata.max_length)
      validatorMessages.push(L.questions.textentry.must_be_at_most_x_characters.replace('$max', metadata.max_length.toString()));
    } else if (!metadata.max_length) {
      validators.push((v) => v.length >= metadata.min_length)
      validatorMessages.push(L.questions.textentry.must_be_at_least_x_characters.replace('$min', metadata.min_length.toString()))
    } else if (metadata.min_length === metadata.max_length) {
      validators.push((v) => v.length === metadata.max_length && v.length === metadata.min_length)
      validatorMessages.push(L.questions.textentry.must_be_x_characters.replace('$x', metadata.min_length.toString()))
    } else {
      validators.push((v) => v.length >= metadata.min_length && v.length <= metadata.max_length)
      validatorMessages.push(L.questions.textentry.must_be_between_x_characters.replace('$min', metadata.min_length?.toString()).replace('$max', metadata.max_length.toString()))
    }
  }

  // If min or max requirements are specified, override the length validation
  if (metadata.max || metadata.min) {
    validators = [(v) => v.length > 0 && parseInt(v) >= (metadata.min || -Infinity) && parseInt(v) <= (metadata.max || Infinity)]
    if (!metadata.min) {
      validatorMessages.push(L.questions.textentry.must_be_less_than.replace('$max', metadata.max.toString()))
    } else if (!metadata.max) {
      validatorMessages.push(L.questions.textentry.must_be_greater_than.replace('$min', metadata.min.toString()))
    } else {
      validatorMessages.push(L.questions.textentry.must_be_between.replace('$min', metadata.min.toString()).replace('$max', metadata.max.toString()))
    }
  }

  if (metadata.startsWith) {
    validators.push((v: string) => !!v.length && v.startsWith(metadata.startsWith))
    validatorMessages.push(L.questions.textentry.must_start_with_x.replace('$x', metadata.startsWith))
  }

  if (metadata.doesNotStartWith) {
    validators.push((v: string) => !!v.length && !v.startsWith(metadata.doesNotStartWith))
    validatorMessages.push(L.questions.textentry.must_not_start_with_x.replace('$x', metadata.doesNotStartWith))
  }

  const validator = (v: string) => validators.every((validator) => validator(v));
  const validatorMessage = validatorMessages.join(', ');

  return React.createElement(BaseTextEntry, {
    ...props,
    ...(metadata.format === 'usd' ? { 
      prefix: '$',
      display: (v?: string) => { 
        if (!v) return '';

        const parts = (v || '').toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.'); },
      onBlurFormatter: (v?: string) => {
        if (!v) return "";

        return (!!!metadata.integers_only && v) ? parseFloat(v).toFixed(2) : v;
      },
    } : {}),
    sanitizer: metadata.integers_only
      ? (v) => (parseInt((v.replace(/[.,][0-9]{2}$/, "")).replace(/[-.,]/g, "")) || 0) + ""
      : props.formatUsd || metadata.format === 'usd'
        ? (v) => v
          .replace(/^\.|[^.0-9]/g, "") // remove leading decimal points or any non numeric or decimal point characters
          .split(".")
          .slice(0, 2) // If there's more than one decimal point, get rid of everything after the second
          .map((v, i) => i === 0 ? v : v.slice(0, 2)) // Limit to 2 digits after the first decimal point
          .join(".")
        : (v) => v.replace(/[^.0-9]/g, ""),
    validator: validator,
    validatorMessage,
  });
}

function SingleLineTextEntryQuestion(props: QuestionProps) {
  const L = useLocalizedStrings();
  let sanitizer = ((v: any) => v);
  if ((props["Additional Options"] || []).indexOf("Letters and Spaces Only") !== -1) {
    sanitizer = (v) => v.replace(/[^a-zA-Z\s]/g, "");
  }

  const { min_length, max_length } = safeParse(props.Metadata || '{}');
  let validator = (v: string) => v.length > 0;
  let validatorMessage = L.questions.textentry.this_field_is_required;
  // Sets a length validator + message if length requirements are specified and isn't a legal name
  if (min_length || max_length) {
    const data = getCharLengthValidatorAndMessage(min_length, max_length, L);
    validator = data.validator;
    validatorMessage = data.validatorMessage;
  }
  if ((props["Additional Options"] || []).indexOf("Legal Name") !== -1) {
    validatorMessage = L.questions.textentry.your_name_must_have_first_and_last_name;
    validator = (v) => legalNameValidator(v, min_length, max_length);
  }
  return React.createElement(BaseTextEntry, {
    ...props,
    sanitizer: sanitizer,
    validator,
    validatorMessage
  });
}

export {
  PhoneNumberQuestion,
  EmailQuestion,
  BankRoutingNumberQuestion,
  NumberQuestion,
  SingleLineTextEntryQuestion,
  DateQuestion,
  EncryptedValueVerification,
  MultilineQuestion,
  BaseTextEntry,
};

function getCharLengthValidatorAndMessage(min_length: number, max_length: number, L: any) {
  let validator;
  let validatorMessage;
  if (min_length && max_length) {
    validator = (v: string) => v.length >= min_length && v.length <= max_length;
    validatorMessage = L.questions.textentry.must_be_between_x_characters.replace('$min', min_length.toString()).replace('$max', max_length.toString());
  } else if (min_length) {
    validator = (v: string) => v.length >= min_length;
    validatorMessage = L.questions.textentry.must_be_at_least_x_characters.replace('$min', min_length.toString());
  } else {
    validator = (v: string) => v.length > 0 && v.length <= max_length;
    validatorMessage = L.questions.textentry.must_be_at_most_x_characters.replace('$max', max_length.toString());
  }
  return { validator, validatorMessage };
}
