import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { Fragment } from "react"
import { Link } from "react-router-dom"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
  
export default function FlyoverMenu(props: {
  className?: string,
  onItemClick: () => void,
  label: string,
  withOpen: (open: boolean) => void,
  items: {
    name: string,
    href: string,
    description: string
  }[],
  onPointerEnter?: () => void,
  onPointerLeave?: () => void
}) {
  return (
    <Popover className="relative">
      {({ open }) => {
        props.withOpen(open);
        return (
          <>
            <Popover.Button
              onPointerEnter={props.onPointerEnter}
              onPointerLeave={props.onPointerLeave}
              className={classNames(
                open ? 'text-gray-900' : 'text-gray-500',
                props.className || '',
                'group bg-transparent rounded-md border-0 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              )}
            >
              <span>{props.label}</span>
              <ChevronDownIcon
                className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
                aria-hidden="true"
              />
            </Popover.Button>
  
            <Transition
              as={"div"}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel style={{
                maxHeight: '80vh',
                overflowY: 'scroll'
              }} className={`absolute z-1000 left-1/2 transform -translate-x-1/2 mt-3 py-2 px-2 w-screen max-w-xs sm:px-0`}>
                <div className="rounded-lg shadow-lg drop-shadow-lg ring-1 ring-black ring-opacity-5 overflow-none">
                  <div className="relative grid gap-6 bg-white px-5 py-5 sm:gap-8 sm:p-5">
                    {props.items.map((item) => (
                      <Popover.Button as={Link} to={item.href} key={item.name} onClick={props.onItemClick}
                        className="-m-3 p-1 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                        <p className="text-base font-medium text-gray-900">{item.name}</p>
                        <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                      </Popover.Button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}}
    </Popover>
  )
}