import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { get_deployment, useAPIPost, usePost } from '../API';
import InterfaceContext from '../Context';
import { useLocalizedStrings } from '../Localization';
import { languageContent, safeParse, SpacedSpinner } from '../Util';
import { QuestionProps } from './Props';
import { toast, ToastContainer } from "react-toastify";

import type { SmartyFrontendMessage } from 'aidkit/lib/geo/smartytypes';
import { useModularMarkdown } from '../Hooks/ModularMarkdown';

export function SegmentedAddress(props: QuestionProps): JSX.Element {
  const rawMetadata = props["Metadata"] || '{}';
  const metadata = useMemo(() => safeParse(rawMetadata), [rawMetadata]);

  const L = useLocalizedStrings();
  const [street, setStreet] = useState<string>(props.info[props['Target Field']! + '_street'] || '');
  const [city, setCity] = useState<string>(props.info[props['Target Field']! + '_city'] || '');
  const [state, setState] = useState<string>(props.info[props['Target Field']! + '_state'] || '');
  const [zip, setZip] = useState<string>(props.info[props['Target Field']! + '_zip'] || '');
  const [secondary, setSecondary] = useState<string>('');
  const [confirmedStreetWithUnmatchedSecondary, setConfirmedStreetWithUnmatchedSecondary] = useState<string>('');

  const [proposedStreet, setProposedStreet] = useState<string>('');
  const [proposedCity, setProposedCity] = useState<string>('');
  const [proposedState, setProposedState] = useState<string>('');
  const [proposedZip, setProposedZip] = useState<string>('');
  const [proposedLatLng, setProposedLatLng] = useState<number[]>([]);
  const [proposedDPB, setProposedDPB] = useState<string>('');
  const [proposedCounty, setProposedCounty] = useState<string>('');
  const [proposedTract, setProposedTract] = useState<string>('');
  const [proposedBuilding, setProposedBuilding] = useState<string>('');
  const [proposedSubpremise, setProposedSubpremise] = useState<string>('');
  const [proposedLookups, setProposedLookups] = useState<Record<string, string>>({});
  const [dpvMatch, setDPVMatch] = useState<string>('');
  const [dirty, setDirty] = useState<boolean>(false);
  const [matched, setMatched] = useState<null | 'yes' | 'no'>(null);

  const streetInput = useRef<HTMLInputElement>(null);

  let snapshot = useRef<{
    street: string;
    city: string;
    state: string;
    zip: string;
    secondary: string;
  } | undefined>(undefined);

  useEffect(() => {
    snapshot.current = {
      street: street,
      city: city,
      state: state,
      zip: zip,
      secondary: secondary
    };
    if (street === '' && city === '' && state === '' && zip === '') {
      props.setInfoKey(props['Target Field']!, '', true, false);
      props.setInfoKey(props['Target Field']! + '_street', '', true, false);
      props.setInfoKey(props['Target Field']! + '_city', '', true, false);
      props.setInfoKey(props['Target Field']! + '_state', '', true, false);
      props.setInfoKey(props['Target Field']! + '_zip', '', true, false);
      props.setInfoKey(props['Target Field']! + '_dpb', '', true, false);
      props.setInfoKey(props['Target Field']! + '_lat', '', true, false);
      props.setInfoKey(props['Target Field']! + '_lng', '', true, false);
      props.setInfoKey(props['Target Field']! + '_county', '', true, false);
      props.setInfoKey(props['Target Field']! + '_tract', '', true, false);
      props.setInfoKey(props['Target Field']! + '_building', '', true, false);
      props.setInfoKey(props['Target Field']! + '_subpremise', '', true, false);
      for (const lookup of metadata.lookups || []) {
        props.setInfoKey(props['Target Field']! + '_' + lookup, '', true, false);
      }
    }
  }, [street, city, state, zip, secondary]);

  useEffect(() => {
    if (!dirty) {
      setStreet(props.info[props['Target Field']! + '_street'] || '');
      setCity(props.info[props['Target Field']! + '_city'] || '');
      setState(props.info[props['Target Field']! + '_state'] || '');
      setZip(props.info[props['Target Field']! + '_zip'] || '');
    }
  }, [props.info]);

  const geocode = usePost('/geo/address');
  // For debouncing the address form
  const [ timer, setTimer ] = useState<ReturnType<typeof setTimeout>>();

  function queueGeocoding() {
    setDirty(true);
    setMatched(null);
    if (street.trim() && city.trim() && state.trim()) {
      const newTimer = (setTimeout(async () => {
        const streetToUse = (confirmedStreetWithUnmatchedSecondary && snapshot.current?.secondary) ? confirmedStreetWithUnmatchedSecondary : snapshot.current!.street
        const backendResponse = await geocode({
          address: streetToUse
            + (snapshot.current!.secondary ? ' #' + snapshot.current!.secondary : '')
            + ', ' + snapshot.current!.city
            + ', ' + snapshot.current!.state
            + ' ' + snapshot.current!.zip,
          geocoder: 'smarty',
          lookups: metadata.lookups || []
        }) as { smartyResponse?: SmartyFrontendMessage, lookups?: Record<string, string>, county?: string, tract?: string, building?: string, subpremise?: string };
        setStreet(streetToUse
            + (snapshot.current!.secondary ? ' #' + snapshot.current!.secondary : ''));

        setDPVMatch(backendResponse?.smartyResponse?.analysis?.dpv_match_code || '');

        if (backendResponse?.smartyResponse?.analysis.dpv_match_code === 'Y') {
          setMatched('yes');

          setProposedStreet(backendResponse.smartyResponse.components.street + ' ' + backendResponse.smartyResponse.components.secondary);
          setProposedCity(backendResponse.smartyResponse.components.city_name);
          setProposedState(backendResponse.smartyResponse.components.state_abbreviation);
          setProposedZip(backendResponse.smartyResponse.components.zipcode);
          setProposedDPB(backendResponse.smartyResponse.delivery_point_barcode);
          setProposedCounty(backendResponse.county || '');
          setProposedTract(backendResponse.tract || '');
          setProposedSubpremise(backendResponse.subpremise || '');
          setProposedBuilding(backendResponse.building || '');
          setProposedLatLng([backendResponse.smartyResponse.metadata.latitude, backendResponse.smartyResponse.metadata.longitude])
          setProposedLookups(backendResponse.lookups || {});

          if (backendResponse.smartyResponse.analysis.dpv_match_code === 'Y') {
            setSecondary('');
          }
        } else if (backendResponse?.smartyResponse?.analysis.dpv_match_code === 'S' || backendResponse?.smartyResponse?.analysis.dpv_match_code === 'D') {
          // Temporarily hold onto the street address that Smary has confirmed, removing the invalid secondary.
          // If the applicant changes the secondary, we'll re-geocode with the new secondary and this valid street address.
          // If the applicant asserts that the address was right the first time, we disregard this variable.
          setConfirmedStreetWithUnmatchedSecondary(backendResponse.smartyResponse.components.street)
          setMatched('yes');
          if (secondary) {
            toast((L.questions.identity.no_address_with_given_secondary_found).replace('$secondary', secondary), { type: 'error', autoClose: 8000 });
          }
          setSecondary('')
        } else { // If DPV match code is N, null, or undefined
          setMatched('no');
        }
      }, 700));
      clearTimeout(timer);
      setTimer(newTimer);
    }
  }

  function confirmProposal() {
    setConfirmedStreetWithUnmatchedSecondary('');
    setSecondary('');
    setStreet(proposedStreet);
    setCity(proposedCity);
    setState(proposedState);
    setZip(proposedZip);
    props.setInfoKey(props['Target Field']!, proposedStreet + ', ' + proposedCity + ', ' + proposedState + ' ' + proposedZip, true, false);
    props.setInfoKey(props['Target Field']! + '_street', proposedStreet, true, false);
    props.setInfoKey(props['Target Field']! + '_city', proposedCity, true, false);
    props.setInfoKey(props['Target Field']! + '_state', proposedState, true, false);
    props.setInfoKey(props['Target Field']! + '_zip', proposedZip, true, false);
    props.setInfoKey(props['Target Field']! + '_dpb', proposedDPB, true, false);
    props.setInfoKey(props['Target Field']! + '_county', proposedCounty, true, false);
    props.setInfoKey(props['Target Field']! + '_tract', proposedTract, true, false);
    props.setInfoKey(props['Target Field']! + '_subpremise', proposedSubpremise, true, false);
    props.setInfoKey(props['Target Field']! + '_building', proposedBuilding, true, false);
    props.setInfoKey(props['Target Field']! + '_lat', proposedLatLng.length ? proposedLatLng[0].toString() : '', true, false);
    props.setInfoKey(props['Target Field']! + '_lng', proposedLatLng.length ? proposedLatLng[1].toString() : '', true, false);
    for (const lookup of metadata.lookups || []) {
      props.setInfoKey(props['Target Field']! + '_' + lookup, proposedLookups[lookup] || '', true, false);
    }
    setProposedStreet('');
    setProposedCity('');
    setProposedState('');
    setProposedDPB('');
    setProposedCounty('');
    setProposedTract('');
    setProposedSubpremise('');
    setProposedBuilding('');
    setProposedZip('');
    setProposedLatLng([]);
    setProposedLookups({});
    setDirty(false);
    setMatched(null);
  }

  function clearProposal() {
    setProposedStreet('');
    setProposedCity('');
    setProposedState('');
    setProposedDPB('');
    setProposedCounty('');
    setProposedTract('');
    setProposedSubpremise('');
    setProposedBuilding('');
    setProposedZip('');
    setSecondary('');
    setConfirmedStreetWithUnmatchedSecondary('');
    setProposedLatLng([]);
    setProposedLookups({});
    setDPVMatch('');
    setMatched(null);
    setDirty(false);

    setStreet('');
    streetInput.current?.focus();
  }

  function confirmAddressThatWasNotFound() {
    props.setInfoKey(props['Target Field']!, street + ', ' + city + ', ' + state + ' ' + zip, true, false);
    props.setInfoKey(props['Target Field']! + '_street', street, true, false);
    props.setInfoKey(props['Target Field']! + '_city', city, true, false);
    props.setInfoKey(props['Target Field']! + '_state', state, true, false);
    props.setInfoKey(props['Target Field']! + '_zip', zip, true, false);
    props.setInfoKey(props['Target Field']! + '_county', '', true, false);
    props.setInfoKey(props['Target Field']! + '_lat', '', true, false);
    props.setInfoKey(props['Target Field']! + '_lng', '', true, false);
    for (const lookup of metadata.lookups || []) {
      props.setInfoKey(props['Target Field']! + '_' + lookup, '', true, false);
    }
    setConfirmedStreetWithUnmatchedSecondary('');
    setProposedStreet('');
    setProposedCity('');
    setProposedState('');
    setProposedDPB('');
    setProposedCounty('');
    setProposedTract('');
    setProposedSubpremise('');
    setProposedBuilding('');
    setProposedZip('');
    setDirty(false);
    setMatched(null);
  }

  const allowApplicantToAssertValidAddress = (props["Additional Options"] || []).indexOf("Force Geocoding") === -1;

  const formatted = ((props["Additional Options"] || []).indexOf("Formatted") !== -1);
  const context = useContext(InterfaceContext);
  const content = formatted ? useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info
  }) : <b>{props[languageContent(context.lang)]}</b>;

  return <div>
    <legend>
      {content}
    </legend>
    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div className="sm:col-span-6">
        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
          {L.questions.identity.street_address}
        </label>
        <div className="mt-1">
          <input
            value={street}
            ref={streetInput}
            onChange={e => {
              setStreet(e.target.value);
              queueGeocoding();
            }}
            type="text"
            name="street-address"
            id="street-address"
            autoComplete="street-address"
            className="shadow-sm p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm block w-full rounded-md border border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-500"

          />
        </div>
      </div>

      <div className="sm:col-span-2">
        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
          {L.questions.identity.city}
        </label>
        <div className="mt-1">
          <input
            value={city}
            onChange={e => {
              setCity(e.target.value);
              queueGeocoding();
            }}
            type="text"
            name="city"
            id="city"
            autoComplete="address-level2"
            className="shadow-sm p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm block w-full rounded-md border border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-500"
          />
        </div>
      </div>

      <div className="sm:col-span-2">
        <label htmlFor="region" className="block text-sm font-medium text-gray-700">
          {L.questions.identity.state}
        </label>
        <div className="mt-1">
          <input
            value={state}
            onChange={e => {
              setState(e.target.value);
              queueGeocoding();
            }}
            type="text"
            name="region"
            id="region"
            autoComplete="address-level1"
            className="shadow-sm p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm block w-full rounded-md border border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-500"
          />
        </div>
      </div>

      <div className="sm:col-span-2">
        <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
          {L.questions.identity.zip_code}
        </label>
        <div className="mt-1">
          <input
            value={zip}
            onChange={e => {
              setZip(e.target.value);
              queueGeocoding();
            }}
            type="text"
            name="postal-code"
            id="postal-code"
            autoComplete="postal-code"
            className="shadow-sm p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm block w-full rounded-md border border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-500"
          />
        </div>
      </div>

      {matched === 'no' && <div className="sm:col-span-6">
        <div>
          <div className="block text-sm font-medium text-gray-700">
            <span>{L.questions.identity.address_not_found + (allowApplicantToAssertValidAddress ? ' ' + L.questions.identity.address_not_found_allow_force : '')}</span>
          </div>
          {allowApplicantToAssertValidAddress &&
          <button
            className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={confirmAddressThatWasNotFound}>
            {L.questions.identity.address_force_assertion}
          </button>
          }
        </div>
      </div>}
      {(matched === 'yes' || (proposedStreet && matched !== 'no')) &&
        <div className="sm:col-span-6">
          <div className="block text-sm font-medium text-gray-700">
            {dpvMatch === 'Y' && <>
              <div className='font-bold'>{L.questions.identity.is_this_the_right_address}</div>
              <div>{proposedStreet}</div>
              <div>{proposedCity}, {proposedState} {proposedZip}</div>
              <button
                className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={confirmProposal}>
                {L.questions.choice.yes}
              </button>
              <button
                className="inline-flex items-center mt-2 ml-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={clearProposal}>
                {L.questions.choice.no}
              </button>
            </>}
            {dpvMatch !== 'Y' && <div>
              <div className='font-bold text-red-500'>
                {L.questions.identity.we_arent_sure_this_address_is_correct}
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="unit" className="block text-sm font-medium text-gray-700">
                  {L.questions.identity.what_is_your_apartment_number}
                </label>
                <div className="mt-1">
                  <input
                    value={secondary}
                    onChange={e => {
                      setSecondary(e.target.value);
                    }}
                    type="text"
                    name="unit"
                    id="unit"
                    className="shadow-sm p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm block w-full rounded-md border border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-500"
                  />
                </div>
                <button
                  className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={queueGeocoding}>
                  {L.questions.identity.check_unit_number}
                </button>
                {(allowApplicantToAssertValidAddress && !secondary) &&
                  <div className="pt-3">
                    {L.questions.identity.address_not_found_allow_force}
                    <button
                      className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-400 hover:bg-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={confirmAddressThatWasNotFound}>
                      {L.questions.identity.address_force_assertion}
                    </button>
                  </div>
                }
              </div>
            </div>}
          </div>
        </div>}
    </div>
  </div>;
}

export function AddressQuestion(props: QuestionProps) {
  if ((props['Additional Options'] || []).indexOf('Separate Fields') !== -1) {
    return SegmentedAddress(props);
  }
  return LegacyAddressQuestion(props);
}

export function LegacyAddressQuestion(props: QuestionProps) {

  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const rawMetadata = props["Metadata"] || '{}';
  const metadata = useMemo(() => safeParse(rawMetadata), [rawMetadata]);
  const content = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
  }); 

  const geocode = useAPIPost(get_deployment() === 'chicagorcp' ? 'api://geo/address?program=' + get_deployment() : "/geocode");
  const [updateTimer, setUpdateTimer] = useState<typeof setTimeout | null>(
    null
  );
  const [address, setAddress] = useState(props.info[props["Target Field"]!]);
  const [result, setResult] = useState({} as any);
  const addressRef = useRef(address);
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    addressRef.current = address;

    if (result.address === undefined && updateTimer === null) {
      if (props.info[props["Target Field"]!] !== address) {
        setAddress(props.info[props["Target Field"]!]);
      }
    }
  }, [address, result.address, updateTimer, props]);

  function queueGeocoding(addr: string) {
    setAddress(addr);
    if (updateTimer) {
      clearTimeout(updateTimer! as any);
    }
    setUpdateTimer(
      (setTimeout(async () => {
        console.log("Checking", addressRef.current);
        if (addressRef.current.length > 0) {
          setSearching(true);
          const res = await geocode({
            address: addressRef.current,
            target_field: props['Target Field']
          });
          if ((props['Additional Options'] || []).indexOf('Partial OK') !== -1) {
            if (res.address) {
              res.found_street_number = true;
            }
          }
          setResult(res);
          setSearching(false);
          if (res.address === addressRef.current) {
            props.setInfoKey(
              props["Target Field"]!,
              addressRef.current,
              true,
              false
            );
            (metadata['lookups'] || []).forEach((lookup: string) => {
              props.setInfoKey(
                props["Target Field"]! + "_" + lookup,
                (res.lookups || {})[lookup] || "",
                true,
                false
              );
            });
          }
        } else {
          props.setInfoKey(props["Target Field"]!, "", false, false);
          (metadata['lookups'] || []).forEach((lookup: string) => {
            props.setInfoKey(
              props["Target Field"]! + "_" + lookup,
              "",
              true,
              false
            );
          });
        }
        setTimeout((null as unknown) as typeof setTimeout);
      }, 1000) as unknown) as typeof setTimeout
    );
  }

  function confirmResult() {
    props.setInfoKey(props["Target Field"]!, result.address || "", true, false);
    props.setInfoKey(
      props["Target Field"]! + "_county",
      result.county || "",
      true,
      false
    );
    props.setInfoKey(
      props["Target Field"]! + "_tract",
      result.census_tract || "unknown",
      true,
      false
    );
    (metadata['lookups'] || []).forEach((lookup: string) => {
      if (result.lookups && result.lookups[lookup]) {
        props.setInfoKey(
          props["Target Field"]! + "_" + lookup,
          result.lookups[lookup] || "",
          true,
          false
        );
      }
    });
    setAddress(result.address);
  }

  function ignoreGeocoding() {
    props.setInfoKey(props["Target Field"]!, address, true, false);
    setResult({});
  }

  const showConfirmation =
      (result.address || result.found_street_number === false) &&
      result.address !== address;
  const valid =
      props.info[props["Target Field"]!] === address &&
      (props.info[props["Target Field"]!] || "").length > 0;
  const canIgnore =
      (props["Additional Options"] || []).indexOf("Force Geocoding") === -1;

  let formatted = false;
  if ((props["Additional Options"] || []).indexOf("Formatted") !== -1) {
    formatted = true;
  }

  return (
    <>
      <fieldset>
        <legend>
          {formatted ? content :
            <b>{props[languageContent(context.lang)]}</b>}
        </legend>
        <input
          className={
            "max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md " + (
              (valid || (props["Additional Options"] || []).indexOf("Optional") !== -1)
                ? "border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400"
                : "border-2 border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400")
          }
          value={address || ''}
          onChange={(e) => queueGeocoding(e.target.value)}
        />
      </fieldset>
      {searching && <div className="float-right" style={{position: "relative", top: "-33.5px"}}><SpacedSpinner />&nbsp;</div>}
      <div>
        {showConfirmation && address && result.found_street_number && (
          <>
            <fieldset>
              <legend>
                <p>
                  {L.questions.textentry.we_found} <b>{result.address}</b>{L.questions.textentry.is_this_correct}&nbsp;
                  {L.questions.textentry.if_it_is_not_correct}
                </p>
              </legend>
              <Button variant="success" onClick={confirmResult}>
                {L.questions.textentry.yes_it_is_correct}
              </Button>
            </fieldset>
            {canIgnore && (
              <Button onClick={ignoreGeocoding} variant="warning">{L.questions.textentry.use_entered_address_anyway}</Button>
            )}
          </>
        )}
        {showConfirmation && address && !result.found_street_number && (
          <>
            <p>
              {L.questions.textentry.address_not_found}
            </p>
            {canIgnore && (
              <Button onClick={ignoreGeocoding} variant="warning">
                {L.questions.textentry.use_entered_address_anyway}
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
}
