import React, { FormEvent } from "react";
import { usePost } from '../API';
import { CustomDateTimePicker } from "./DateTimePicker";
import { timezones } from '@aidkitorg/types/lib/timezones';

const RoughlyTwoMonthsInMillis = 2 * 30 * 24 * 60 * 60 * 1000;

function findTimezones() {
  const offset = -Math.fround(new Date().getTimezoneOffset() / 60);
  return {
    local: timezones.find(t => t.value === "UTC")!,
    reference: timezones.find(t => t.offset === offset),
  };
}

const toMinuteString = (m?: Date | number): string => {
  if (!m) { return '' }
  if (typeof m === 'number') {
    m = new Date(m);
  }
  const padWithZero = (num: number) => num.toString().padStart(2, '0');
  return `${m.getFullYear()}-${padWithZero(m.getUTCMonth() + 1)}-${padWithZero(m.getUTCDate())}-${padWithZero(m.getUTCHours())}-${padWithZero(m.getUTCMinutes())}`;
}

export default function TransactionDownload() {
  const getSpendingData = usePost('/givecard/spending');
  const timezones = findTimezones();

  async function fetchAndDownload(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const params = Array.from(e.currentTarget.elements).filter(e => e.id).reduce((prev, e: any) => ({
      ...prev,
      [e.id]: e.type === 'datetime-local' ? new Date(e.value).getTime() : e.value
    }), {} as Partial<Awaited<Parameters<typeof getSpendingData>[0]>>);

    const data = await getSpendingData({
      ...params,
      asCSV: true,
      cardIdField: 'card_id'
    });
    const blob = new Blob([data.totals as string ?? ''], { type: 'text/csv' });
    const link = document.createElement('a');
    link.download = `givecard_transactions_${toMinuteString(params.from)}_to_${toMinuteString(params.to)}`;
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  return <form className="border rounded p-4 inline-table space-y-3" onSubmit={fetchAndDownload}>
    <div className="flex">
      <label className="pr-2" htmlFor="from">From:</label>
      <CustomDateTimePicker timezones={timezones} id="from" initialValue={Date.now() - RoughlyTwoMonthsInMillis} before={Date.now()} />
    </div>
    <div className="flex">
      <label className="pr-2" htmlFor="to">To:</label>
      <CustomDateTimePicker timezones={timezones} id="to" before={Date.now()} />
    </div>
    <div>
      <label className="pr-2" htmlFor="optInField">Opt-In Field:</label>
      <input type="text" defaultValue="enrollment_consent_spending_data" className="border rounded w-full" id="optInField" />
    </div>
    <div>
      <label className="pr-2" htmlFor="delimiter">Delimiter:</label>
      <input type="text" defaultValue="," className="font-bold text-lg text-center w-auto flex-grow border rounded" id="delimiter" />
    </div>
    <div className="space-x-2 float-right">
      <button className="mt-3 bg-red-500 rounded-md p-2 text-white font-semibold" type="reset">Reset</button>
      <button className="mt-3 bg-indigo-700 rounded-md p-2 text-white font-semibold" type="submit">Download</button>
    </div>
  </form>;
}
