import { Collection, PaymentModule, Section } from "../../survey";

export const generatePaymentFAQs = (config: PaymentModule): Collection => {
  return {
    "kind": "Collection",
    "name": {
      "en": "FAQs"
    },
    "options": {},
    "components": [
      ...config.ach.enabled ? [FAQS_ACH] : [],
      ...(config.debit.kind !== 'None') ? [generateDebitCardFAQs(config)] : []
    ]
  };
};

const generateDebitCardFAQs = (config: PaymentModule): Collection => {
  return {
    "kind": "Collection",
    "name": {
      "en": "Debit Cards"
    },
    "options": {},
    "components": [
      ...(config.debit.kind === 'Usio') ? [
        ...config.debit.mail.enabled ? [FAQS_DEBIT_CARD_USIO_MAIL] : [],
        ...config.debit.virtual.enabled ? [FAQS_DEBIT_CARD_USIO_VIRTUAL] : [],
        ...config.debit.pickup.enabled ? [FAQS_DEBIT_CARD_USIO_PICKUP] : [],
      ] : [],
      ...(config.debit.kind === 'GiveCard') ? [
        ...(config.debit.mail.enabled) ? [FAQS_DEBIT_CARD_GIVECARD] : []
      ] : []
    ]
  }
};

export const FAQS_ACH: Collection = {
  "kind": "Collection",
  "name": {
    "en": "ACH"
  },
  "options": {},
  "components": [
    {
      "kind": "Section",
      "title": {
        "en": "ACH FAQs"
      },
      "components": [
        {
          "kind": "Explanation",
          "content": {
            "en": "# Frequently Asked Questions - Direct Deposit\n\n### When will I receive my money? \n\nACH transfers generally post in 1-2 days depending on the receiving institution- e.g. your bank or credit union. You received an email or text message on the day AidKit sent your payment to your bank or credit union. "
          },
          "collapsible": true
        },
        {
          "kind": "Explanation",
          "content": {
            "en": "### Why is only some of my money available? \n\nBanks and credit unions have funds availability policies. They may make large deposits available smaller amounts over time. Check with your bank or credit union to learn about their policy."
          },
          "collapsible": true
        },
        {
          "kind": "Explanation",
          "content": {
            "en": "### I think there was a problem with my transfer or I have another question. \n\nPlease send a text, call us, or email us from the phone or email address you used to apply. You can contact us at: $support_email or $support_phone."
          },
          "collapsible": true
        }
      ]
    }
  ]
};

const FAQS_DEBIT_CARD_USIO_PICKUP: Section = {
  "kind": "Section",
  "title": {
    "en": "USIO Pickup "
  },
  "components": [
    {
      "kind": "Explanation",
      "content": {
        "en": "# Frequently Asked Questions - Pre-paid Debit Card Pick-up \n\n## How do I get my card? \n\nPlease text, call, or email us at $support_email or $support_phone to schedule a time to pick up your card. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "## Using My Card\n\n### Are there fees or withdrawal limits associated with using the debit card?\n\nThere is no fee when you use the card for purchases - other fees vary. Please refer to your card agreement. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How do I use the funds for online and phone purchases?\n\nYou can use your debit card like you would use a credit card for online or phone purchases. You will typically need to provide the card number, expiration date, and CV number (3-digit security code), which you can access by logging into [your account](https://app.akimbocard.com/app/#/nowincentive/login) (opens in a new window)."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How do I use the funds for online and phone purchases?\n\nYou can use your debit card like you would use a credit card for online or phone purchases. You will typically need to provide the card number, expiration date, and CV number (3-digit security code), which you can access by logging into [your account](https://app.akimbocard.com/app/#/nowincentive/login) (opens in a new window).\n\n**Important note**: When using your card for online transactions or making purchases over the phone, the merchant will often validate your billing address (usually the ZIP code). Please make sure that you have the correct address information listed on your card account."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Can I add money to my Akimbo Now card? \n\nNot directly. There is no way to add funds to the card. However, when you add the card to your mobile wallet you can add additional funds via Apple Pay, Google Pay, or Samsung Pay. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "## Managing My Card\n\n### How do I access my Akimbo Now account?\n\nYou can log in with your email and password at https://app.akimbocard.com/app/#/nowincentive/login (opens in a new window)."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How can I check the balance of my card?\n\nYou can check the balance by logging into [your Akimbo Now account](\nhttps://app.akimbocard.com/app/#/nowincentive/login)  (opens in a new window) with your email and password, or by calling the automated phone service at <a href=\"tel:8559254626\">1-855-925-4626</a>."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How do I change my PIN? \n\nLog in to your Akimbo Now account and click on \"CHANGE PIN\".\n\n![Akimbo Now change pin](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nYou can also change your PIN by calling <a href=\"tel:8554492273\">1-855-449-2273</a> Monday through Friday between the hours of 7am and 7pm CT."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Will my funds expire?\n\nAfter three years of inactivity you may need to take action to protect your funds. See the card agreement for more information. You can access your card agreement by logging in to your Akimbo Now Account.\n\n![Akimbo Now card agreement location](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### What should I do if my card is lost or stolen?\n\nAidKit works with a company called USIO to provide virtual cards. USIO is the card issuer that provides the Akimbo Now card. If your card is lost/stolen, call USIO customer support at <a href=\"tel:8559254626\">1-855-925-4626</a> to cancel the card.\n\nYou will need to provide them with your name, phone number, and the card number (if available) so they can process a replacement. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How can I keep my money safe?\n\n- Keep the PIN code saved somewhere safe (you will need the PIN for purchases)\n- Report loss or theft right away by calling customer support for USIO, the card issuer,  at <a href=\"tel:8559254626\">1-855-925-4626</a>\n- Don’t share your card number with others"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Is my card insured? \n\nNo. Akimbo Now Cards are not FDIC or NCUA insured. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Questions or need support?\n\nContact $support_email or $support_phone."
      },
      "collapsible": true
    }
  ]
};

const FAQS_DEBIT_CARD_USIO_VIRTUAL: Section = {
  "kind": "Section",
  "title": {
    "en": "USIO Virtual"
  },
  "components": [
    {
      "kind": "Explanation",
      "content": {
        "en": "# Frequently Asked Questions - Virtual Debit Card\n\n## General Questions / Activating My Card\n\n### What is a virtual debit card?\n\nA virtual (or online) debit card is a debit card that you can use without providing a physical card. The Akimbo Now Card is the type of card AidKit provides.\n\nOnce the card is activated, you can use it for online purchases and with a digital/mobile wallet. Digital/mobile wallets include Apple Pay, Google Pay, and Samsung Pay. There are instructions on linking your virtual debit card to a digital/mobile wallet below."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Do I need to register my debit card?\n\nYes. Registration is necessary to activate your virtual debit card and receive funds. Registration occurs when you create an account and activate your card in the portal. Registration may provide you with additional consumer rights."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "## How do I get my virtual debit card?\n\nYou will receive an email or text message that contains a link to create an account with Akimbo Now, activate your virtual card, and get access to your card number. \n\nYou can also get this link by clicking \"Get my card setup link\" at the bottom of this page.\n\nClicking on this link (or copying and pasting it into a web browser) will take you to a website for Akimbo Now that looks like this:\n\n![Akimbo Now collect your card](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/collect_your_card.jpg)\n\n**To create an account with Akimbo Now and collect your card:**\n\n(1) Enter your name, email, and phone number (email or phone may already be filled in).\n(2) Create a password and a PIN.\n    * The password is used to log into your online account to access your card information and make changes to your account.\n    * A PIN is a four digit code you enter to confirm a debit purchase or ATM withdrawal. \n    * Choose a password and PIN that you will remember easily and consider saving them in a safe place.\n\n(3) Press finish in the bottom right of your screen.\n\n![Akimbo Now press finish](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/press_finish.jpg)\n\n(4) You will then see your member home page. From this page you can manage alerts, activate your card, and add your card to a digital wallet.\n(5) Be sure to click on “**ACTIVATE CARD**.” Your card must be activated for use.\n\n![Akimbo Now activate card](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/activate_card.jpg)"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### I created an Akimbo Now account over a day ago and my money is still not there.\n\nActivate your card. \n(1) Click on **VIEW YOUR VIRTUAL NUMBER**. \n![virtual](https://aidkit-public.s3.us-east-2.amazonaws.com/sifh/virtual%20card%20number.png)\n(2) Scroll down and click on **ACTIVATE CARD**. Enter your card information from Step 1. \n\nYour card must be activated before your payment will be loaded. Your money should be available to use within 1 day of activating it.\n\n![Akimbo Now activate card](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/activate_card.jpg)"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### I can't find the email or text message that has my card setup link.\n\nClick on the **Get my card setup link** button below and verify the email or phone number we have on file for you. You will be redirected to a page that shows your link, if we have one for you."
      },
      "collapsible": true
    },
    {
      "kind": "LoginWidget",
      "page": "my-card-info",
      "buttonText": {
        "en": "Get my card setup link",
        "_id": "71c3ea64-d7a2-44fa-9665-4ba7c49c2bf3"
      },
      "version": 2
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "# Using My Card\n\n### Where can I use my debit card?\n\nYou can use your debit card to make purchases online, over the phone, and at any store that accepts debit cards using virtual/mobile wallets (also referred to as tap to pay, cardless, or contactless payment).\n\nYou can get cash back with no fees at merchants that allow it (including Walmart, some CVS, and some grocery stores - check with individual stores about their policies).\n\nIn the AkimboNow portal you also have the option to request an ACH be sent to a bank account, request a check be mailed to you or someone else, and/or to request a physical debit card be mailed to you if you have a mailing address. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Are there fees or withdrawal limits associated with using the debit card?\n\nThere is no fee when you use the card for purchases - other fees vary. Please refer to your card agreement. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How do I find my card number, expiration date, and CVC? \n\n(1) Click on \"View your card details.\" \n\n![Akimbo Now get card number Step 1](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/step_1_to_get_card_number.jpg)\n\n(2) Copy the information that appears on the screen.\n\n![Akimbo Now get card number Step 2](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/step_2_to_get_card_number.jpg)"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How do I use the funds with a virtual/mobile wallet?\n\n[Click here](https://akimbo.zendesk.com/hc/en-us/articles/360048843013-Adding-your-card-to-Apple-Pay-Google-Pay-Samsung-Pay-and-other-mobile-wallets) (opens in a new window) for instructions on how to use your debit card with Apple Pay, Google Pay, and Samsung Pay on your mobile phone."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How do I use the funds for online and phone purchases?\n\nYou can use your debit card like you would use a credit card for online or phone purchases. You will typically need to provide the card number, expiration date, and CV number (3-digit security code), which you can access by logging into [your account](https://app.akimbocard.com/app/#/nowincentive/login) (opens in a new window).\n\n**Important note**: When using your virtual card for online transactions or making purchases over the phone, the merchant will often validate your billing address (usually the ZIP code). Please make sure that you have the correct address information listed on your card account."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Can I add money to my Akimbo Now card? \n\nNot directly. There is no way to add funds to the card. \n\nHowever, when you add the card to your mobile wallet you can add additional funds via Apple Pay, Google Pay, or Samsung Pay. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "## Managing My Card\n\n### How do I access my Akimbo Now account?\n\nYou can log in with your email and password at \nhttps://app.akimbocard.com/app/#/nowincentive/login (opens in a new window)."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How can I check the balance of my card?\n\nYou can check the balance by logging into [your Akimbo Now account](\nhttps://app.akimbocard.com/app/#/nowincentive/login)  (opens in a new window) with your email and password, or by calling the automated phone service at <a href=\"tel:8559254626\">1-855-925-4626</a>."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How do I change my PIN? \n\nLog in to your Akimbo Now account and click on \"CHANGE PIN\".\n\n![Akimbo Now change pin](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nYou can also change your PIN by calling <a href=\"tel:8554492273\">1-855-449-2273</a> Monday through Friday between the hours of 7am and 7pm CT."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Will my funds expire?\n\nAfter three years of inactivity you may need to take action to protect your funds. See the card agreement for more information. You can access your card agreement by logging in to your Akimbo Now Account.\n\n![Akimbo Now card agreement location](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### What should I do if my card is lost or stolen?\n\nAidKit works with a company called USIO to provide virtual cards. USIO is the card issuer that provides the Akimbo Now card. If your card is lost/stolen, call USIO customer support at <a href=\"tel:8559254626\">1-855-925-4626</a> to cancel the card.\n\nYou will need to provide them with your name, phone number, and the card number (if available) so they can process a replacement. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How can I keep my money safe?\n\n- Keep the PIN code saved somewhere safe (you will need the PIN for purchases)\n- Report loss or theft right away by calling customer support for USIO, the card issuer,  at <a href=\"tel:8559254626\">1-855-925-4626</a>\n- Don’t share your card number with others"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Is my card insured? \n\nNo. Akimbo Now Cards are not FDIC or NCUA insured. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Questions or need support?\n\nContact $support_email or $support_phone."
      },
      "collapsible": true
    }
  ]
};

const FAQS_DEBIT_CARD_USIO_MAIL: Section = {
  "kind": "Section",
  "title": {
    "en": "USIO Mail"
  },
  "components": [
    {
      "kind": "Explanation",
      "content": {
        "en": "# Frequently Asked Questions - Mailed Pre-paid Debit Card\n\n## When will my card arrive? \n\nCards take between 2-3 weeks to arrive. If you changed your mailing address, please let us know as soon as possible. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "## Using My Card\n\n### Are there fees or withdrawal limits associated with using the debit card?\n\nThere is no fee when you use the card for purchases - other fees vary. Please refer to your card agreement. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How do I use the funds for online and phone purchases?\n\nYou can use your debit card like you would use a credit card for online or phone purchases. You will typically need to provide the card number, expiration date, and CV number (3-digit security code), which you can access by logging into [your account](https://app.akimbocard.com/app/#/nowincentive/login) (opens in a new window)."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How do I use the funds for online and phone purchases?\n\nYou can use your debit card like you would use a credit card for online or phone purchases. You will typically need to provide the card number, expiration date, and CV number (3-digit security code), which you can access by logging into [your account](https://app.akimbocard.com/app/#/nowincentive/login) (opens in a new window).\n\n**Important note**: When using your card for online transactions or making purchases over the phone, the merchant will often validate your billing address (usually the ZIP code). Please make sure that you have the correct address information listed on your card account."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Can I add money to my Akimbo Now card? \n\nNot directly. There is no way to add funds to the card. However, when you add the card to your mobile wallet you can add additional funds via Apple Pay, Google Pay, or Samsung Pay. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "## Managing My Card\n\n### How do I access my Akimbo Now account?\n\nYou can log in with your email and password at https://app.akimbocard.com/app/#/nowincentive/login (opens in a new window)."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How can I check the balance of my card?\n\nYou can check the balance by logging into [your Akimbo Now account](\nhttps://app.akimbocard.com/app/#/nowincentive/login)  (opens in a new window) with your email and password, or by calling the automated phone service at <a href=\"tel:8559254626\">1-855-925-4626</a>."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How do I change my PIN? \n\nLog in to your Akimbo Now account and click on \"CHANGE PIN\".\n\n![Akimbo Now change pin](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nYou can also change your PIN by calling <a href=\"tel:8554492273\">1-855-449-2273</a> Monday through Friday between the hours of 7am and 7pm CT."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Will my funds expire?\n\nAfter three years of inactivity you may need to take action to protect your funds. See the card agreement for more information. You can access your card agreement by logging in to your Akimbo Now Account.\n\n![Akimbo Now card agreement location](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### What should I do if my card is lost or stolen?\n\nAidKit works with a company called USIO to provide virtual cards. USIO is the card issuer that provides the Akimbo Now card. If your card is lost/stolen, call USIO customer support at <a href=\"tel:8559254626\">1-855-925-4626</a> to cancel the card.\n\nYou will need to provide them with your name, phone number, and the card number (if available) so they can process a replacement. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### How can I keep my money safe?\n\n- Keep the PIN code saved somewhere safe (you will need the PIN for purchases)\n- Report loss or theft right away by calling customer support for USIO, the card issuer,  at <a href=\"tel:8559254626\">1-855-925-4626</a>\n- Don’t share your card number with others"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Is my card insured? \n\nNo. Akimbo Now Cards are not FDIC or NCUA insured. "
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "### Questions or need support?\n\nContact $support_email or $support_phone."
      },
      "collapsible": true
    }
  ]
};

const FAQS_DEBIT_CARD_GIVECARD: Section = {
  "kind": "Section",
  "title": {
    "en": "GiveCard Information Sheet"
  },
  "components": [
    {
      "kind": "Explanation",
      "content": {
        "en": "# GiveCard Information Sheet\n\n#### What is a GiveCard?\nGiveCard is a Mastercard prepaid debit card that works like any other debit card. GiveCards are reloadable by your program as well."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "#### General Card Information\n- You are able to use your GiveCard immediately, with no setup required. \n- GiveCards are already activated. \n- GiveCards can be used anywhere that accepts Mastercard debit cards. This means you can either use it physically in stores, or use it online (more details on this are listed below.)\n- We recommend keeping the paper your card comes attached to - this has some information on it that could be useful for identifying your card in the event that it gets lost or stolen.\n- If you believe that your card has been lost or stolen, please contact GiveCard Support immediately - more details on this are in the ‘lost/stolen’ section below.\n- You must set a PIN before you use the card at the ATM.\n- You will have access to a network of 80,000 fee-free ATMS. You can view a map of these ATMS at this link: https://www.moneypass.com/atm-locator.html\n- When using your card at a cash register, sometimes the machine will ask you for a PIN. If you have not set a PIN for your card, press the green ‘OK’ button on the machine to continue and you will be able to skip the PIN entry.\n\nIf the payment system you are using does not allow prepaid debit cards, here are alternative payment options which you can connect your GiveCard to:\n- Paypal\n- Venmo\n- Moneygram/Western Union money order (in-person)\n- Withdraw cash from ATMs (if applicable)"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "#### Checking Balance\nGo to [givecard.io/support](https://www.givecardplatform.com/cardholders) to access the GiveCard cardholder site. The website that you see printed on the back of your card will also direct you here. On this site, you can:\n- Check your balance\n- Set your optional card PIN number\n- Find ATMs that are in your area \n- View the spending history of your card by creating an account and logging in"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "#### Support\nIf you are experiencing an issue related to your program, please get in contact with BRF team. \n\nIf you are experiencing issues with your card, please contact GiveCard support by: \n- emailing support@givecard.io, or\n- calling 908-529-0955.\n- Our support team hours are 7am- 4pm PST. We will pick up your call or return your message immediately during those hours.\n- Outside of 7am- 4pm PST, our support team will get back to you as soon as they can, however this may not always be immediate."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "#### Lost or Stolen Cards\nIf you believe your card is lost or stolen, contact GiveCard Support by calling 908-529-0955 immediately and lock your card through the [cardholder portal](https://www.givecardplatform.com/cardholders). The GiveCard team will freeze your card to protect whatever funds were on it (if not done already), and then will work with your program manager to reissue you a new card. In the event that your card has been reported as stolen and you notice unauthorized transactions have occurred on it, GiveCard Support will work with you to file a transaction dispute report."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "#### Card Limits\nTransaction Limits:\n- Maximum per transaction: $5,000\n- Maximum per day: $5,000\n- Maximum per month: $5,000\n\nATM Restrictions:\n- Maximum per transaction: $1,000\n- Maximum per day: $1,000\n- Maximum per month: $2,500"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "#### Program billing information (if needed for online purchases)\nBelow you will find the billing information for your program. This information should go in the billing information section when you make an online purchase:\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AidKit  \n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;383 Corona Street, Unit #814  \n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Denver, CO 80218"
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "#### Other Restrictions\nMastercard imposes certain restrictions on prepaid cards. The following merchant category code restrictions may apply: \n- 6211\tSecurity Brokers/Dealers\n- 7273\tDating/Escort Services\n- 7297\tMassage Parlors\n- 7995\tBetting/ Casino Gambling\n- 7800\tGovernment Owned Lotteries\n- 7801\tGovernment Licensed Online Casinos (Online Gambling)\n- 7802\tGovernment Licensed Horse/Dog Racing\n\nPlease remember that this program is designed to provide you with no-strings-attached financial support, so you can always take money out at the ATM and use it for whatever you decide to spend your money on. As a reminder, you must set a PIN before you use the card at the ATM."
      },
      "collapsible": true
    },
    {
      "kind": "Explanation",
      "content": {
        "en": "#### Program Contact\nPlease contact the $program_name team in case of any non card-related issues, including questions surrounding when cards will be loaded next. Please reach out at: $support_email or $support_phone."
      },
      "collapsible": true
    }
  ]
};
