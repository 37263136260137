import { useRef, useContext, useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { usePost, useAPIPost } from "./API";
import { CommsWindow, LegacyCommsWindow } from "./Applicant/Comms";
import { AdminOnlyBadge } from "./Components/Badge";
import { Dropdown } from "./Components/Dropdown";
import { useSurveyDescription } from "./Components/Roboscreener";
import InterfaceContext, { ConfigurationContext } from "./Context";
import { useLocalizedStrings } from "./Localization";
import { ContactNotes, SupportCaseApplicantInfo, SupportCaseBadge, SupportCaseHeader } from "./SupportCase";
import { CaseStatus } from "./SupportUtil";
import { useInterval, SpacedSpinner } from "./Util";
import { ApplicantInfo } from "aidkit/lib/model/ApplicantInfo";

export function LegacySupportCasePage(props: any) {
  const { caseId } = useParams() as any;

  const L = useLocalizedStrings();

  const getCaseInfo = usePost("/support/get_case");
  const loading = useRef(false);
  const config = useContext(ConfigurationContext);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const resolveCase = usePost("/support/resolve_case");
  const escalateCase = usePost("/support/escalate_case");

  const reassignCase = usePost("/support/reassign_case");
  const getAssignees = usePost("/support/assignees");

  const getRelatedApplicants = usePost("/support/related_applicants");

  const [assignees, setAssignees] = useState([] as { uid: string, name: string}[]);

    type PossibleSupportCase = Awaited<ReturnType<typeof getCaseInfo>>['supportCase'];
    const [supportCase, setCase] = useState<null | PossibleSupportCase>(null);
    const [relatedApplicants, setRelatedApplicants] = useState<Record<string, ApplicantInfo>>({});
    const [selectedApplicant, setSelectedApplicant] = useState('');
    const [selectedApplicantInfo, setSelectedApplicantInfo] = useState<undefined | ApplicantInfo>(undefined);
    
    const [contact, setContact] = useState("");

    const [tab, setTab] = useState("comms");

    const sendMessage = useAPIPost("/send_message");

    const getMessages = usePost("/applicant/get_messages");
    const [messages, setMessages] = useState({} as Awaited<ReturnType<typeof getMessages>>);
    const [refreshed, setRefreshed] = useState(0);
    useEffect(() => {
      (async () => {
        const result = await getAssignees({});
    
        if (result && result.assignees) {
          setAssignees(result.assignees);
        }
      })();
    }, []);

    const refreshMessages = useCallback(async (contact: string, config: {
      [key: string]: any
    }) => {
      if (!contact || !config?.twilio_mms_number) return;
      if (document.hidden) return;
      if (window.document.hidden) return;

      const newMessages = await getMessages({ 
        contact,
        ourNumber: config.twilio_mms_number
      });
      setMessages(newMessages);
    }, [document.hidden]);

    const refreshSupportCase = useCallback(async () => {
      if (document.hidden) return;
      if (window.document.hidden) return;

      loading.current = true;
      const res = await getCaseInfo({ caseId });
      if (res.supportCase) {
        setCase(res.supportCase);
        setContact(res.supportCase.contact);
      }
      setRefreshed((prev) => prev + 1);
      loading.current = false;
    }, [caseId, document.hidden]);

    const refreshRelatedApplicants = async () => {
      if (document.hidden || window.document.hidden) return;
      const res = await getRelatedApplicants({ caseId });
      if (res.applicantRecords) {
        setRelatedApplicants(res.applicantRecords);
        let uids = Object.keys(res.applicantRecords);
        if (uids.length === 1) {
          setSelectedApplicant(uids[0]);
          setSelectedApplicantInfo(res.applicantRecords[uids[0]]);
        }
      }
    }

    const getStageFromUid = (uid?: string) => {
      if (!uid) return L.application_stage.pre_application;
      if (uid.length <= 22) return L.application_stage.applied;
      return L.application_stage.in_progress;
    }

    const getLabelFromUidAndInfo = (uid?: string, info?: ApplicantInfo) => {
      if (!uid || !info) return L.support.select_an_applicant;
      if (uid.length <= 22) return info?.legal_name || uid;
      return (info?.legal_name || uid) + ' (Unsubmitted)';
    }

    // For showing a spinner when resolving unhandled messages
    const [resolving, setResolving] = useState(false); 
    const resolveRequests = usePost("/messages/resolve_all");

    async function resolveAllRequests(message_ids: number[]) {
      setResolving(true);
      await resolveRequests({ 
        applicant: props.uid || 'unknown',
        message_ids
      });
      setResolving(false);
      await refreshMessages(contact, config);
    }

    useEffect(() => {
      if (!loading.current) refreshSupportCase();
      refreshRelatedApplicants();     
    }, [caseId]);

    useEffect(() => {
      if (contact && config.twilio_mms_number) {
        refreshMessages(contact, config);
      }
    }, [contact, config]);

    // Don't call if document hidden
    const cb = useCallback(async () => {
      if (document.hidden) return;
      if (window.document.hidden) return;
      await refreshMessages(contact, config);
    }, [contact, config]);

    useInterval(cb, 5000);
    useInterval(refreshSupportCase, 5000);
    useInterval(refreshRelatedApplicants, 20000);

    if (!supportCase) return <>Loading...</>

    if (!supportCase.status || !supportCase.uid) {
      return <>Support case does not exist.</>
    }

    return <>
      <h2 className="sticky top-0 z-10 name-and-status">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="text-xl2">
            <SupportCaseHeader supportCase={supportCase} messages={messages} />
          </div>
          <div>
            <div className="flex-col text-normal align-right justify-end">
              <div className="space-x-2 md:space-x-3">
                <SupportCaseBadge status={supportCase.status as CaseStatus} />
                {/** TODO: Add 're-open case' and remove this barrier: */ } 
                <Dropdown className="px-3 max-h-32" label={<>
                  {updatingStatus && <SpacedSpinner />}{supportCase.assigned_to === 'system' ? 'Unassigned' : 'Assigned To: ' + supportCase.agent_name || supportCase.assigned_to}
                </>} options={[{
                  label: "Unassign",
                  callback: async () => {
                    setUpdatingStatus(true);
                    await reassignCase({
                      caseId,
                      reassignTo: 'system'
                    });
                    setUpdatingStatus(false);
                    await refreshSupportCase();
                  }},
                ...assignees.filter(u => u.uid != 'system').map((assignee, idx) => ({
                  label: assignee.name,
                  key: assignee.uid + '-' + idx,
                  callback: async () => {
                    setUpdatingStatus(true);
                    await reassignCase({
                      caseId,
                      reassignTo: assignee.uid
                    });
                    setUpdatingStatus(false);
                    await refreshSupportCase();
                  }
                }))
                ]}/>
                {['resolved','closed'].indexOf(supportCase.status) < 0 && 
                            <Dropdown className="px-3" label={<>
                              {updatingStatus && <SpacedSpinner />}Update Status
                            </>} options={[{
                              label: "Mark Resolved",
                              callback: async () => {
                                // If still unhandled messages, ask if you want to mark those as handled first.
                                if (messages.data && messages.data.some((m) => m.needs_attention)) {
                                  const doHandle = window.confirm("There are unhandled messages. Do you want to mark them as handled?");
                                  if (doHandle) {
                                    await resolveAllRequests(messages.data.filter((m) => m.needs_attention).map((m) => m.id as number));
                                  }
                                }

                                const yn = window.confirm("Are you sure you want to mark this case as resolved?");
                                if (!yn) return;

                                setUpdatingStatus(true);
                                const caseUpdate = await resolveCase({ caseId });
                                if (supportCase) {
                                  setCase((prevState: any) => {
                                    if (caseUpdate?.row) {
                                      return {
                                        ...prevState,
                                        ...caseUpdate.row
                                      }
                                    }
                                    return prevState;
                                  });
                                }
                                setUpdatingStatus(false);
                              }
                            }, ...(supportCase.status !== 'escalated' ? [{
                              label: "Escalate",
                              callback: async () => {
                                const yn = window.confirm("Are you sure you want to escalate this case?");
                                if (!yn) return;

                                setUpdatingStatus(true);
                                const caseUpdate = await escalateCase({ caseId });
                                if (supportCase) {
                                  setCase((prevState: any) => {
                                    if (caseUpdate?.row) {
                                      return {
                                        ...prevState,
                                        ...caseUpdate.row
                                      }
                                    }
                                    return prevState;
                                  });
                                }
                                setUpdatingStatus(false);
                              }
                            }] : [])]} />}
              </div>
            </div>
          </div>
        </div>
        {(selectedApplicant)
          ? <div className="flex flex-col md:flex-row justify-between">
            <div className="text-lg font-normal">
              <span>Found applicant for this support case:</span>
              <Link to={`/applicant/${selectedApplicant}`} className="px-3">
                {selectedApplicantInfo?.['legal_name']}
              </Link>
            </div>
          </div> : <></>}
        {Object.keys(relatedApplicants).length > 1 &&
                // If there are multiple applicants, show a dropdown to select one
                <div>
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="text-lg font-normal">
                      <span>Multiple applicants for this support case: </span>
                      <Dropdown className="px-3" label={getLabelFromUidAndInfo(selectedApplicant, selectedApplicantInfo)} 
                        options={Object.entries(relatedApplicants).map(([applicant, info], idx) => ({
                          label: getLabelFromUidAndInfo(applicant, info),
                          key: applicant,
                          callback: () => setSelectedApplicant(applicant)
                        }))} />
                    </div>
                    
                    {selectedApplicant && <div className="text-lg font-normal">
                      <span>{L.support.applicant_stage}</span>
                      <span className="px-3">{getStageFromUid(selectedApplicant)}</span>
                    </div>}
                  </div>
                </div>}
      </h2>
            
      <div className="sm:p-3 md:p-5 -mt-2">
        <div className="flex flex-col md:flex-row space-x-4 justify-between">
          <div className="w-full md:w-1/2 wrap-text">
            <div className="border-2 rounded-md drop-shadow-lg bg-blue-50 p-4">
              <h4 className="text-xl2 font-bold">Context</h4>
              <hr/>
              <div className="mb-4">
                <h5>Contact</h5>
                <div>{supportCase.contact}</div>
              </div>
              <div className="mb-4">
                <h5>Applicant&apos;s Name</h5>
                <div>{selectedApplicantInfo?.['legal_name'] || 'Unknown'}</div>
              </div>
              <div className="mb-4">
                <h5>Support Case Created</h5>
                <div>{new Date(supportCase.created_at).toLocaleString()} by {supportCase.created_by}</div>
              </div>
              <div className="mb-4">
                <h5>{L.support.applicant_stage}</h5>
                <div>{L.application_stage[selectedApplicantInfo?.stage as keyof typeof L['application_stage']]}</div>
              </div>
            </div>
            {selectedApplicant && <SupportCaseApplicantInfo info={selectedApplicantInfo || {}} />}
          </div>
          <div className="w-full md:w-1/2">
            <div className="p-4 bg-gray-50 border-2 rounded-md mb-10">
              <div>
                <div className="sm:hidden">
                  <select id="tabs" name="tabs" 
                    className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                    <option selected>Applicant Comms</option>
                    <option>Internal Notes</option>
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="flex space-x-4" aria-label="Tabs">
                    {/* Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" */}
                    <button type="button" onClick={() => setTab("comms")} aria-current="page" 
                      className={`border-0 ${tab === 'comms' ? 'bg-indigo-100 text-indigo-700' : 'border-gray-100 bg-white text-gray-400 hover:text-gray-700'} 
                                            px-3 py-2 font-medium text-sm rounded-md`}>Comms</button>
                    <button type="button" onClick={() => setTab("notes")} 
                      className={`border-0 ${tab === 'notes' ? 'bg-indigo-100 text-indigo-700' : 'border-gray-100 bg-white text-gray-400 hover:text-gray-700'}
                                            px-3 py-2 drop-shadow font-medium text-sm rounded-md`}>Case Notes</button>
                  </nav>
                </div>
              </div>
              <div className="mt-4 mb-4">
                {tab === 'comms' && <>
                  <LegacyCommsWindow contact={supportCase.contact} 
                    info={selectedApplicantInfo || (supportCase.contact.includes("@") ? {email: supportCase.contact} : {phone_number: supportCase.contact})}
                    messages={messages}
                    refreshMessages={async () => refreshMessages(contact, config)}
                    send_message={async (params) => {
                      const response = await sendMessage({
                        phone: supportCase.contact,
                        message: params.message
                      });

                      if (response?.created_at) {
                        return response.created_at;
                      }
                      return;
                    }}
                  />

                </>}
                {tab === 'notes' && <ContactNotes 
                  refresh={refreshed}
                  doRefresh={() => setRefreshed(prev => prev + 1)}
                  contact={supportCase.contact} />}
              </div> 
            </div>
          </div>
        </div>
      </div>
    </>
    
}