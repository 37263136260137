import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {  useAPIPost } from "./API";
import { useLocalizedStrings } from "./Localization";
import './Invoice.css';
import { delimitNumbers, copyToClipboard, AidKitLogo } from "./Util";
import { Button } from "react-bootstrap";
import InterfaceContext from "./Context";
import { ConfigurationContext } from "./Context";
import { DateTime } from "luxon";

function LegacyScreeningInvoicePage() {
  const { payment_id } = useParams() as Record<'payment_id', string>;
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const configContext = useContext(ConfigurationContext);
  const get_data = useAPIPost("/paymenttransactions/screening_invoice_data");
  const anonGetData = useAPIPost("/paymenttransactions/anon_screening_invoice_data");
  const getToken = useAPIPost("/paymenttransactions/generate_invoice_token");
  const payInvoice = useAPIPost("/paymenttransaction/:id/pay_screening_invoice");
  const [data, setData] = useState({} as any);

  const covers = payment_id.split('_').map((c: string) => c.replace('"', ''));
  const coversDate = new Date(DateTime.fromObject({ year: parseInt(covers[0]), month: parseInt(covers[1]) }).toString());
  const coversMoment = coversDate.toLocaleString(context.lang, { month: 'long', year: 'numeric' });
  // const coversMoment = moment(coversDate).toLocaleString();

  useEffect(() => {
    async function loadData() {
      const params = new URLSearchParams(window.location.search);
      const key = params.get("key");
      const data = key ? await anonGetData({token: key}) : await get_data({ payment_id });
      setData(data);
    }

    loadData();

  }, [payment_id, get_data, setData, anonGetData]);

  async function doPayInvoice() {
    let status = await payInvoice({}, '/paymenttransaction/' + data.id + '/pay_screening_invoice');
    if (status.status === 'ok') {
      alert('Payment issued')
      window.location.reload();
    } else {
      alert('Issuing failed ' + JSON.stringify(status))
    }
  }

  async function copyAnonymousLink() {
    const resp = await getToken({ payment_id: payment_id });
    copyToClipboard(
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?key=" +
        resp.token, L.copied_to_clipboard
    );
  }
  
  return (
    <>
      <div className="invoice-box mt-4">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="top" key="top">
              <td colSpan={4}>
                <table>
                  <tbody>
                    <tr key="aidkit_header">
                      <td className="title">
                        ✚ Aid Kit
                      </td>
                      <td></td><td></td>
                      <td>
                        {data.created_at && (
                          <>
                            {L.banking.record_number}: { payment_id }<br />
                            {L.banking.created}: {new Date(DateTime.fromISO(data.created_at).toString()).toLocaleString(context.lang, { month: 'long', day: 'numeric', year: 'numeric' })}<br />
                            {L.banking.covers}: {coversMoment}
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr className="information" key="information">
              <td colSpan={4}>
                <table>
                  <tbody>
                    <tr key="address_row">
                      <td>
                        {L.admin.invoices.aidkit_llc}<br />
                        {L.admin.invoices.address}<br/>
                        {L.admin.invoices.address_2}<br/>
                        {L.admin.invoices.city_state_zip}
                      </td>
                      <td></td><td></td>
                      <td>
                        {data && (
                          <>
                            {data.organization?.name }<br />
                            {data.screener?.name }<br />
                            {data.screener?.email}
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr className="heading" key="heading">
              <td>{L.banking.payment_method}</td>
              <td>{L.banking.pay_rate}</td>
              <td>{L.banking.pay_rate_with_corrections}</td>
              <td>{L.banking.record_number}</td>
            </tr>

            {data && data.organization && (
              <tr className="details" key="details">
                <td>{L.admin.invoices.dwolla_ach}</td>
                <td>{data.organization?.metadata['Pay Rate']}</td>
                <td>{data.organization?.metadata['Pay Rate (With Corrections)']}</td>
                <td>{ payment_id }</td>
              </tr>
            )}

            <tr className="heading" key="heading2">
              <td>{L.applicant.screener}</td>
              <td>{L.banking.applicants_cleared}</td>
              <td>{L.banking.applicants_with_corrections}</td>
              <td>{L.banking.pay_earned}</td>
            </tr>

            {data && data.screeners && Object.keys(data.screeners).map((uid: string, index: number) => {
              let itemClass = "item";
              if (data.screeners.length - 1 === index) itemClass = "item last";
              return (
                <tr className={itemClass} key={`details2-${index}`}>
                  <td>{data.screeners[uid]?.name}</td>
                  <td>{data.screeners[uid]?.goodies || ""}</td>
                  <td>{data.screeners[uid]?.baddies || ""}</td>
                  <td>${delimitNumbers(Math.abs(parseFloat(data.screeners[uid].total_pay)).toFixed(2))}{" "}</td>
                </tr>
              )
            })}

            {data && (
              <tr className="total" key="total">
                <td></td>
                <td></td>
                <td></td>
                <td>{L.banking.total}{": $"}{delimitNumbers(Math.abs(parseFloat(data.total_amount)).toFixed(2))}{" "}</td>
              </tr>
            )}
          </tbody>
        </table>
        {(configContext.roles || []).indexOf('admin') !== -1 &&
          <>{(((data.metadata?.request || data.metadata?.action) &&
            "Payment Issued")
            || <Button onClick={doPayInvoice}>{L.admin.invoices.issue_payment}</Button>)}&nbsp;
          <Button onClick={copyAnonymousLink}>{L.admin.invoices.copy_link}</Button></>}
      </div>
      
    </>
  );
}

function ItemizedScreeningInvoicePage() {
  const { payment_id } = useParams() as Record<string, string>;
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const configContext = useContext(ConfigurationContext);
  const get_data = useAPIPost("/paymenttransactions/screening_invoice_data");
  const anonGetData = useAPIPost("/paymenttransactions/anon_screening_invoice_data");
  const getToken = useAPIPost("/paymenttransactions/generate_invoice_token");
  const payInvoice = useAPIPost("/paymenttransaction/:id/pay_screening_invoice");
  const [data, setData] = useState({} as any);

  const covers = payment_id.split('_').map((c: string) => c = c.replace('"', ''));
  const coversDate = new Date(DateTime.fromObject({ year: parseInt(covers[0]), month: parseInt(covers[1]) }).toString());
  const coversMoment = coversDate.toLocaleString(context.lang, { month: 'long', year: 'numeric' });
  // const coversMoment = moment(coversDate).toLocaleString();

  useEffect(() => {
    async function loadData() {
      const params = new URLSearchParams(window.location.search);
      const key = params.get("key");
      const data = key ? await anonGetData({token: key}) : await get_data({ payment_id });
      setData(data);
    }

    loadData();

  }, [payment_id, get_data, setData, anonGetData]);

  async function doPayInvoice() {
    let status = await payInvoice({}, '/paymenttransaction/' + data.id + '/pay_screening_invoice');
    if (status.status === 'ok') {
      alert('Payment issued')
      window.location.reload();
    } else {
      alert('Issuing failed ' + JSON.stringify(status))
    }
  }

  async function copyAnonymousLink() {
    const resp = await getToken({ payment_id: payment_id });
    copyToClipboard(
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?key=" +
        resp.token, L.copied_to_clipboard
    );
  }
  
  return (
    <>
      <div className="invoice-box mt-4">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="top" key="top">
              <td colSpan={4}>
                <table>
                  <tbody>
                    <tr key="aidkit_header">
                      <td className="title">
                        <AidKitLogo width={200} height={85} />
                      </td>
                      <td></td><td></td>
                      <td>
                        {data?.created_at && (
                          <>
                            {L.banking.record_number}: { payment_id }<br />
                            {L.banking.created}: {new Date(DateTime.fromISO(data.created_at).toString()).toLocaleString(context.lang, { month: 'long', day: 'numeric', year: 'numeric' })}<br />
                            {L.banking.covers}: {coversMoment}
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr className="information" key="information">
              <td colSpan={4}>
                <table>
                  <tbody>
                    <tr key="address_row">
                      <td>
                        {L.admin.invoices.aidkit_llc}<br />
                        {L.admin.invoices.address}<br/>
                        {L.admin.invoices.address_2}<br/>
                        {L.admin.invoices.city_state_zip}
                      </td>
                      <td></td><td></td>
                      <td>
                        {data && (
                          <>
                            {data.organization?.name }<br />
                            {data.screener?.name }<br />
                            {data.screener?.email}
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr className="heading" key="heading2">
              <td>{L.applicant.screener}</td>
              <td>{L.banking.items}</td>
              <td>{L.banking.pay_earned}</td>
            </tr>

            {data && data.screeners && Object.keys(data.screeners).map((uid: string, index: number) => {
              let itemClass = "item";
              if (data.screeners.length - 1 === index) itemClass = "item last";
              if (data.metadata.items[uid]) {
                let rows = [<tr className={itemClass} key={`details2-${index}`}>
                  <td>{data.screeners[uid]?.name}</td>
                  <td></td>
                  <td></td>
                </tr>]
                data.metadata.items[uid].forEach((item: any) => {
                  rows.push(<tr className={itemClass} key={`details2-${index}`}>
                    <td></td>
                    <td>{item.date} - {item.kind}</td>
                    <td>${delimitNumbers(item.amount.toFixed(2))}</td>
                  </tr>)
                })
                return rows;
              }
            })}

            {data && (
              <tr className="total" key="total">
                <td></td>
                <td></td>
                <td>{L.banking.total}{": $"}{delimitNumbers(Math.abs(parseFloat(data.total_amount)).toFixed(2))}{" "}</td>
              </tr>
            )}
          </tbody>
        </table>
        {(configContext.roles || []).indexOf('admin') !== -1 &&
          <>{(((data.metadata?.request || data.metadata?.action) &&
            "Payment Issued")
            || <Button onClick={doPayInvoice}>{L.admin.invoices.issue_payment}</Button>)}&nbsp;
          <Button onClick={copyAnonymousLink}>{L.admin.invoices.copy_link}</Button></>}
      </div>
      
    </>
  );
}


function ScreeningInvoicePage() {
  const { payment_id } = useParams() as Record<string, string>;
  if (payment_id.indexOf("itemized") !== -1) {
    return <ItemizedScreeningInvoicePage />
  }
  return <LegacyScreeningInvoicePage />
}

export default ScreeningInvoicePage;
