import React, { useState, useRef, useEffect } from "react";
import { Accordion, Alert, Button, Card, Form, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAPI, useAPIPost, useDownloader } from "./API";
import { useLocalizedStrings } from "./Localization";

type QueryProps = {
  id: any,
  author: string,
  created_at: string,
  query: string,
  pinned: boolean,
  runCallback: (queryId: any) => void,
  refresh: any
}

type Query = {
  "id": any,
  "author": string,
  "created_at": string,
  "query": string,
  "pinned": boolean
}

type QuerySortKey = "id" | "author" | "created_at" | "query" | "pinned";

function QueryLogElement(query: QueryProps) {
  const L = useLocalizedStrings();

  const pinQuery = useAPIPost("/querylog/" + query.id + "/pin");
  const unpinQuery = useAPIPost("/querylog/" + query.id + "/unpin");

  const doPin = async () => { await pinQuery({}); query.refresh(); }
  const doUnPin = async () => { await unpinQuery({}); query.refresh(); }

  return (
    <tr key={query.id  + '_listitem'}>
      <td>{query.author}</td>
      <td>{query.created_at}</td>
      <td>{query.query}</td>
      <td>{query['pinned'] ? "📌" : ''}</td>
      <td>
        <Button 
          onClick={() => query.runCallback(query.id)}
          key={query.id + "_run"}>
          {L.queries.run_query}
        </Button>&nbsp;
        {!query.pinned && <Button
          onClick={() => doPin()}
          key={query.id + "_save"} variant="success">
          {L.queries.save_query}
        </Button>}
        {query.pinned && <Button
          onClick={() => doUnPin()}
          key={query.id + "_unpin"} variant="danger">
          {L.queries.unpin}
        </Button>}
      </td>
    </tr>
  );

}

function QueriesPage() {
  const L = useLocalizedStrings();
  const [queries, refreshQueries] = useAPI("/querylogs/list_queries", true);
  const [sortedQueries, setSortedQueries] = useState([]);
  const [sortKey, setSortKey] = useState("created_at" as QuerySortKey);
  const [sortAscending, setSortAscending] = useState(false);

  const customQuery = useAPIPost("/querylogs/custom_query");
  const cloneQuery = useAPIPost("/querylog/.../clone_query");

  const downloadPath = useDownloader();

  const [query, setQuery] = useState('');

  const queryRef = useRef(null);
    
  const runQuery = useAPIPost("/querylog/.../run_query");

  const [queryResults, updateQueryResults] = useState({} as any);

  const [tableVariant, setTableVariant] = useState("light");

  // For when you create a new query and run it fresh
  async function runNewQuery(queryId: number) {
    const result = await runQuery({}, "/querylog/" + queryId + "/run_query");

    const key = queryId;
    updateQueryResults({ ...result, key: key});
  }

  // This function runs a query from the log (creates a new query and runs that)
  async function runExistingQuery(queryId: number) {

    // create a new query log item:
    const query = await cloneQuery({}, "/querylog/" + queryId + "/clone_query");
    await refreshQueries();
    await runNewQuery(query.id);

    setQuery(query.query);
  }


  async function saveQueryAnd(action: 'run' | 'export') {
    if(!query){
      toast.error(L.queries.no_query_entered);
      return;
    }

    const theQuery = await customQuery({ query });
        
    if(theQuery && theQuery.id){
      if (action === 'run'){
        queryRef.current = theQuery.id;
        await refreshQueries();
        await runNewQuery(theQuery.id);
      } else {
        downloadPath('/querylog/' + encodeURIComponent(theQuery.id) + '/export_query');
      }
    }
  }

  function updateSort(key: QuerySortKey) {
    setSortKey(key);
    setSortAscending(!sortAscending);
  }
    
  useEffect(() => {
    if (queries.queries) {
      setSortedQueries(
        queries.queries
          .sort((a: Query, b: Query) => {
            if (!a[sortKey] || !b[sortKey]) return 0;
            const n = a[sortKey].localeCompare(b[sortKey]);

            if(sortAscending === true) return n;
            return n * -1;
          })
          .slice(0)
      );
    }
  }, [sortKey, queries.queries, sortAscending]);

  function stringify(v: any) {
    if (typeof v === "number" || typeof v === "string") {
      return v;
    }
    return JSON.stringify(v);
  }

  return (
    <>
      <h2>{L.queries.custom_queries}</h2>
      <b>{L.queries.new_query}</b>&nbsp;
      <Form.Group>
        <Form.Label>{L.queries.query}</Form.Label>
        <Form.Control as="textarea" rows={5} 
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </Form.Group>
      <Button 
        onClick={() => saveQueryAnd('run')}>
        {L.queries.save_and_run_query}
      </Button>&nbsp;
      <Button variant="success" onClick={() => saveQueryAnd('export')}>
        Save Query and Export to CSV
      </Button>
      <div className="mt-2">
        <div className="d-flex justify-content-end">
          <div className="col-6 col-sm-3">
            <Form.Control size="sm" defaultValue={tableVariant} 
              as="select" onChange={(e) => setTableVariant(e.target.value)}>
              <option value="light">{L.queries.light}</option>
              <option value="dark">{L.queries.dark}</option>
            </Form.Control>
          </div>
        </div>
        {queryResults && 
                queryResults.columns && (
          <>
            <Accordion defaultActiveKey="1" className="mt-2">
              <Card>
                <Accordion.Toggle eventKey="1" as={Card.Header}>
                  <span>{L.queries.click_to_hide}</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className="table-responsive" style={{ maxHeight: '1000px', overflowY: 'scroll' }}>
                      <Table variant={tableVariant} className="mt-2">
                        <thead>
                          <tr>
                            {queryResults.columns.map((c: any) => {
                              return (<th key={c + "_header"}>{c}</th>);
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {queryResults.results.map((r: any, ri: number) => {
                            return (<tr key={"row_" + ri}>
                              {queryResults.columns.map((c: any, i: number) => {
                                return (<td key={i}>{stringify(r[c])}</td>);
                              })}
                            </tr>);
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </>
        )}
      </div>
      {queryResults && queryResults.error && (
        <div className="bg-warning">
          {queryResults.error}
        </div>            
      )}
      <Accordion defaultActiveKey="0" className="mt-2">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">{L.queries.pinned_queries}</Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {sortedQueries && sortedQueries.filter(q => q['pinned']).length > 0 && (
                <Table size="sm" variant={tableVariant}>
                  <thead>
                    <tr>
                      <th className="cursor-pointer" onClick={(e) => updateSort("author")}>{L.queries.author}</th>
                      <th className="cursor-pointer" onClick={(e) => updateSort("created_at")}>{L.queries.run_date}</th>
                      <th className="cursor-pointer" onClick={(e) => updateSort("query")}>{L.queries.query}</th>
                      <th className="cursor-pointer" onClick={(e) => updateSort("pinned")}>{L.queries.pinned}</th>
                      <th>{L.queries.actions}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedQueries.map((query: Query) => {
                      if (query['pinned']){
                        return (
                          React.createElement(QueryLogElement, {
                            key: query?.id,
                            refresh: refreshQueries,
                            runCallback: runExistingQuery,
                            ...query
                          })
                        );
                      }
                      return <></>
                    })}
                  </tbody>
                </Table>
              )}
              {sortedQueries?.filter(q => q['pinned']).length === 0 && (
                <Alert type="info" variant="secondary">{L.queries.no_pinned_queries}</Alert>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Accordion defaultActiveKey="0" className="mt-2">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">{L.queries.all_queries}</Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div className="table-responsive" style={{ maxHeight: '1000px', overflowY: 'scroll' }}>
                <Table size="sm" variant={tableVariant}>
                  <thead>
                    <tr>
                      <th className="cursor-pointer" onClick={(e) => updateSort("author")}>{L.queries.author}</th>
                      <th className="cursor-pointer" onClick={(e) => updateSort("created_at")}>{L.queries.run_date}</th>
                      <th className="cursor-pointer" onClick={(e) => updateSort("query")}>{L.queries.query}</th>
                      <th className="cursor-pointer" onClick={(e) => updateSort("pinned")}>{L.queries.pinned}</th>
                      <th>{L.queries.actions}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedQueries.map((query: Query) => {
                      return (
                        React.createElement(QueryLogElement, {
                          key: query?.id,
                          refresh: refreshQueries,
                          runCallback: runExistingQuery,
                          ...query
                        })
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <div style={{ height: '50px' }}></div>
    </>
  );
}

export default QueriesPage;