import React, { useState, useEffect, useRef, useCallback, useMemo, useContext } from "react";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { toast } from "react-toastify";
import { useAPI, useAPIPost } from "../API";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionProps } from "./Props";
import {
  useInterval,
  copyToClipboard,
  snakeToEnglish,
  languageContent,
  safeParse
} from "../Util";

import InterfaceContext from "../Context";
import { useLocalizedStrings } from "../Localization";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";

function ContractQuestion(props: QuestionProps) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const rawMetadata = props["Metadata"]!;
  const metadata = useMemo(() => safeParse(rawMetadata), [rawMetadata]);
  const contract = metadata["contract"];
  const signers: any = {};
  for (const signer in metadata["signers"]) {
    signers[signer] = props.info[metadata["signers"][signer]] || "";
  }
  const host = "https://esign.aidkit.cloud";
  const createContract = useAPIPost(host + "/create");
  const updateContract = useAPIPost(host + "/update");
  const textContract = useAPIPost(host + "/send");

  const [phoneNumbers, setPhoneNumbers] = useState({} as any);

  const showSignatures =
    (props["Additional Options"] || []).indexOf("No Signatures") === -1;

  // TODO: This doesn't update if we replace the contract
  // EDIT: I think it does now
  const [contractStatus, checkContract] = useAPI(
    host + "/check?key=" + (props.info[props["Target Field"]!] || ""),
    true
  );

  const getNotifyLink = useAPIPost("/applicant/" + props.uid + "/get_notify_url");

  const refContractStatus = useRef(contractStatus);
  useEffect(() => {
    refContractStatus.current = contractStatus;
  }, [contractStatus]);

  const refContract = useRef(props.info[props["Target Field"]!]);
  useEffect(() => {
    refContract.current = props.info[props["Target Field"]!];
  });

  const [staleParams, setStaleParams] = useState(false);
  const computeParams = useCallback((info: any, metadata: any) => {
    const params: any = {};
    for (const key in metadata["params"]) {
      params[key] = info[metadata["params"][key]];
    }
    //for (const _ in metadata["signers"]) {
    // console.log(key, metadata['signers'][key]);
    //}
    return params;
  }, []);

  function getDate() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "long" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return da + " " + mo + ", " + ye;
  }

  // Create or replace the contract
  async function sendContract() {
    const params = computeParams(props.info, metadata);
    params["date"] = getDate();

    const contractOptions = {
      template_url: contract,
      params: params,
      signers: signers,
    } as any;

    if (!props.provisional){
      const response = await getNotifyLink({ 
        route: 'contract_update', 
        metadata: { contract_name: props["Target Field"]! }
      });
      const link = response['link'];
      console.log("Creating contract with notify: ", link);
      contractOptions['notify'] = link;
    }
    
    const result = await createContract(contractOptions);

    setStaleParams(false);
    props.setInfoKey(props["Target Field"]!, result.master_key, true, false);
    props.setInfoKey(props["Target Field"]! + "_stale", "no", true, false);
    await checkContract(host + "/check?key=" + result.master_key);
  }

  // Check the contract continuously unless everyone has signed
  useInterval(async () => {
    if (refContract.current !== undefined)
      await checkContract(host + "/check?key=" + refContract.current);
  }, 3000);


  // Propagate the signed status of everything
  const targetField = props["Target Field"]!;
  const propsInfo = props.info;
  const setInfoKey = useCallback(props.setInfoKey, [propsInfo]);
  useEffect(() => {
    if (!contractStatus["status"]) return;

    // Create or replace the contract
    async function pushContractUpdates() {
      const params = computeParams(propsInfo, metadata);
      params["date"] = getDate();
      await updateContract(
        {
          params: params,
        },
        host + "/update?key=" + refContract.current
      );
    }

    async function pushContractSignerUpdates(newSigners: any) {
      await updateContract(
        {
          signers: newSigners
        },
        host + "/update?key=" + refContract.current
      );
    }

    let anySigned = false;
    let rolesSigned = [];
    for (const role in contractStatus["status"]) {
      const key = targetField + "_" + role;
      const state = contractStatus["status"][role];
      if(state === "signed") rolesSigned.push(role)
      anySigned = anySigned || state === "signed";
      if (propsInfo[key] !== state) {
        // console.log("[key]: ", key, "[state]: ", state);
        setInfoKey(key, state, true, false);
      }
    }

    // Check for stale params
    const params = computeParams(propsInfo, metadata);
    let stale = false;
    for (const key in params) {
      if (refContractStatus.current === null) continue;

      if (params[key] !== contractStatus["params"][key]) {
        // Special case params that were never supplied to the contract
        // TODO: Clean this up

        if (
          contractStatus["params"][key] === undefined 
          && (params[key] === "0" || params[key] === undefined)
        ) {
          continue;
        }

        // Special case params that were not previously given
        // and not important enough to make a contract stale
        const ignoreUndefinedParams = metadata['ignoreUndefinedParams'];
        if ( contractStatus["params"][key] === undefined && ignoreUndefinedParams?.includes(key)) {
          continue;
        }


        // Special case params that show hide various months
        if (key.indexOf("show_") === 0) continue;

        console.log(
          "Mismatching param",
          key,
          params[key],
          contractStatus["params"][key],
          staleParams
        );
        stale = true;
      }
    }

    let signersStale = false;
    for (const signer in signers){
      if(refContractStatus.current === null) continue;

      if(signers[signer] !== contractStatus["signers"][signer]){
        console.log("Mismatching signer", signer, signers[signer], contractStatus["signers"][signer]);

        if(rolesSigned.includes(signer)){
          stale = true;
        }else{
          signersStale = true;
        }

      }
    }

    (async () => {
      if (signersStale && !stale){
        refContractStatus.current = null;
        await pushContractSignerUpdates(signers);
      }

      if (stale) {
        if (!anySigned) {
          refContractStatus.current = null;
          await pushContractUpdates();
        } else {
          setStaleParams(stale);
          if (propsInfo[targetField + "_stale"] !== "stale") {
            if (!staleParams) {
              setInfoKey(
                targetField + "_stale",
                "stale",
                true,
                false
              );
            }
          }
        }
      } else {
        if (propsInfo[targetField + "_stale"] === "stale") {
          setInfoKey(
            targetField + "_stale",
            "no",
            true,
            false
          );
        }
        setStaleParams(stale);
      }
    })();
  }, [contractStatus, updateContract, computeParams, targetField, metadata, propsInfo, setInfoKey, staleParams, signers]);

  function copyLinkFor(role: string, lang: string) {
    copyToClipboard(contractStatus.links[role] + "&lang=" + lang, L.copied_to_clipboard);
  }

  function openLinkFor(role: string, lang: string) {
    window.open(contractStatus.links[role] + "&lang=" + lang, "_blank noopener noreferrer")
  }

  async function sendLinkFor(role: string, lang: string) {
    let value = phoneNumbers[role];
    if (!value) {
      toast.error(L.questions.contract.no_phone_number_for_role.replace('%v',role));
      return;
    }

    if (value.indexOf('@') === -1) {
      value = value.replace(/[^0-9]/g,'');
    }

    const exclusions = ((metadata.exclude || {})[role] || []);
    for (const exc of exclusions) {
      if (exc[0] === 'info') {
        if (value === propsInfo[exc[1]]) {
          alert('Sending this agreement to this contact is not allowed');
          return;
        }
      }
      if (exc[0] === 'screener') {
        let comp = props.screenerMetadata[exc[1]] || '';
        if (comp.indexOf('@') === -1) {
          comp = comp.replace(/[^0-9]/g,'');
        }
        if (value === comp) {
          alert('Sending this agreement to this contact is not allowed');
          return;
        }
      }
    }

    await textContract(
      {
        role: role,
        number: phoneNumbers[role],
        lang: lang,
      },
      host + "/send?key=" + refContract.current
    );
    toast(L.applicant.contract_sent);
  }

  function setPhoneNumber(role: string, number: string) {
    setPhoneNumbers((prevState: any) => {
      const nextState: any = Object.assign({}, prevState);
      nextState[role] = number;
      return nextState;
    });
  }

  function askIfSureAndSendContract(): void {
    if (window.confirm(L.questions.contract.are_you_sure_you_want_to_reset)){
      sendContract()
    }
  }

  return (
    <div>
      <div>{useModularMarkdown({
        content: props[languageContent(context.lang)] || '',
        info: props.info,
      })}</div>
      {staleParams && (
        <>
          <Alert variant="danger">
            {L.questions.contract.this_contract_is_out_of_date}
            <br />
            <br />
            <Button
              style={{ float: "right" }}
              variant="danger"
              onClick={sendContract}
            >
              {L.questions.contract.replace_contract}
            </Button>
            <br />
            <br />
          </Alert>
        </>
      )}
      {props.info[props["Target Field"]!] && (
        <>
          {!props.provisional && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={host + "/view?key=" + props.info[props["Target Field"]!]}
            >
              {L.questions.contract.view_contract}
            </a>
          )}
          <Dropdown style={{ float: "right" }}>
            <Dropdown.Toggle 
              variant="link"
              bsPrefix="not-caret"
              title={L.questions.contract.reset_contract_careful}
            >...</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={askIfSureAndSendContract}>{L.questions.contract.reset_contract_careful}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <br />
        </>
      )}
      <br />
      {!props.info[props["Target Field"]!] && (
        <Button onClick={sendContract}>{L.questions.contract.create_contract}</Button>
      )}
      {showSignatures &&
        contractStatus &&
        contractStatus.links && signers &&
        (Object.keys(signers).map((signer: string) => {
          return (
            <div key={signer}>
              {!(props["Additional Options"] || []).includes("Embedded") && !props.selfServe && (
                <>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {snakeToEnglish(signer)}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      placeholder={L.questions.contract.phone_number_or_email}
                      onChange={(e) => setPhoneNumber(signer, e.target.value)}
                      value={phoneNumbers[signer] || ""}
                    />
                  </InputGroup>
                  <InputGroup className="mt-1 d-flex justify-content-end">
                    <DropdownButton as={InputGroup.Prepend}
                      title={L.questions.contract.send_link}>
                      <Dropdown.Item onClick={(e) => sendLinkFor(signer, "en")}>
                        {L.questions.contract.send_link_in_english}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => sendLinkFor(signer, "es")}>
                        {L.questions.contract.send_link_in_spanish}
                      </Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton variant="secondary"
                      as={InputGroup.Append}
                      title={L.questions.contract.copy_link}>
                      <Dropdown.Item onClick={(e) => copyLinkFor(signer, "en")}>
                        {L.questions.contract.copy_link_in_english}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => copyLinkFor(signer, "es")}>
                        {L.questions.contract.copy_link_in_spanish}
                      </Dropdown.Item>
                    </DropdownButton>
                  </InputGroup>
                </>
              )}
              {(props.selfServe || (props["Additional Options"] || []).includes("Embedded")) && (
                <InputGroup className="mt-1 d-flex justify-content-end">
                  <Button variant="primary"
                    onClick={(e) => openLinkFor(signer, context.lang)}>{L.questions.contract.open_contract}</Button>
                </InputGroup>
              )}
              <br/>
            </div>
          );
        }))}
    </div>
  );
}

function ContractSignerQuestion(props: QuestionProps) {
  const L = useLocalizedStrings();
  const status = props.info[props["Target Field"]!];
  if (status) {
    return (
      <>
        <b>{snakeToEnglish(props["Target Field"]!) + " " + L.questions.contract.status}</b>{" "}
        <h3>
          <Badge variant={status === "signed" ? "success" : "warning"}>
            {snakeToEnglish(status)}
          </Badge>
        </h3>
      </>
    );
  }
  return <></>;
}

export { ContractQuestion, ContractSignerQuestion };
