import React, { useMemo } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionProps } from "./Props";
import { AttachmentQuestion } from "./Attachment";
import { useLocalizedStrings } from "../Localization";
import Button from "react-bootstrap/Button";
import { Alert } from "react-bootstrap";
import { useAPIPost } from "../API";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { safeParse } from "../Util";

function DeviceSetup(props: QuestionProps) {
  const sendResourcePage = useAPIPost("/applicant/" + props.uid + "/send_resource_page");

  const rawMetadata = props["Metadata"]!;
  const metadata = useMemo(() => safeParse(rawMetadata), [rawMetadata]);
  const fixed_phone = metadata["fixed_phone"];

  const L = useLocalizedStrings();

  async function dissasociate() {
    if (!window.confirm(L.device.are_you_sure)) {
      return;
    }
    props.setInfoKey(props["Target Field"]!, "", true, false);
    props.setInfoKey(props["Target Field"]! + "_url", "", true, false);
  }

  async function resend_resource_page() {
    const result = await sendResourcePage({});
    if (result.error) {
      alert(result.message);
    } else {
      toast("Message sent!")
    }
  }

  let phone_number = null;
  let imei = null;
  if (props.info[props["Target Field"]! + "_url"]) {
    let params = new URLSearchParams(props.info[props["Target Field"]! + "_url"].split('?')[1]);
    phone_number = params.get("phone")?.replace(/[^-0-9]/g, "");
    imei = params.get("imei")?.replace(/[^0-9]/g, "");
  }

  if (props.info[fixed_phone] === 'yes') {
    phone_number = props.info['phone_number'];
  }

  if (phone_number && imei) {
    return <div>
      <Alert variant="success">
        <h3>{L.device.associated}</h3>
        <ul>
          <li><b>{L.device.phone_number}</b> {phone_number}</li>
          <li><b>{L.device.imei}</b> {imei}</li>
        </ul>
        <Button onClick={resend_resource_page}>{L.device.resend_resource_page}</Button>
        <Button variant="link" onClick={dissasociate}>{L.device.dissasociate}</Button>
      </Alert>
    </div>;
  } else {
    return <>
      {props.info[props["Target Field"]! + "_error"] &&
        <Alert variant="warning">QR Upload Error: We are having issues recognizing the QR code upload, please enter the required information (IMEI, SIM, Phone Number) manually to complete the association process.  Thanks!</Alert>}
      {!props.info[props["Target Field"]! + "_error"] && props.info[props["Target Field"]!] && <>
        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;
        {L.device.processing}<br /><br />
      </>}
      {(!props.info[props["Target Field"]!] || props.info[props["Target Field"]! + "_error"]) && L.device.upload_qr}
      {
        React.createElement(AttachmentQuestion, {
          ...props,
        })
      }
    </>
  }

}

export { DeviceSetup };
