import { CheckIcon, XMarkIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { useEffect } from 'react';
import { useState, useContext } from 'react';
import { get_deployment, usePost } from './API';
import { ClickableButton } from './Components/Button';
import { TailwindSwitch } from './Components/Switch';
import { SpacedSpinner, snakeToEnglish } from './Util';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

type MessageSamplesType = {
  [key: string]: string;
};

export function ResultsModal({ 
  bought,
  nextSteps,
  onClose,
  distroUpdateRes
} : {
  bought: any,
  nextSteps: string[],
  onClose: () => void,
  distroUpdateRes?: { success: boolean, reason?: string }
}) {
  let successMessage;
  let failureMessage;
  if (distroUpdateRes?.success) {
    successMessage = 'The new comms values were successfully copied into the distro for entireprogram.';
  } else {
    failureMessage = `The new comms values failed to copy to the distro for entireprogram for reason: ${distroUpdateRes?.reason}.
            Please visit the distro and input these values manually to your Comms Config.
        `;
  }

  return <>
    <Modal show size="lg" onHide={onClose} >
      <Modal.Header closeButton>
        <Modal.Title className="pl-2">
          <h2>Success!</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          {successMessage && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2.5 rounded relative mb-3" role="alert">
            <span className="block sm:inline">{successMessage}</span>
          </div>}
          {failureMessage && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{failureMessage}</span>
          </div>}
          {bought.phoneNumber ? <><p><b>Provisioned number:</b> <code>{bought.phoneNumber}</code> with SID <code>{bought.phoneNumberSID}</code>. </p> </> : <></>}
          {bought.smsServiceSID ? <p><b>SMS Messaging service <code>{bought.smsServiceName}</code> provisioned: </b><code>{bought.smsServiceSID}</code>. </p> : <></> }
          {bought.whatsAppServiceSID ? <p><b>WhatsApp Messaging service <code>{bought.whatsappServiceName}</code> provisioned:</b> <code>{bought.whatsAppServiceSID}</code>.</p> : <></> }
          {bought.twiMLApplicationSID ? <p><b>TwiML Application provisioned:</b> <code>{bought.twiMLApplicationSID}</code></p> : <></>}
          {bought.verificationServiceSID ? <p><b>Verification Service provisioned:</b><code>{bought.verificationServiceSID}</code></p> : <></>}
        </div>
        {nextSteps.length && <div className='flex-column pt-6 border-t border-gray-200'>
          <h3 className='mb-2'>Next steps:</h3>
          {nextSteps.includes('use-case') && <div>
            <div className='flex items-center'>
              <ArrowRightIcon className='h-5 mr-2' />
              <span className='text-lg font-bold'>Create a Campaign use case</span>
            </div>
            <p className='ml-5'>Before connecting your new messaging service to our AidKit brand, we need to share that brand with the new subaccount we just created.
              This must be done from the Twilio console - navigate to the brand and you will find an option to share with existing subaccounts.</p>
            <p className='ml-5'>Once the request to share the brand has been approved by Twilio, you can revisit this page to connect the campaign by modifying the messaging service, 
              or create the campaign directly in the Twilio console.</p>
          </div>}
          {nextSteps.includes('whatsapp-sender') && <div>
            <div className='flex items-center'>
              <ArrowRightIcon className='h-5 mr-2' />
              <span className='text-lg font-bold'>Register a WhatsApp Sender</span>
            </div>
            <p className='ml-5'>WhatsApp senders need to be connected to an approved WhatsApp for business account, and must be created from the Twilio Console.
              Navigate to the program subaccount in Twilio and go to {"Messaging -> Senders -> WhatsApp Senders"} to start this process. </p>
          </div>}
        </div>}
      </Modal.Body>
    </Modal>
  </>;
}

export function ProvisionPage(props: any) {
  const [phoneNumbers, setPhoneNumbers] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [areaCode, setAreaCode] = useState(0);
  const [program, setProgram] = useState(get_deployment());

  const [createSMSMessagingServices, setCreateSMSMessagingServices] = useState(false);
  const [createWhatsAppMessagingService, setCreateWhatsAppMessagingService] = useState(false);
  const [createVerificationService, setCreateVerificationService] = useState(false);
  const [timer, setTimer] = useState(null as ReturnType<typeof setTimeout> | null);

  const [preview, setPreview] = useState('');
  const [provisioning, setProvisioning] = useState(false);
  const [overwriteExisting, setOverwriteExisting] = useState(false);
  const [overwriteExistingWhatsApp, setOverwriteExistingWhatsApp] = useState(false);

  // Friendly name we give the SMS Messaging Service & TwimML app
  const [serviceName, setServiceName] = useState(get_deployment());
  // Friendly name we give the WhatsApp Messaging Service
  const [whatsAppServiceName, setWhatsAppServiceName] = useState(get_deployment() + ' - WhatsApp');
  // Channel name in Distro - default, ia, etc
  const [channelName, setChannelName] = useState('');

  const [bought, setBought] = useState({} as any);
  const [nextStepsRequired, setNextStepsRequired] = useState<string[]>([]);
  const [distroUpdateRes, setDistroUpdateRes] = useState<{ success: boolean, reason?: string }>();

  // state variables to determine availability of message service names
  const [fetchingExistingService, setFetchingExistingService] = useState(false);
  const [existingServices, setExistingServices] = useState([] as string[]);
  const [fetchedServiceNames, setFetchedServiceNames] = useState([] as string[]);

  const [existingCommsAccount, setExistingCommsAccount] = useState<any>({});

  const [hasEmbeddedLinks, setHasEmbeddedLinks] = useState(false);

  const [addVoiceCalling, setAddVoiceCalling] = useState(false);

  const getPhoneNumbers = usePost('/admin/messaging/search_numbers');
  const getMessagingServices = usePost('/admin/messaging/service');
  const buyPhoneNumber = usePost('/admin/messaging/provision_phone_number');
  const provisionMessagingService = usePost('/admin/messaging/provision_service');
  const provisionVerificationService = usePost("/admin/messaging/provision_verification_service");
  const provisionTwiML = usePost('/admin/messaging/provision_application');
  const getCurrentCommsAccount = usePost('/admin/messaging/get_comms_subaccount');
  const rotateSubAccountKeys = usePost('/admin/messaging/rotate_subaccount_keys');
  const updateDistroComms = usePost('/admin/messaging/update_distro_comms');

  useEffect(() => {
    (async () => {
      const res = await getCurrentCommsAccount({});
      setExistingCommsAccount(res);
    })();
  }, []);

  async function doProvision() {
    console.log("Provisioning...");
    setProvisioning(true);
    const result = await buyPhoneNumber({
      phoneNumber: preview,
      channelName,
      program
    });
    console.log("buy phone outcome", result);

    let justBought: any = {
      phoneNumber: preview,
      phoneNumberSID: result.sid
    };

    if (result && result.sid && createSMSMessagingServices) {
      const service = await provisionMessagingService({
        name: serviceName,
        channelName,
        phoneNumbers: [result.sid],
        overrideExistingService: overwriteExisting
      })
      console.log("SMS Service outcome", service);

      // We have to manually share the brand in the twilio console to submit a campaign use case.
      setNextStepsRequired(prev => {
        return [...prev, 'use-case']
      });

      justBought = {
        ...justBought,
        smsServiceSID: service.service,
        smsServiceName: serviceName,
        useCase: service.useCase
      };
    }

    if (result && result.sid && createWhatsAppMessagingService) {
      const service = await provisionMessagingService({
        name: whatsAppServiceName,
        channelName,
        whatsApp: true,
        overrideExistingService: overwriteExistingWhatsApp,
      })
      console.log("WhatsApp Service outcome", service);

      justBought = {
        ...justBought,
        whatsAppServiceSID: service.service,
        whatsAppServiceName,
        useCase: service.useCase
      };

      setNextStepsRequired(prev => {
        return [...prev, 'whatsapp-sender']
      });
    }

    if (addVoiceCalling) {
      const twiml = await provisionTwiML({
        name: serviceName,
        channelName,
        phoneNumber: result.sid
      });
      console.log("Provision TwiML outcome", twiml);
      justBought = {
        ...justBought,
        twiMLApplicationSID: twiml.application
      };
    }

    console.log("RW", result, createVerificationService);
    if (result && result.sid && createVerificationService) {
      const verificationService = await provisionVerificationService({
        name: serviceName,
        channelName
      });
      justBought = {
        ...justBought,
        verificationServiceSID: verificationService.service
      };
    }

    setBought(justBought);

    const res = await updateDistroComms({
      channelName,
      phoneNumber: justBought.phoneNumber,
      verifyService: justBought.verificationServiceSID,
      smsService: justBought.smsServiceSID,
      whatsAppService: justBought.whatsAppServiceSID,
      twimlService: justBought.twiMLApplicationSID
    });

    setDistroUpdateRes(res);
    setProvisioning(false);
  }

  useEffect(() => {
    if (timer) clearTimeout(timer);
    if (!serviceName) return;
    setFetchingExistingService(false);
    setTimer(setTimeout(() => {
      (async () => {
        setFetchingExistingService(true);
        const serviceExists = await getMessagingServices({ 
          names: [serviceName, whatsAppServiceName],
          channelName
        });

        setFetchedServiceNames([serviceName, whatsAppServiceName]);
        if (serviceExists && serviceExists.services) {
          setExistingServices(serviceExists.services);
        } else {
          setExistingServices([]);
        }
        setFetchingExistingService(false);
      })()
    }, 500));

    return () => { if (timer) clearTimeout(timer) };
  }, [program, serviceName, whatsAppServiceName, channelName]);

  // input area code, get phone numbers
  return  <div className="p-4 items-center justify-center align-center">
    <div className="bg-white">
      <div className='border-b mb-4 border-gray-200'>
        <div className='bg-gray-100 rounded px-4 py-3 mb-4'>
          {existingCommsAccount.sid && 
                    <div className='flex justify-between'>
                      <div className='flex flex-column'>
                        <span className='text-lg font-bold mb-1'>Existing Twilio SubAccount: </span>
                        <span>Name: {existingCommsAccount.name} </span>
                        <span>SID: <code>{existingCommsAccount.sid}</code></span>
                        <div className='mt-3'>
                          <span className='font-bold'>Please note: </span> 
                          any new services provisioned here will be provisioned under this subaccount.
                        </div>
                      </div>
                      <div className='flex flex-column justify-end items-center mx-2'>
                        <ClickableButton 
                          color='indigo' colorIntensity={600} extraClasses="text-white" 
                          onClick={async () => {
                            const res = await rotateSubAccountKeys({});
                            if (res.msg) toast.success(res.msg);
                          }}>
                          Rotate subaccount API Keys
                        </ClickableButton>
                      </div>
                    </div>
          }
          {!existingCommsAccount.sid &&
                    <div>
                      <span className='font-bold'>Please note: </span> 
                      any new services provisioned here will be provisioned under a new program-specific subaccount. 
                      Any existing comms services for this program currently use the AidKit support subaccount, and will need to be ported 
                      over to the new program subaccount once it is created.
                    </div>
          }
        </div>
      </div>
      <div className="relative grid grid-cols-[max-content_1fr] max-w-7xl mx-auto">
        <section
          aria-labelledby="summary-heading"
        >
          <div className="max-w-2xl mx-auto lg:max-w-none pr-4">
            <div className="mt-1 flex flex-column">
              <h3>Choose a phone number</h3>
              <label className="text-gray-600">Enter Area Code You Want to Provision</label>
              <div className="flex flex-row">
                <input type="text" className="border rounded" autoFocus onChange={(e) => {
                  let val = e.target.value.replace(/[^0-9]/g, '');
                  setAreaCode(parseInt(val));
                }} />&nbsp;
                <ClickableButton color="green" onClick={async () => {
                  setLoading(true);
                  const numbers = await getPhoneNumbers({ areaCode });
                  setLoading(false);
                  setPhoneNumbers(numbers);
                }}>Get Numbers</ClickableButton>
              </div>
            </div>

            <div className="mt-6 flex flex-column">
              <label className="text-gray-600">OR Input Existing Phone Number you want to Add Services to</label>
              <div className="flex flex-row">
                <input type="text" className="block h-10 border rounded w-full" 
                  onChange={(e) => {
                    let val = '+1' + e.target.value.replace(/[^0-9]|^1/g, '');
                    setPreview(val);
                  }} />&nbsp;
              </div>
            </div>

            {loading && <div>Loading...</div>}
            <ul role="list" className="text-sm font-medium divide-y divide-white divide-opacity-10">
              {phoneNumbers.map((product) => (
                <li key={product.id} className="flex items-start py-6 space-x-4">
                  <ClickableButton color="blue" onClick={() => setPreview(product.phoneNumber)}>
                    {product.phoneNumber}
                  </ClickableButton>
                  <div className="flex-auto space-y-1">
                    <h3>{product.phoneNumber}</h3>
                    <p>{product.locality}</p>
                    <p>{product.region}</p>
                    <p>{product.postalCode}</p>
                  </div>
                  <p className="flex-none text-base font-medium text-white">{product.price}</p>
                </li>
              ))}
            </ul>
          </div>
        </section>
        {preview ? 
          <section
            aria-labelledby="payment-and-shipping-heading"
            className="lg:pl-4 lg:mx-auto lg:border-l"
          >
            <div className="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
              <div>
                <h3 id="contact-info-heading">
                  Purchase or Modify {preview}
                </h3>

                <div className="mt-6 col-span-3 sm:col-span-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Channel Name
                  </label>
                  <div className="mt-1">
                    <input type="text"
                      placeholder='default'
                      onChange={(e) => {
                        setChannelName(e.target.value);
                      }}
                      className="p-1 h-10 block w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <small className="my-auto">This needs to match the name of the CommsConfig you intend to use in Distro. (ie: default, ia, etc... )</small>
                </div>

                <div className="mt-6">
                  <TailwindSwitch label="Create Verification Service? (SMS Only, for 2FA)" 
                    checked={createVerificationService}
                    onChange={() => setCreateVerificationService(prevState => !prevState)}
                  />
                </div>

                <div className="mt-6">
                  <TailwindSwitch label="Create SMS Messaging Service?" 
                    checked={createSMSMessagingServices}
                    onChange={() => setCreateSMSMessagingServices(prevState => !prevState)}
                  />
                </div>

                {createSMSMessagingServices ? 
                  <div className="bg-indigo-100 rounded-lg p-4 mt-6">
                    <div className="col-span-3 sm:col-span-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Service name, leave blank to use program ({program})
                      </label>
                      <div className="mt-1">
                        <input type="text"
                          onChange={(e) => {
                            const value = (e.target.value && e.target.value.length > 0) ? e.target.value : program;
                            setServiceName(value);
                          }}
                          className="p-1 h-10 block w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {fetchingExistingService && <div className="float-right" style={{position: "relative", top: "-33.5px"}}><SpacedSpinner />&nbsp;</div>}
                      </div>
                      {existingServices && existingServices.includes(serviceName) ? 
                        <div className="flex flex-row"><div className="mr-1 my-auto">
                          <XMarkIcon className="h-6 text-red-400" />
                        </div>
                        <small className="text-red-500 my-auto">A service already exists with name {serviceName}</small>
                        </div>
                        : serviceName && fetchedServiceNames.includes(serviceName) && !fetchingExistingService ? 
                          <div className="flex flex-row justify-start">
                            <div className="mr-1 my-auto">
                              <CheckIcon className="h-6 text-green-400" />
                            </div>
                            <small className="text-gray-900 my-auto">The messaging service <code>{serviceName}</code> is available</small>
                          </div> : <></>}
                    </div>

                    <div className="mt-6">
                      <TailwindSwitch label="Has embedded links" 
                        checked={hasEmbeddedLinks}
                        onChange={() => setHasEmbeddedLinks(prevState => !prevState)}
                      />
                    </div>

                    <div className="mt-6 flex items-center">
                      <TailwindSwitch label="Modify existing service"
                        checked={overwriteExisting}
                        onChange={() => setOverwriteExisting(prevState => !prevState)}
                      />
                    </div>
                  </div>
                  : <></>}

                <div className="mt-6">
                  <TailwindSwitch label="Create WhatsApp Messaging Service?" 
                    checked={createWhatsAppMessagingService}
                    onChange={() => setCreateWhatsAppMessagingService(prevState => !prevState)}
                  />
                </div>

                {createWhatsAppMessagingService ? 
                  <div className="bg-indigo-100 rounded-lg p-4 mt-6">
                    <div className="col-span-3 sm:col-span-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Service name, leave blank to use default name <code>({whatsAppServiceName})</code>
                      </label>
                      <div className="mt-1">
                        <input type="text"
                          onChange={(e) => {
                            const value = (e.target.value && e.target.value.length > 0) ? e.target.value : program;
                            setWhatsAppServiceName(value);
                          }}
                          className="p-1 h-10 block w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    {(existingServices && existingServices.includes(whatsAppServiceName)) 
                      ? <div className="flex flex-row"><div className="mr-1 my-auto">
                        <XMarkIcon className="h-6 text-red-400" />
                      </div>
                      <small className="text-red-500 my-auto">A service already exists for channel {channelName} with name {whatsAppServiceName}</small>
                      </div> 
                      : (whatsAppServiceName && fetchedServiceNames.includes(whatsAppServiceName) && !fetchingExistingService) 
                        ? <div className="flex flex-row justify-start">
                          <div className="mr-1 my-auto">
                            <CheckIcon className="h-6 text-green-400" />
                          </div>
                          <small className="text-gray-900 my-auto">The messaging service <code>{whatsAppServiceName}</code> is available for channel <code>{channelName}</code></small>
                        </div> 
                        : null
                    }

                    <div className="mt-6 flex items-center">
                      <TailwindSwitch label="Modify existing service"
                        checked={overwriteExistingWhatsApp}
                        onChange={() => setOverwriteExistingWhatsApp(prevState => !prevState)}
                      />
                    </div>
                  </div>
                  : <></>}

                <div className="mt-6">
                  <TailwindSwitch label="Add Voice Calling" labelDescription="(TwiML App)" 
                    checked={addVoiceCalling}
                    onChange={() => setAddVoiceCalling(prevState => !prevState)}
                  />
                </div>

                <div className="mt-10 flex justify-end pt-6 border-t border-gray-200">
                  <ClickableButton 
                    disabled={channelName.trim() === ''}
                    onClick={async () => await doProvision()}
                    color="indigo" colorIntensity={600} extraClasses="text-white">
                    {provisioning && <SpacedSpinner />}Provision
                  </ClickableButton>
                </div>

                {(bought && bought.phoneNumber && !provisioning) ? 
                  <ResultsModal 
                    nextSteps={nextStepsRequired} 
                    bought={bought} 
                    onClose={() => setBought(undefined)} 
                    distroUpdateRes={distroUpdateRes} /> :
                  <></>
                }
              </div>
            </div>
          </section> : <></>}
      </div>
    </div>
  </div>;
}