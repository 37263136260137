import { useState } from 'react';
import { toast } from 'react-toastify';
import { usePost } from './API';
import { ClickableButton } from './Components/Button';

// Form to submit new program handle and airtable Id to the backend using a custom hook
function AddNewProgram() {
  let [handle, setNewHandle] = useState('');
  const createProgram = usePost('/program/create')
    
  return (
    <>
      <div>
        <div className="mt-1 flex flex-column">
          <label className="text-gray-300">Program Subdomain / DB Name</label>
          <div className="flex flex-row">
            <input type="text" 
              placeholder="New Program Name" 
              className="p-1 h-10 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={e => setNewHandle(e.target.value)} required/> 
          </div>
        </div>
        <div className="mt-6 flex flex-row justify-end">
          <ClickableButton color="indigo" colorIntensity={200}
            onClick={async (e) => {
              const result = await createProgram({handle}); 
              if (result && (result as any).status) {
                toast.success(`Successfully created program ${handle}`);
              }}}>Deploy</ClickableButton>
        </div>
      </div>
      <br />
    </>
  )
}

export function NewProgramPage(props: any) {
  return <div className="p-8 items-center justify-center align-center">
    <div className="bg-white">
      <div className="hidden lg:block fixed top-0 left-0 w-full h-full bg-indigo-900" aria-hidden="true" />

      <div className="relative grid grid-cols-1 gap-x-16 max-w-7xl mx-auto lg:px-8 lg:grid-cols-2 lg:pt-16">
        <section
          aria-labelledby="summary-heading"
          className="bg-indigo-900 text-indigo-300 py-12 md:px-10 lg:max-w-lg lg:w-full lg:mx-auto lg:px-0 lg:pt-0 lg:pb-24 lg:bg-transparent lg:row-start-1 lg:col-start-1"
        > 
          <div className="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
            <div className="mt-1 flex flex-column">
              <h1>Add New Program</h1>
              <AddNewProgram />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>;
}