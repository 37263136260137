import { useContext, useEffect, useState } from "react";
import { usePost } from "./API";
import { toast } from "react-toastify";
import { langToWord, supportedLanguages, useLocalizedStrings } from "./Localization";
import { BUTTON_CLASS } from "./Util";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import InterfaceContext, { SupportedLanguage } from "./Context";

export function VerificationPage(props: any) {
  const L = useLocalizedStrings();
  const params = new URLSearchParams(window.location.search);
  const challenge = params.get("challenge");

  const verify = usePost('/confirm/check_link');
  const [confirmed, setConfirmed] = useState<null | boolean>(null);

  useEffect(() => {
    (async () => {
      if (!challenge) return;
      setConfirmed(await verify({ key: challenge }));
    })()
  }, [challenge]);

  if (confirmed === false) return <div className="flex justify-center items-center h-screen bg-gray-100">
    <div className="bg-white border border-red-400 text-red-700 px-4 py-3 rounded-lg shadow-md max-w-sm text-center">
      <h1 className="text-lg font-semibold mb-2">{L.mfa_link.verification_failed}</h1>
      <p className="mb-4">{L.mfa_link.link_invalid}</p>
    </div>
  </div>

  return <div className="flex justify-center items-center h-screen bg-gray-100">
    <div className="bg-white border border-blue-400 text-blue-700 px-4 py-3 rounded-lg shadow-md max-w-sm text-center" role="alert">
      {confirmed === null 
        ? <div>{L.mfa_link.verifying}</div> 
        : <div>
          <strong className="font-bold m-1">{L.mfa_link.thank_you}</strong>
          <span className="block sm:inline m-1">{L.mfa_link.email_verified}</span>
        </div>}
    </div>
  </div>
}

export function ConfirmationStep(props: { 
  lang?: SupportedLanguage,
  supportCaseId?: string, 
  // If the user needs to click a button to start the verification process
  needReady?: boolean, 
  // For holding the state if the user switches tabs
  sent?: string, 
  setSent: (email: string) => void,
  // A list of emails which have been verified
  verified?: string[]
}) {

  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const [ready, setReady] = useState(!props.needReady);
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(props.sent || '');
  const sendLinkVerification = usePost('/confirm/send_link');
  const [lang, setLanguage] = useState(props.lang || 'en');

  // if state is updated outside this component we need to update here too
  useEffect(() => {
    setSent(props.sent || '');
  }, [props.sent]);

  if (sent) return <div className="bg-white p-5 rounded-lg shadow-md max-w-lg my-6">
    <h1 className="text-xl font-semibold mb-4">{L.mfa_link.post_send_instructions_title}</h1>
    <p>{L.mfa_link.sent_to_email.replace('$X', sent)}</p>
    <ol className="list-decimal list-inside bg-gray-100 p-4 rounded-lg">
      <li>{L.mfa_link.post_send_instructions_1}</li>
      <li>{L.mfa_link.post_send_instructions_2}</li>
      <li>{L.mfa_link.post_send_instructions_3}</li>
      <li>{L.mfa_link.post_send_instructions_4}</li>
    </ol>
    <p className="mt-4">{L.mfa_link.post_send_instructions_final_tip}</p>
  </div>

  return <div className="flex flex-col p-4">
    <div className="w-full max-w-lg bg-white rounded-lg shadow-md p-6 space-y-6">
      {props.verified ? 
        <div className="space-y-2">
          <h2 className="text-xl font-semibold text-gray-800">{L.mfa_link.verified_contacts}</h2>
          <ul className="list-disc pl-5 space-y-1">
            {props.verified.map(v => <li key={v} className="text-gray-700">{v}</li>)}
          </ul>
        </div> : null}
      {ready ? 
        <form className="space-y-4" onSubmit={async (e) => {
          if (e.currentTarget.checkValidity()) {
            e.preventDefault();
          }
          const response = await sendLinkVerification({ ...props, kind: "email", email, lang })
          if (response?.status) {
            toast.success(L.applicant.comms.sent);
          }
          setSent(email);
          props.setSent(email);
          // Clear email
          setEmail('');
        }}>
          <div>
            {props.needReady && <div className="float-right top-0 -mt-4 right-2">
              <button className="" onClick={() => setReady(false)}>X</button>
            </div>}
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {L.mfa_link.enter_an_email_to_verify}
            </label>
            <div className="mt-2">
              <input type="email" name="email_to_verify" id="email"
                pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                autoComplete="off" value={email}
                onChange={(e) => { 
                  const value = e.target.value;
                  setEmail(value)
                }}
                aria-describedby="email-desription" required
                className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="" />
            </div>
            <p className="mt-2 text-sm text-gray-500" id="email-description">
              {L.mfa_link.we_will_send_an_email}
            </p>
          </div>
          {/** Language selector */}
          <div className="w-64">
            <label htmlFor="language-select" className="block mb-2 text-sm font-medium text-gray-900">{L.mfa_link.select_a_language}</label>
            <select
              id="language-select"
              value={lang}
              onChange={(e) => {
                setLanguage(e.target.value as SupportedLanguage);
              }}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              aria-label="Select a language"
            >
              {Object.keys(supportedLanguages).map((lang) => (
                <option key={lang} value={lang}>
                  {supportedLanguages[lang as SupportedLanguage]} {lang !== 'en' ? '(' + langToWord(lang as SupportedLanguage, 'Name') + ')' : null}
                </option>
                        
              ))}
            </select>
          </div>
          <button className="w-full px-4 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" 
            type="submit">{L.mfa_link.send_verification_link}</button>
        </form> : <div>
          <button onClick={() => setReady(true)} className={BUTTON_CLASS}>
            {L.mfa_link.open_form}
          </button>
        </div>}
    </div>
  </div>
}