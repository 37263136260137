import React, { useState, useContext, useRef, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { usePost, useAPIUpload, get_deployment, useToken, handleDataError } from "../API";
import { useLinkKey } from "../Apply";
import InterfaceContext, { ConfigurationContext, SupportedLanguage } from "../Context";
import { langToWord, useLocalizedStrings } from "../Localization";
import { ArrowPathIcon, CameraIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ClickableButton } from "../Components/Button";
import { AidKitLogo, BarSpinner, SpacedSpinner, safeParse, saveKeyToCookieAndRelocateToUrl, useInterval, useMarkdown } from "../Util";
import * as v0 from "@aidkitorg/types/lib/survey";
import { LanguageDropdown, LanguageIcon } from "../Components/LanguageDropdown";
import { Dropdown } from "../Components/Dropdown";
import type { LivenessStage } from "aidkit/lib/application/liveness.schema";
import { LIVENESS_REQUEST_EVENT_TYPE } from "../Questions/LivenessDetection";

let debug = process.env.NODE_ENV === 'development' ? console.log : () => {};

/** Constants for Recording */
const MAX_CHUNKS = 8;
const CAPTURE_INTERVAL = 500;

function getScreenshot(videoEl: HTMLVideoElement): Promise<Blob> {
  const canvas = document.createElement("canvas");
  const settings = ((videoEl.srcObject!) as any).getVideoTracks()[0].getSettings()
  canvas.width = settings.width;
  canvas.height = settings.height;
  const context = canvas.getContext('2d');
    
  context!.drawImage(videoEl, 0, 0, canvas.width, canvas.height);

  // Sometimes the video frame is not ready and we need to try again.
  // Detect if the canvas is black and try again if so
  // (Limit to 3 attempts so we don't get stuck)
  let isBlack = true;
  for (let i=0; i<3; i++) {
    const pixelData = context!.getImageData(0, 0, canvas.width, canvas.height).data;
      
    for (let i = 0; i < pixelData.length; i += 4) {
      if (pixelData[i] !== 0 || pixelData[i + 1] !== 0 || pixelData[i + 2] !== 0) {
        isBlack = false;  // Found a non-black pixel
        break;
      }
    }

    if (isBlack) {
      console.log("Canvas is black", i+1, "trying again");
      context!.drawImage(videoEl, 0, 0, canvas.width, canvas.height);
    }
  }

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      resolve(blob!)
    }, "image/jpeg", 1.0);
  });
}

function handleErrors(data: any): void {
  if(data && data.error){    
    switch (data.error){
      case 'Not authorized':
      case "ld2_session_empty_please_start_over":
      case "ld2_link_expired":
      case "ld2_session_expired":
      case "ld2_not_authorized":
        // let these errors pass through and be handled by LivenessDetectionSessionPage
        // this prevents unnecessary toast errors
        return;
      default:
        // other errors should get handled normally
        return handleDataError(data);
    }
  }
}

export function LivenessDetectionPage(props: any) {

  const linkKey = useLinkKey();

  const [ready, setReady] = useState(false);
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);

  const { livenessId } = useParams<{ livenessId: string }>();
  const getLivenessSession = usePost("/applicant/get_liveness_session", { handleErrors });
  const [ errored, setErrored ] = useState(null as null | string);
  const [, auth_token] = useToken();
  const [livenessConfig, setLivenessConfig] = useState(null as null | v0.LivenessDetection);

  const backContent = useMarkdown(
    livenessConfig?.identification?.back?.content?.[context.lang] 
    || livenessConfig?.identification?.back?.content?.en
    || L.questions.identity.take_a_photo_of_back);

  function sendLivenessRefreshRequest(){
    if(window.opener){
      // auth needs to be refreshed
      // notify the parent window that we need a new liveness link
      window.opener.postMessage(JSON.stringify({
        type: LIVENESS_REQUEST_EVENT_TYPE
      }));
    }
  }

  useEffect(() => {
    if (!livenessId) return;
    if (!auth_token){
      sendLivenessRefreshRequest();
      return;
    }

    // Fetch the liveness config
    (async () => {
      const resp = await getLivenessSession({ livenessId });
      if (resp.error) {
        setErrored(resp.error);
        switch (resp.error){
          case "ld2_session_empty_please_start_over":
          case "ld2_link_expired":
          case "ld2_session_expired":
          case "ld2_not_authorized":
            return sendLivenessRefreshRequest();
          default:
                // do nothing with other errors
        }
      } else if (resp.livenessConfig) {
        setLivenessConfig(resp.livenessConfig);
      }
    })();
  }, [livenessId, auth_token]);

  if (window.location.search.includes("key")) {
    saveKeyToCookieAndRelocateToUrl(window.location.href, { removeHistory: true, useHistoryToRelocate: true });
    return <></>;
  } 

  const errorContent = (function getErrorContent(){
    const sessionEmptyContent = <div>{L.selfie.session_expired_message}:
      <ol className="list-decimal mb-0 mt-2 list-inside">
        <li>{L.selfie.session_expired_close}</li>
        <li>{L.selfie.session_expired_restart}</li>
      </ol>
    </div>;

    if(!auth_token && !window.location.search.includes("key")){
      return sessionEmptyContent;
    }

    switch (errored) {
      case "ld2_session_empty_please_start_over":
        return sessionEmptyContent;
      case "ld2_liveness_not_defined":
        return <div>There was a Problem getting the configuration</div>
      case "ld2_link_expired":
      case "ld2_session_expired":
      case "ld2_not_authorized":
        return  <div>This session has expired. Please go back to your application and start a new session.</div>
      default:
        return null;
    }
  })();
  
  if(errorContent){
    return <div className="p-2 m-2 flex gap-2 bg-red-200 ring-2 ring-red-400 rounded-md items-start">
      <ExclamationCircleIcon className="w-8 shrink-0" />
      <div className="grow">{errorContent}</div>
    </div>
  }

  if (!ready) {
    return <div className="h-full w-full bg-gray-100 items-stretch flex-col flex">
      <div className="flex-shrink flex-1" />
      <div className="flex-grow items-center flex flex-col py-2">
        <div className="px-10 py-10 text-xl m-0 bg-white w-full mb-4 flex items-center flex-col">
          <div className="max-w-lg flex-1">
            <div className="flex flex-row mb-2">
              <div className="flex-grow text-left"></div>
              <div className="flex-1 text-right">
                <Dropdown description="Language" 
                  label={<><LanguageIcon />
                    <span className="ml-1 md:block">
                      {langToWord(context.lang as SupportedLanguage, 'Name')}
                    </span>
                  </>} 
                  options={
                    (config.languages?.split(',') || []).map((l: any) => {
                      return { label: langToWord(l as any, 'Name'), callback: () => {
                        context.setLanguage(l as any);
                      }}
                    })} />
              </div>
            </div>
            <div className="max-width-100">
              <AidKitLogo width={100} height={50} />
              <h1>{L.questions.identity.title}</h1>
              {L.questions.identity.comfort}
            </div>
          </div>
        </div>
        {livenessConfig ? <div className="flex-grow items-center flex flex-col px-10 py-10 text-xl m-0 w-full mb-4">
          <div className="max-w-lg flex-1">
            <h2 className="text-lg">{L.questions.identity.preview_instructions}</h2>
          </div>
          {[
            {
              step: 'front',
              title: L.questions.identity.take_a_photo_of_front,
              icon: <CardFront style={{width: '100%', height: 'auto'}} />,
            },
            {
              step: 'back',
              title: backContent,
              icon: livenessConfig.identification?.back.alternativeDocument?.kind === 'Debit Card' 
                ? <DebitCardFront style={{width: '100%', height: 'auto'}} />
                : <CardBack style={{width: '100%', height: 'auto'}} />,
            },
            {
              step: 'selfie',
              title: L.questions.identity.take_a_photo_of_face,
              icon: <Selfie style={{width: '100%', height: 'auto'}} />,
            },
          ].filter(f => livenessConfig.identification ? !!f : f.step === 'selfie').map((step, i) => {
            return <button key={i} className="max-w-lg relative p-2 m-4 rounded-md bg-white" onClick={() => setReady(true)} 
              style={{"border": '2px solid black'}}>
              <div className="absolute top-0 text-center left-0 w-full" style={{top: '-22px'}}>
                <div className="inline-block text-center align-center bg-white rounded-full bold" style={{
                  width: '40px',
                  height: '40px',
                  lineHeight: '40px',
                  border: '2px solid black'
                }}>
                  {i + 1}
                </div>
              </div>
              <div className="text-center align-center mx-4 mt-8 mb-2">
                {step.icon}
              </div>
              <div className="m-4 mt-0">{step.title}</div>
            </button>
          })}
          <div className="text-center mt-6 mb-20 w-100">
            <Button onClick={() => { setReady(true) }}>{L.questions.identity.begin_identity_verification}</Button>
          </div>
        </div> : <SpacedSpinner />}
      </div>
      <div className="flex-shrink" /> 
    </div>;
  }

  return <LivenessDetectionSessionPage {...props} livenessId={null} />
}

export function WhiteButton(props: {children: React.ReactNode, onClick?: (...args: any) => any}) {
  return <button 
    type="button" 
    onClick={props.onClick || (() => {})} 
    className="inline-flex bg-transparent rounded-md border-0 items-center px-4 py-2 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
    {props.children} 
  </button>
}

export function Button(props: {children: React.ReactNode, disabled?: boolean, className?: string, style?: any, onClick?: (...args: any) => any}) {
  return <button
    type="button"
    style={props.style || {}}
    disabled={props.disabled}
    onClick={props.onClick || (() => {})}
    className={(props.className || '') + " inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"} >
    {props.children}
  </button>
}

export function fitRectInRect(inner: {width: number, height: number}, outer: {width: number, height: number}) {
  let scale = Math.min(outer.width / inner.width, outer.height / inner.height);
  return {
    width: Math.ceil(inner.width * scale),
    height: Math.ceil(inner.height * scale),
    top: Math.round((outer.height - (inner.height * scale)) / 2),
    left: Math.round((outer.width - (inner.width * scale)) / 2),
  }
}

type LivenessPart = {
  imageUrl?: string,
  videoUrl?: string,
  uploading?: false,
  videoUpload?: number,
  imageUpload?: number,
}

type LivenessState = {
  front: LivenessPart,
  back: LivenessPart,
  selfie: LivenessPart
}

const facingModeForStage = {
  'front': 'environment',
  'back': 'environment',
  'selfie': 'user'
} as const;

const backZoom = 1.5;

export function LivenessDetectionSessionPage(props: any) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const { livenessId } = useParams() as any;

  const loggerHandler: ProxyHandler<string[]> = {
    get: function(target, prop, receiver) {
      if (prop === 'push') {
        return function(element: string) {
          if (['disabled'].includes(process.env.NODE_ENV)) {
            return Reflect.apply(target[prop], target, [`${new Date().toISOString()} ${element}`]);
          }
          return;
        }
      }
      return Reflect.get(target,prop,receiver);
    }
  }
  const logger = useRef(new Proxy([] as string[], loggerHandler));

  const [videoMode, setVideoMode] = useState(process.env.NODE_ENV === 'development');
  if (process.env.NODE_ENV === 'development') (window as any).setVideoMode = setVideoMode;

  /* Camera management */
  const videoRef = useRef() as React.MutableRefObject<HTMLVideoElement>;
  const [mediaStream, setMediaStream] = useState(null as MediaStream | null);
  const videoStream = useRef(null as MediaStream | null);

  const [cameraAvailable, setCameraAvailable] = useState(true);
  const [multipleCameras, setMultipleCameras] = useState(false);
  const [facingMode, setFacingMode] = useState("environment" as "user" | "environment");
  const [videoDimensions, setVideoDimensions] = useState<{
    width: number,
    height: number
    top: number,
    left: number
  } | null>(null)
  const [maskDimensions, setMaskDimensions] = useState<{
    width: number,
    height: number
    top: number,
    left: number
  } | null>(null)

  const [videoPlaying, setVideoPlaying] = useState(false);
  const resizeRef = useRef<any>(null);

  const [idType, setIDType] = useState<"wide" | "tall">("wide");
  const [shouldFail, setShouldFail] = useState<Partial<Record<keyof LivenessState, boolean>>>({})

  /* Status management */
  const getLivenessSession = usePost("/applicant/get_liveness_session", { handleErrors });
  const updateLivenessAttempt = usePost("/applicant/update_liveness_attempt");
  const getUploadURL = usePost("/document/upload_url");
  const checkRealtimeResults = usePost("/applicant/check_realtime_results");
  const upload = useAPIUpload("/upload");

  // The config from Distro
  const [livenessConfig, setLivenessConfig] = useState(null as null | v0.LivenessDetection);
  const [livenessState, setLivenessState] = useState({ front: {}, back: {}, selfie: {} } as LivenessState);
  const [actionState, setActionState] = useState<null | LivenessStage>(null);
  const [currentImage, setCurrentImage] = useState(null as null | Blob);
  const [currentVideo, setCurrentVideo] = useState(null as null | string);
  const [realtimeResults, setRealtimeResults] = useState<null | Awaited<ReturnType<typeof checkRealtimeResults>>>(null);
  const [retrying, setRetrying] = useState(false);
  const [canCloseWindow, setCanCloseWindow] = useState(false);

  const getNextActionState = (cur: null | LivenessStage) => {
    // If we're retrying we want to allow people to pop in/out of the specific
    // failed state
    if (cur?.includes('-captured') && retrying) return 'waiting';

    switch (cur) {
      case 'front':
      case 'back':
      case 'selfie':
        return cur + "-captured";

      case 'front-captured':
        return 'back';
      case 'back-captured':
        return 'selfie';
      case 'selfie-captured':
        return 'waiting';
      default:
        return cur;
    }
  }

  // On load, get information about the liveness session
  useEffect(() => {
    // Fetch the liveness config
    (async () => {
      const resp = await getLivenessSession({ livenessId });
      if (resp.livenessConfig) {
        setLivenessConfig(resp.livenessConfig);
        setActionState(resp.livenessConfig && !resp.livenessConfig.identification ? 'selfie' : 'front');
      }
    })();
  }, [])

  // Check the feedback
  useInterval(() => {
    if (actionState === 'done') return;
    (async () => {
      setRealtimeResults(await checkRealtimeResults({ livenessId })) 
    })();
  }, 3000);

  const handleResize = useCallback(() => {
    const elWidth = videoRef.current?.offsetWidth;
    const elHeight = videoRef.current?.offsetHeight;

    const width = mediaStream?.getVideoTracks()[0].getSettings().width;
    const height = mediaStream?.getVideoTracks()[0].getSettings().height;

    if (width && height && elWidth && elHeight) {
      const dimensions = fitRectInRect({ width, height }, { width: elWidth, height: elHeight }); 

      const maskDimensions = fitRectInRect(actionState == 'selfie' ? 
        { width: 690, height: 870 } :
        actionState === 'front' ? { 
          width: idType === 'tall' ? 560 : 820,
          height: idType === 'tall' ? 820 : 560
        } : { width: 820, height: 560 },
      { width: dimensions.width, height: dimensions.height });
      maskDimensions.top += dimensions.top;
      maskDimensions.left += dimensions.left;

      setVideoDimensions(dimensions);
      setMaskDimensions(maskDimensions);
      setVideoPlaying(true);
    } else {
      setVideoPlaying(false);
    }
  }, [mediaStream, videoRef.current, actionState, idType])

  useEffect(() => {
    handleResize();
  }, [idType]);

  /* Layout Management */
  useEffect(() => {
    // Initialization tends to happen in fairly arbitrary orders so we need a bunch of
    // different handlers to ensure we've catch things when they finally settle
    const localHandleResize = () => { handleResize() };

    localHandleResize();
    function handlePlay() { localHandleResize(); }
    function handlePause() { setVideoPlaying(false); }
    videoRef.current?.addEventListener('resize', localHandleResize);
    videoRef.current?.addEventListener('play', handlePlay)
    videoRef.current?.addEventListener('pause', handlePause)
    window.addEventListener('resize', localHandleResize);
    
    return () => {
      videoRef.current?.removeEventListener('resize', localHandleResize);
      videoRef.current?.removeEventListener('play', handlePlay);
      videoRef.current?.removeEventListener('pause', handlePause);
      window.removeEventListener('resize', localHandleResize);
    }
  }, [mediaStream, videoRef.current, actionState]);

  /* Camera Management */
  const updateCamera = useCallback(async (facingMode: string, actionState: string) => {
    // Clear the video stream so that we know we arent streaming immediately.
    videoStream.current = null;

    if (mediaStream && videoRef.current) {
      console.log("Swapping cameras");
      videoRef.current.pause();
      videoRef.current.srcObject = null;
      mediaStream.getTracks().forEach((track) => {
        console.log("Stopping", track.label);
        track.stop();
      });
    }

    if (actionState !== "front" && actionState !== "back" && actionState !== "selfie") {
      // kill the media stream 
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          console.log("Stopping", track.label);
          track.stop();
        });
      }
      return;
    };

    // If the screen is vertical try to ask for a vertical image
    let width = 1920;
    let height = 1440;
    if (window.innerHeight > window.innerWidth) {
      width = 1440;
      height = 1920;
    }

    const requestedMedia = {
      audio: false,
      video: { 
        facingMode,
        width: { ideal: width },
        height: { ideal: height },
        resizeMode: "none"
      },
    };

    let stream: MediaStream | undefined;
    try {
      stream = await navigator.mediaDevices.getUserMedia(requestedMedia) as MediaStream;;
      console.log("New stream created:", stream.id, facingMode, actionState);
      setMediaStream(stream);
      videoStream.current = stream;
    } catch (e) {
      console.warn("Error creating stream:", e);
      setCameraAvailable(false);
    }
    let devices;
    try {
      devices = await navigator.mediaDevices.enumerateDevices();
      let videoCount = 0;
      for (const device of devices) {
        if (device.kind === 'videoinput') {
          videoCount++;
        }
      }
      if (videoCount > 1) {
        setMultipleCameras(true);
      }
    } catch (e) {
      console.log("No Camera Available 2");
    }
  }, [mediaStream]);

  useEffect(() => {
    updateCamera(facingMode, actionState || "");
  }, [facingMode, actionState]);

  function handleCanPlay() {
    console.log("Playing", mediaStream);
    videoRef.current.play();
  }
    
  useEffect(() => {
    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
      videoRef.current.srcObject = mediaStream;
      handleCanPlay();
    }
  }, [mediaStream]);

  const swapCamera = useCallback(() => {
    //setUploaded(false);
    if (facingMode === "user") {
      setFacingMode("environment");
    } else {
      setFacingMode("user");
    }
  }, [facingMode]);

  const { recordedChunks, codec, captureReady } = useIntervalRecording(
    videoStream, 
    logger.current
  );

  const capture = async (part: 'selfie' | 'front' | 'back') => {

    const currentChunks = recordedChunks.current;

    // Upload the Image
    let uploadImage = async () => {
      let image = await getScreenshot(videoRef.current);
      if (!image) return;
      setCurrentImage(image);
      const url = await getUploadURL({
        path: part,
        length: image.size
      });
      
      let result;
      try {
        result = await upload([image], {}, url.uploadURL, "PUT", (progress) => setLivenessState((cur) => {
          return {
            ...cur,
            [part]: {
              ...cur[part],
              imageUpload: progress,
            }
          }
        }))
      } catch (e) {
        console.warn("Error uploading image: ", e);
        toast.error("Error uploading image");
      }
      return url.savedPath;
    }

    let videoPromise = async () => {
      // Get the video from the current media recorder
      //setVideoProgress("0%");
      debug("Recorded chunks:", currentChunks, currentChunks.length);

      // Get the latest recorded video from recordedChunks
      // To do this we do an algorithm to get as close to the last 5 seconds as possible
      let latest = currentChunks[currentChunks.length - 1];
      debug("Latest chunk:", latest);

      let latestVideo = recordedChunks.current.filter((ib) => {
        // console.log("Checking if ", ib.i, " === ", currentChunks[i].i, "...");
        return ib.i === latest.i;
      }).map((b) => { 
        return b.data;
      });
          
      const videoBlob = new Blob(latestVideo, { type: codec });
      const videoURL = URL.createObjectURL(videoBlob);
      setCurrentVideo(videoURL);
      logger.current.push(`latestVideoLength: ${latestVideo.length}, videoblob: ${videoBlob}, videourl: ${videoURL}`);
      const codecExt = codec.includes('mp4') 
        ? 'mp4' 
        : codec.includes('webm') ? 'webm' : 'ogg';
      const url = await getUploadURL({
        path: part + "_video." + codecExt,
        length: videoBlob.size
      });

      debug("Video path:", url.savedPath);
      if (url.uploadURL) {
        let result;
        try {
          result = await upload([videoBlob], {}, url.uploadURL, "PUT", (progress) => setLivenessState((cur) => {
            return {
              ...cur,
              [part]: {
                ...cur[part],
                videoUpload: progress,
              }
            }
          }))
        } catch (e) {
          console.warn("Error uploading video: ", e);
          toast.error("Error uploading video");
        }
        return url.savedPath;
      }
    }

    setActionState(part + "-captured" as any);

    const [imagePath, videoPath] = (await Promise.allSettled([uploadImage(), videoPromise()])).map((p) => (p as any).value);

    setLivenessState((cur) => {
      return {
        ...cur,
        [part]: {
          ...cur[part],
          imageUrl: imagePath,
          videoUrl: videoPath,
        }
      }
    })
    setRealtimeResults(cur => ({
      ...cur || {},
      [part]: "pending"
    }) as any);

    await updateLivenessAttempt({
      livenessId,
      stage: actionState || "front",
      parts: {
        [part]: {
          imageUrl: imagePath,
          videoUrl: videoPath,
          browserDetails: {
            codec,
            userAgent: navigator.userAgent,
            hardwareConcurrency: navigator.hardwareConcurrency,
            deviceMemory: (navigator as any).deviceMemory,
            language: navigator.language,
            languages: navigator.languages,
            debugOptions: {
              shouldFail: !!shouldFail[part],
              log: logger.current
            }
          }
        }
      }
    });
  }

  useEffect(() => {
    if (actionState !== 'done') return;
    (async () => { 
      await updateLivenessAttempt({
        livenessId,
        stage: actionState
      });
      setCanCloseWindow(true);
    })();
  }, [livenessId, actionState]);

  const debugFrame = false;

  const backContent = useMarkdown(
    livenessConfig?.identification?.back?.content?.[context.lang] 
      || livenessConfig?.identification?.back?.content?.en
      || L.questions.identity.take_a_photo_of_back,
    false, // collapsible
    true // no paragraphs
  );

  if (!livenessConfig) return <SpacedSpinner />

  return (<>
    {['waiting','done'].includes(actionState || '') && <FinalPage
      canCloseWindow={canCloseWindow}
      livenessConfig={livenessConfig} 
      realtimeResults={realtimeResults} 
      setRealtimeResults={setRealtimeResults}
      setActionState={setActionState} 
      setRetrying={setRetrying} 
      setFacingMode={setFacingMode}
      multipleCameras={multipleCameras}
      livenessState={livenessState} />}
    <div className={"bg-gray-50 flex flex-col fixed h-full w-screen " + (["done","waiting"].includes(actionState as any) ? 'hidden' : '')} style={{height: '-webkit-fill-available'}}>
      {/* Top Nav Bar */}
      <div className="flex flex-none">
        <div className="flex-grow text-center self-center text-xs flex justify-center items-center" style={{height: '50px'}}>
          {{
            'front': livenessConfig?.identification?.front.content || { [context.lang]: L.questions.identity.take_a_photo_of_front },
            'front-captured': { [context.lang]: L.questions.identity.front_readable },
            'back': { [context.lang]: backContent },
            'back-captured': { [context.lang]: L.questions.identity.back_readable },
            'selfie': livenessConfig?.selfie.content || { [context.lang]: L.questions.identity.take_a_photo_of_face },
            'selfie-captured': { [context.lang]: L.questions.identity.selfie_readable },
            'waiting': { [context.lang]: L.questions.identity.please_wait_processing },
            'done': { [context.lang]: 
                canCloseWindow ? L.applicant.all_done_you_may_now_close_this_window : L.questions.identity.please_wait_processing },
          }[actionState || 'front']?.[context.lang]}
        </div>
      </div>

      {/* Error States */}
      <div style={{display: cameraAvailable ? 'none' : ''}}>
        <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
          <div className="p-6 bg-red-500 rounded-lg shadow-md max-w-sm">
            <h1 className="text-white font-bold text-xl mb-4">{L.selfie.camera_error}</h1>
            <p className="text-white">
              {L.selfie.camera_disabled}
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="bg-gray-50 flex flex-grow items-stretch flex-column items-center overflow-hidden" style={{display: (!cameraAvailable || [null, "done"].includes(actionState)) ? 'none' : ''}}>
        <div className="flex h-full relative">
          {actionState?.includes('-captured') && currentImage && <div className="h-full w-full items-center">
            {!videoMode && <img className="h-full w-full" style={{objectFit: 'contain'}} src={URL.createObjectURL(currentImage)} />}
            {videoMode && currentVideo && <video controls src={currentVideo} 
              className="w-full h-full"
              style={{position: "relative", background: "#f2f2f2", zIndex: 100, objectFit: "contain" }} 
              autoPlay
              playsInline 
              muted 
              poster="" />}
          </div>}
          {!captureReady && !currentImage && <div style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 100,
            backgroundColor: 'lightgray'
          }}></div>}
          <video className={`w-full ${currentImage ? 'hidden' : ''}`}
            style={{background: "#f2f2f2", transform: `${(facingMode === 'user' || !multipleCameras) ? 'scaleX(-1)' : ''} ${actionState === 'back' ? `scale(${backZoom})` : ''}`}} 
            ref={videoRef} playsInline muted />
          <div style={{ 
            position: 'absolute', 
            display: 'flex', alignItems: 'center',
            ...videoDimensions
          }} >
            {actionState === 'front' && idType === 'wide' && <CardFront style={{margin: 'auto'}} className="block flex-1 w-full h-full" />}
            {actionState === 'back' && (
              livenessConfig?.identification?.back?.alternativeDocument?.kind === 'Debit Card' 
                ? <DebitCardFront style={{margin: 'auto'}} className="block flex-1 w-full h-full" />
                : <CardBack style={{margin: 'auto'}} className="block flex-1 w-full h-full" />)}
            {actionState === 'selfie' && <Selfie style={{margin: 'auto'}} className="block flex-1 w-full h-full" />}
          </div>
          {livenessConfig?.identification?.front?.allowTallImages ? <div className="fixed w-50 bg-white m-auto justify-content-center top-15 z-20 left-1/2 transform -translate-x-1/2 flex space-x-0 rounded">
            {/**<!-- wide/Classic ID Style --> */}
            <button className="w-16 h-16 flex items-center justify-center bg-gray-50 rounded-l-md hover:bg-gray-100" onClick={() => setIDType("wide")}>
              <svg className="w-20 h-20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="3" y="6" width="18" height="12" rx="2" ry="2"></rect>
              </svg>
            </button>
            {/**<!-- tall/Paper ID Style --> */}
            <button className="w-16 h-16 flex items-center justify-center bg-gray-50 rounded-r-md hover:bg-gray-100" onClick={() => setIDType("tall")}>
              <svg className="w-20 h-20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="1" width="16" height="22" rx="2" ry="2"></rect>
              </svg>
            </button>
          </div> : null}
          <div className={debugFrame ? "bg-red-100" : "bg-white"} style={{
            position: 'absolute',
            top: ((maskDimensions?.top || 0) + (maskDimensions?.height || 0)) - 2,
            left: 0,
            width: '100%',
            height: '100%',
          }}></div>
          <div className={debugFrame ? "bg-blue-100" : "bg-white"} style={{
            position: 'absolute',
            top: 0,
            left: ((maskDimensions?.left || 0) + (maskDimensions?.width || 0)) - 2,
            width: '100%',
            height: '100%',
          }}></div>
          <div className={debugFrame ? "bg-yellow-100" : "bg-white"} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: (maskDimensions?.top || 0) + 2,
          }}></div>
          <div className={debugFrame ? "bg-green-100" : "bg-white"} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: (maskDimensions?.left || 0) + 2,
            height: '100%',
          }}></div>
        </div>
      </div>

      {/* Loading Spinner for Camera Recording */}
      <div className="w-100 flex-none bg-white flex flex-column items-center justify-center h-10">
        {(['front','back','selfie'].includes(actionState + '') && !captureReady) ? 
          <div style={{ zIndex: 100 }} 
            className="mx-auto my-4 justify-center flex items-center px-4 py-2 text-xs font-medium text-gray-700">
            <SpacedSpinner />
            <span>{L.selfie.starting_camera}</span>
          </div>
          : null}
      </div>

      {/* Debug options */}
      {process.env.NODE_ENV === 'development' && ['front','back','selfie'].includes(actionState + '') && <div className="fixed hover top-5 left-5 z-100 bg-white">
        <div className="">
          <label htmlFor="shouldfail"><input id="shouldfail" type="checkbox" checked={shouldFail[actionState as 'front']}
            onChange={(e) => setShouldFail(cur => ({
              ...cur,
              [actionState as 'front']: e.target.checked
            }))}/> Should {actionState} fail?</label>
        </div>
      </div>}

      {/* Bottom Nav Bar */}
      <div className="flex flex-none items-center justify-center">
        {['front','back','selfie'].includes(actionState ?? '') ?
          <div className="flex justify-center flex-1 text-center p-1" style={{ height: '60px' }} >
            <div className="flex-1">{multipleCameras &&
                <WhiteButton onClick={swapCamera}>
                  <ArrowPathIcon className="text-gray-800 mr-2" width={30} height={30} />
                  {L.selfie.switch_camera}
                </WhiteButton>}</div>
            <div className="flex-1">
              {captureReady ? <Button 
                onClick={async () => { await capture(actionState as 'front' | 'back' | 'selfie'); }}
                className={`ml-2`}>
                <CameraIcon className="mr-2" width={30} height={30} />
                <span>{L.selfie.take_photo}</span>
              </Button> : null}
            </div>
          </div>
          : null}
        {['front-captured','back-captured','selfie-captured'].includes(actionState ?? '') ? 
          <div className="flex-1 max-w-md items-center justify-center flex text-center p-1" style={{height: '60px'}}>
            <Button className="flex-1 text-center" onClick={() => {
              setActionState((cur) => {
                let next = cur!.replace("-captured", "") as "front" | "selfie" | "back";
                if (multipleCameras && next in facingModeForStage) {
                  setFacingMode((facingModeForStage as any)[next])
                }
                return next;
              });
              setCurrentImage(null);
            }}>
              <ArrowPathIcon className="mr-2" width={30} height={30} />
              {L.apply.previous}</Button>
            <Button className="flex-1 text-center" onClick={async () => {
              setCurrentImage(null);
              setActionState((cur) => { 
                let next = getNextActionState(cur) as any;
                if (multipleCameras && next in facingModeForStage) { 
                  setFacingMode((facingModeForStage as any)[next])
                }
                return next;
              });
            }}>
              <CheckCircleIcon className="mr-2" width={30} height={30} />
              {L.apply.next}
            </Button>
          </div>
          : null}
      </div>
    </div>
  </>
  );
}
type iBlob = {
  i: number,
  timestamp: number,
  data: Blob
}

const stopRecorder = (recording: MediaRecorder) => {
  if (recording.state === 'recording') {
    recording.stop();
  }
}

// Custom hook to manage interval recording
const useIntervalRecording = (stream: React.MutableRefObject<MediaStream | null>, log?: string[]) => {

  const recordedChunks = useRef([] as iBlob[]);
  const mediaRecorders = useRef([] as MediaRecorder[]);
  const [captureReady, setCaptureReady] = useState(false);
  const chunks = useRef([] as iBlob[]);
  const i = useRef(0);

  const chosenCodec = useRef('');

  const codecs = [
    'video/webm; codecs="vp8"',
    'video/webm; codecs="vp9"',
    'video/webm; codecs="av1"',
    'video/mp4; codecs="avc1.42E01E"', // Baseline profile for H.264
    'video/mp4; codecs="avc1.64001F"', // Main profile for H.264
    'video/mp4; codecs="avc1.640028"', // High profile for H.264
    'video/mp4; codecs="hev1.1.6.L93.B0"', // HEVC (H.265)
    'video/mp4; codecs="hvc1.1.6.L93.B0"', // HEVC (H.265)
    'video/ogg; codecs="theora"'
  ];

  const startNewRecording = useCallback(() => {
    if (!stream.current) {
      log?.push("Waiting for stream");
      return; 
    }

    log?.push(`Creating Recorder ${i.current}`);

    let mediaRecorder = (() => {
      let recorder: MediaRecorder | null;
      for (let codec of codecs) {
        try {
          recorder = new MediaRecorder(stream.current, {
            mimeType: codec, 
            videoBitsPerSecond: 2500000
          });
          chosenCodec.current = codec;
          break;
        } catch (e) {
          console.log("Codec doesnt work with device: ", e, codec, navigator?.userAgent);
        }
      }
      if (!recorder!) throw new Error("Device not supported");
      return recorder;
    })()
    let current = i.current; 
  
    mediaRecorder.ondataavailable = (event) => {
      let { data } = event;
      if (data.size > 0) {
        chunks.current.push({ 
          i: current, 
          timestamp: Date.now(),
          data,
        });
        if (chunks.current.length > 2 * MAX_CHUNKS * MAX_CHUNKS) {
          chunks.current.shift();
        }
        
        recordedChunks.current = chunks.current;
        log?.push(`Pushed chunks: ${chunks.current.length} for recorder ${current}`);
      } else {
        log?.push(`No data available: ${chunks.current.length} for recorder ${current}`);
        console.warn("No data available", event);
      }

      if (recordedChunks.current.length >= MAX_CHUNKS) {
        log?.push("Setting capture ready = true")
        setCaptureReady(true);
      } else {
        log?.push("Setting capture ready = false")
        setCaptureReady(false);
      }

    };

    try {
      if (stream.current) mediaRecorder.start(CAPTURE_INTERVAL);
    } catch (e) {
      console.warn("Error starting recorder:", e);
    }

    setTimeout(() => {
      stopRecorder(mediaRecorder);
    }, MAX_CHUNKS * CAPTURE_INTERVAL);

    i.current++;
    mediaRecorders.current.push(mediaRecorder);

    if (mediaRecorders.current.length > MAX_CHUNKS) {
      const oldMediaRecorder = mediaRecorders.current.shift();
      if (oldMediaRecorder) stopRecorder(oldMediaRecorder);
    }

  }, [stream.current]);

  useEffect(() => {
    startNewRecording();
    const intervalId = setInterval(startNewRecording, CAPTURE_INTERVAL * MAX_CHUNKS);
    return () => {
      clearInterval(intervalId);
      mediaRecorders.current.forEach((mediaRecorder) => {
        stopRecorder(mediaRecorder);
      });
      recordedChunks.current = [];
      chunks.current = [];
    };
  }, [startNewRecording, stream.current]);

  return { recordedChunks, codec: chosenCodec.current, captureReady };
};

type RealtimeResults = Record<"front" | "back" | "selfie", "passed" | "failed" | "pending" | "max_attempts_reached" | undefined> | null

function FinalPage(props: {
  realtimeResults: RealtimeResults, 
  livenessState: LivenessState,
  multipleCameras: boolean, 
  canCloseWindow: boolean,
  livenessConfig: v0.LivenessDetection,
  setActionState: React.Dispatch<React.SetStateAction<LivenessStage | null>>,
  setRetrying: React.Dispatch<React.SetStateAction<boolean>>,
  setRealtimeResults: React.Dispatch<React.SetStateAction<RealtimeResults>>,
  setFacingMode: React.Dispatch<React.SetStateAction<"user" | "environment">>
}) {

  const L = useLocalizedStrings();
  const parts = props.livenessConfig.identification ?
    ['front', 'back', 'selfie'] as const :
    ['selfie'] as const;

  // Figure out the global state to display
  let state = L.questions.identity.please_wait_processing;
  let completeCount = 0;
  let failedCount = 0;
  parts.forEach((part) => {
    if ((props.livenessState as any)[part].videoUpload !== 1 || (props.livenessState as any)[part].imageUpload !== 1) return;
    if ((props.realtimeResults as any)?.[part] === 'passed' || (props.realtimeResults as any)?.[part] === 'max_attempts_reached') completeCount += 1;
    if ((props.realtimeResults as any)?.[part] === 'failed') failedCount += 1;
  });
  if (completeCount === parts.length) {
    state = props.canCloseWindow ? L.applicant.all_done_you_may_now_close_this_window : L.questions.identity.please_wait_processing;
    props.setActionState("done");
  }
  if (failedCount > 0) {
    state = L.questions.identity.blurry_sorry;
  }

  return <div className="bg-gray-100 flex flex-col fixed h-screen w-screen overflow-y-scroll" style={{ height: '-webkit-fill-available' }}>
    <div className="flex items-center flex flex-col py-2">
      <div className="px-10 py-10 text-xl m-0 bg-white w-full flex items-center flex-col">
        <div className="max-w-lg flex-1">
          <AidKitLogo width={100} height={50} />
          <h3>{state}</h3>
          <div>{state === L.questions.identity.please_wait_processing ? 
            <BarSpinner /> : null}</div>
        </div>
      </div>
    </div>
    {window.opener && state === L.applicant.all_done_you_may_now_close_this_window && <div className="flex flex-none items-center justify-center">
      <button type="button" 
        onClick={(e) => {
          e.preventDefault();
          window.close(); 
        }} className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >{L.apply.close}</button>
    </div>}
    <div className="flex flex-grow bg-gray-100 text-gray-800 justify-center items-center">
      <div className="mx-auto max-w-lg px-4 py-4 sm:px-6 md:py-16">
        <div>
          <ul role="list" className="divide-y divide-gray-200">
            {parts.map((part) => (
              <li key={"progress-" + part} className="flex items-center justify-between gap-x-2 p-2 bg-white rounded-md m-3" style={{ "border": '2px solid black' }}>
                <div className="flex min-w-0 gap-x-4 text-center" style={{ width: '90px' }}>
                  <div className="text-center" style={{ 'width': '90px' }}>
                    {{
                      'front': <CardFront style={{ height: 50, width: 'auto' }} />,
                      'back': props.livenessConfig.identification?.back.alternativeDocument?.kind === 'Debit Card' 
                        ? <DebitCardFront style={{ height: 50, width: 'auto' }} /> 
                        : <CardBack style={{ height: 50, width: 'auto' }} />,
                      'selfie': <Selfie style={{ height: 50, width: 'auto', display: 'inline-block' }} />,
                    }[part]}
                  </div>
                </div>
                <div className="min-w-0 flex-auto mr-2 flex justify-center items-center text-gray-900">
                  {(() => {
                    let partState = props.realtimeResults?.[part] as any;
                    if (props.livenessState[part].videoUpload !== 1 || props.livenessState[part].imageUpload !== 1) {
                      partState = 'uploading';
                    }
                    switch (partState) {
                      case 'failed':
                        return L.questions.identity.photo_too_blurry;
                      case 'passed':
                      case 'max_attempts_reached':
                        return L.questions.identity.complete;
                      case 'uploading':
                        return L.questions.attachment.uploading + " " + Math.round(Math.min(props.livenessState[part].imageUpload || 0, props.livenessState[part].videoUpload || 0)*100) + '%';
                      case 'pending':
                      default:
                        return L.questions.identity.checking_quality;
                    }
                  })()}
                </div>
                <div className="text-center mr-2" style={{ width: "70px" }}>
                  {(() => {
                    let partState = props.realtimeResults?.[part];
                    if (props.livenessState[part].videoUpload !== 1 || props.livenessState[part].imageUpload !== 1) {
                      partState = 'pending';
                    }
                    switch (partState) {
                      case 'failed':
                        return <button className="border-0 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            props.setRetrying(true);
                            props.setActionState(part);
                            if (props.multipleCameras) props.setFacingMode(facingModeForStage[part])
                            props.setRealtimeResults((cur) => ({
                              ...cur, 
                              [part]: "pending"
                            } as RealtimeResults));
                            partState = 'pending';
                          }}>{L.questions.identity.retry}</button>;
                      case 'passed':
                      case 'max_attempts_reached': // ¯\_(ツ)_/¯
                        return <span><CheckCircleIcon className="text-green-500" width={30} /></span>;
                      case 'pending':
                      default:
                        return <span><SpacedSpinner /></span>;
                    }
                  })()}
                </div>
              </li>
            ))}
          </ul></div>
      </div>
    </div>
  </div>;
}



























/*
 * Inline SVGs (not useful)
 */

function CardFront(props: any) {
  return <svg width="820" height="560" {...props} viewBox="0 0 820 560" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M864 -174H-44V734H864V-174ZM85 52C62.9086 52 45 69.9086 45 92V468C45 490.091 62.9086 508 85 508H735C757.091 508 775 490.091 775 468V92C775 69.9086 757.091 52 735 52H85Z" fill="white"/>
    <rect x="47" y="54" width="726" height="452" rx="38" stroke="black" strokeWidth="4"/>
    <rect x="342" y="179" width="195" height="19" fill="#D9D9D9"/>
    <rect x="342" y="253" width="179" height="15" fill="#D9D9D9"/>
    <rect x="342" y="285" width="179" height="15" fill="#D9D9D9"/>
    <rect x="342" y="410" width="179" height="15" fill="#D9D9D9"/>
    <rect x="342" y="444" width="99" height="15" fill="#D9D9D9"/>
    <rect x="546" y="444" width="99" height="15" fill="#D9D9D9"/>
    <rect x="342" y="218" width="160" height="15" fill="#D9D9D9"/>
    <rect x="82" y="98" width="646" height="42" fill="#D9D9D9"/>
    <circle cx="197.5" cy="280.5" r="61.5" fill="#D9D9D9"/>
    <mask id="mask0_0_1" style={{ 'maskType': 'alpha' }} maskUnits="userSpaceOnUse" x="82" y="179" width="230" height="280">
      <rect x="82" y="179" width="230" height="280" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_0_1)">
      <circle cx="199" cy="502" r="117" fill="#D9D9D9"/>
    </g>
    <rect x="84" y="181" width="226" height="276" stroke="#D9D9D9" strokeWidth="4"/>
  </svg>;
}

function DebitCardFront(props: any) {
  return <svg xmlns="http://www.w3.org/2000/svg" {...props} width="820" height="560" fill="none" viewBox="0 0 820 560" >
    <path fillRule="evenodd" clipRule="evenodd" d="M864 -174H-44V734H864V-174ZM85 52C62.9086 52 45 69.9086 45 92V468C45 490.091 62.9086 508 85 508H735C757.091 508 775 490.091 775 468V92C775 69.9086 757.091 52 735 52H85Z" fill="white"/>
    <rect x="47" y="54" width="726" height="452" rx="38" stroke="black" strokeWidth="4" />
    <g fill="#C0C0C0" fill-opacity="0.5">
      <rect x="100" y="153" height="99.679" width="110.32" rx="20.323" fill-opacity="0.5" />
      <rect x="120" y="153" width="2" height="100" fill="#fff" />
      <rect x="140" y="153" width="2" height="100" fill="#fff" />
      <rect x="100" y="180" width="111" height="2" fill="#fff" />
      <rect x="100" y="200" width="111" height="2" fill="#fff" />
      <text y="350" font-size="56" style={{ fontFamily: 'Courier-New', display: 'block' }}>
        <tspan x="150">1234 1234 1234 1234</tspan>
      </text>
      <text y="420" font-size="24" style={{ fontFamily: 'Courier-New', display: 'block' }}>
        <tspan x="550">MM/YY</tspan>
      </text>
    </g></svg>
}

function CardBack(props: any) {
  return <svg width="820" height="560" {...props} viewBox="0 0 820 560" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M864 -174H-44V734H864V-174ZM85 52C62.9086 52 45 69.9086 45 92V468C45 490.091 62.9086 508 85 508H735C757.091 508 775 490.091 775 468V92C775 69.9086 757.091 52 735 52H85Z" fill="white"/>
    <rect x="47" y="54" width="726" height="452" rx="38" stroke="black" strokeWidth="4"/>
    <rect x="50" y="107" width="721" height="91" fill="#D9D9D9"/>
    <rect x="233" y="359" width="17" height="94" fill="#D9D9D9"/>
    <rect x="254" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="262" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="270" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="282" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="290" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="298" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="302" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="310" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="318" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="282" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="290" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="298" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="322" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="330" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="338" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="342" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="350" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="358" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="322" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="330" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="338" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="362" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="370" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="378" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="382" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="390" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="398" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="362" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="370" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="378" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="402" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="410" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="418" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="422" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="430" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="438" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="402" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="410" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="418" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="442" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="450" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="458" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="462" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="470" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="478" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="442" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="450" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="458" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="482" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="490" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="498" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="502" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="510" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="518" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="482" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="490" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="498" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="522" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="530" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="538" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="542" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="550" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="558" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="522" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="530" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="538" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="562" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="570" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="578" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="562" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="570" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="578" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="590" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="598" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="606" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="614" y="359" width="17" height="94" fill="#D9D9D9"/>
  </svg>;
}

function Selfie(props: any) {
  return <svg width="690" height="870" {...props} viewBox="0 0 690 870" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M799 -19H-109V889H799V-19ZM344.5 810C510.462 810 645 642.107 645 435C645 227.893 510.462 60 344.5 60C178.538 60 44 227.893 44 435C44 642.107 178.538 810 344.5 810Z" fill="white"/>
    <path d="M644.95 434.5C644.95 643.384 509.675 810.55 345.2 810.55C180.725 810.55 45.45 643.384 45.45 434.5C45.45 225.616 180.725 58.45 345.2 58.45C509.675 58.45 644.95 225.616 644.95 434.5Z" stroke="black" strokeWidth="10.9"/>
    <circle cx="241.65" cy="380" r="29.975" fill="#D9D9D9"/>
    <path d="M293.425 380C293.425 361.94 270.855 347.3 243.012 347.3C215.17 347.3 192.6 361.94 192.6 380" stroke="#D9D9D9" strokeWidth="10.9"/>
    <path d="M503.25 380C503.25 361.94 480.68 347.3 452.838 347.3C424.995 347.3 402.425 361.94 402.425 380" stroke="#D9D9D9" strokeWidth="10.9"/>
    <circle cx="454.2" cy="382.725" r="29.975" fill="#D9D9D9"/>
    <path d="M296.15 543.5C296.15 558.55 318.72 570.75 346.562 570.75C374.405 570.75 396.975 558.55 396.975 543.5" stroke="#D9D9D9" strokeWidth="10.9"/>
    <path d="M464.197 641.6C463.02 645.155 460.665 648.784 456.978 652.425C451.363 657.968 442.959 663.212 432.135 667.731C410.511 676.761 380.26 682.475 346.563 682.475C312.865 682.475 282.614 676.761 260.99 667.731C250.167 663.212 241.763 657.968 236.148 652.425C232.46 648.784 230.105 645.155 228.928 641.6L464.197 641.6Z" stroke="#D9D9D9" strokeWidth="10.9"/>
    <line x1="176.25" y1="311.875" x2="296.15" y2="311.875" stroke="#D9D9D9" strokeWidth="10.9"/>
    <line x1="396.975" y1="311.875" x2="516.875" y2="311.875" stroke="#D9D9D9" strokeWidth="10.9"/>
  </svg>;
}