import { v4 } from 'uuid';
import * as v0 from '../survey'
import { CompileExpressionToSQL } from './expr_to_sql';
import { CompileExpressionToJS } from './expr_to_js';
import { AirtableSurveyQuestion } from '../legacy/airtable';
import { PermissionScope } from './permissions';
import { filterDict } from '../util';
import { CompileExpressionToEnglish } from './expr_to_english';
import w9 from '../documents/w9';

export type Add = {
  Question: (q: AirtableSurveyQuestion) => void,
  Translation: (text: v0.Content) => string,
  Choice: (choice: v0.Select['choices'][number]) => string,
  Role: (name: string, properties: string[]) => string,
  Section: (section: v0.Section & v0.CommonProperties, depth: number) => string,
  Configuration: (params: {
    kind: 'update' | 'set',
    key: string,
    update?: (old: string) => string,
    value?: string
  }) => void,
  Depth: number
}

type DataDictionaryRow = {
  kind: string,
  field: string,
  question: string,
  choiceLabels: string[],
  choiceValues: string[],
  extraFields?: string[],
  formula?: string,
}

type Choice = {
  value: string,
  label: v0.Text,
}

export function getDataDictionary(node: any, permissionScope: PermissionScope, excludeFields?: v0.TargetFieldRef[], includeComputations?: v0.DataDictionary['includeComputations']): { rows: DataDictionaryRow[], columns: (keyof DataDictionaryRow)[] } {

  const columns: (keyof DataDictionaryRow)[] = [
    'kind',
    'field',
    'question',
    'choiceLabels',
    'choiceValues',
    'extraFields'
  ];

  if (!!includeComputations) {
    columns.push('formula');
  }

  function hasFieldPermission(targetField: string) {
    return permissionScope.fieldScope.includes(targetField) && (!excludeFields || !excludeFields.includes(targetField));
  }

  // Keep track of fields we've already seen before, so we can avoid adding them multiple times in the dictionary.
  const seenFields = new Set<string>();
  const excludedTypes = [
    'Show Field',
    'Payment',
  ];

  function traverseTree(node: any): DataDictionaryRow[] {
    if (typeof node.targetField === 'string' && node.kind && !excludedTypes.includes(node.kind)) {
      if (hasFieldPermission(node.targetField) && !seenFields.has(node.targetField)) {
        seenFields.add(node.targetField);
        const choices = ['Select', 'Ranking'].includes(node.kind) ? (node.choices as Choice[]) : [];
        let formula = '';

        if (!!includeComputations) {
          if (node.kind === 'Computed') {
            const computeNode = node as v0.Computed; // for typing
            if (includeComputations === 'As is') {
              formula = typeof computeNode.formula === 'string' ? computeNode.formula : JSON.stringify(computeNode.formula);
            } else if (includeComputations === 'Compiled JS') {
              formula = typeof computeNode.formula === 'string' ? computeNode.formula : CompileExpressionToJS(computeNode.formula);
            } else if (includeComputations === 'Pretty') {
              formula = typeof computeNode.formula === 'string' ? computeNode.formula : CompileExpressionToEnglish(computeNode.formula);
            }
          } else if (node.kind === 'Validated') {
            formula = (node as v0.Validated).formula;
          }
        }

        return [{
          kind: node.kind,
          field: node.targetField,
          question: typeof node?.content?.en === 'string'
            ? node.content.en
            : typeof node?.label?.en === 'string'
              ? node.label.en
              : node.kind,
          choiceLabels: choices.map(choice => choice.label.en),
          choiceValues: choices.map(choice => choice.value),
          extraFields: node.kind in TRANSLATORS
            ? TRANSLATORS[node.kind as keyof typeof TRANSLATORS].getSlugs(node).slugs.map((slug) => slug.startsWith('_') ? node.targetField + slug : slug)
            : [],
          ...(!!includeComputations ? { formula } : {})
        }];
      }
      return [];
    }

    if (typeof node === 'object') {
      // Special case Likerts because they are questions which contain subquestions
      if (node.kind === 'Likert') {
        return (Array.isArray((node as v0.Likert).questions) ? (node as v0.Likert & { questions: any[] }).questions : [])
          .filter(subQuestion => hasFieldPermission(subQuestion.targetField) && !seenFields.has(subQuestion.targetField))
          .flatMap((subQuestion: any) => {
            seenFields.add(subQuestion.targetField);
            return {
              kind: 'Likert Subquestion',
              field: subQuestion.targetField,
              question: subQuestion.label.en,
              choiceLabels: (node as v0.Likert).choices.map(choice => choice.label.en),
              choiceValues: (node as v0.Likert).choices.map(choice => choice.value)
            };
          });
      }
      // Special case NumberWithUnits because they are unlike most other question types
      if (node.kind === 'NumberWithUnit') {
        if (hasFieldPermission(node.numberQuestion.targetField) && !seenFields.has(node.numberQuestion.targetField)) {
          seenFields.add(node.numberQuestion.targetField);
          return [{
            kind: 'NumberWithUnit',
            field: node.numberQuestion.targetField,
            question: node.content.en,
            // These are technically the choices for the subquestion 'unitQuestion'
            choiceLabels: (node as v0.NumberWithUnit).unitQuestion.choices.map(choice => choice.label.en),
            choiceValues: (node as v0.NumberWithUnit).unitQuestion.choices.map(choice => choice.value),
            extraFields: [node.unitQuestion.targetField]
          }];
        }
      }

      if (Array.isArray(node)) {
        return node.flatMap((subNode) => traverseTree(subNode));
      }

      return Object.values(node).flatMap((subNode) => traverseTree(subNode));
    }
    return [];
  }

  return { rows: traverseTree(node), columns };
}

/**
 * This function traverses an object and creates a copy minus all properties with shape { en: string, ... }
 *
 * This is useful because the 'content' properties of question objects represent the vast percentage of
 * the size of the objects, but often does not surve functional purposes in terms of the system.
 */
function removeContent(obj: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {};
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (value && typeof value === 'object') {
      if ('en' in value && typeof value.en === 'string') {
        continue; // i.e. remove this from the final object
      }

      if (Array.isArray(value)) {
        result[key] = value.map(item => typeof item === 'object' ? removeContent(item) : item);
      } else {
        result[key] = removeContent(value);
      }
    } else {
      result[key] = value;
    }
  }
  return result;
}

export const TRANSLATORS = {
  'Action': {
    addFunc: (component: v0.Action, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Action',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify({
            action: component.action,
            confirmation: component.confirmation as v0.Text,
            toast: component.toast as v0.Text,
          }),
        }
      })
    },
    getSlugs: (component: v0.Action) => {
      return { slugs: (component.fields || []) as v0.StandaloneSlug[] };
    },
    getRequirements: (component: v0.Action) => []
  },
  'Address': {
    addFunc: (component: v0.Address, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Address',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': ['Formatted', 'Separate Fields'],
          Metadata: JSON.stringify({ lookups: component.lookups }),
        }
      })
    },
    getSlugs: (component: v0.Address) => {
      const slugs: Array<v0.StandaloneSlug | v0.SuffixSlug> = [
        '_county',
        '_tract',
        '_lat',
        '_lng',
        '_building',
        '_subpremise',
        '_street',
        '_city',
        '_state',
        '_zip',
        '_dpb',
        '_formatted'
      ] as v0.SuffixSlug[];

      for (const lookup of (component.lookups ?? [])) {
        slugs.push('_' + lookup as v0.SuffixSlug);
      }
      return { slugs };
    },
    getRequirements: (component: v0.Address) => [{ kind: 'Exists', field: component.targetField }]
  },
  'ApplicantCreator': {
    addFunc: (component: v0.ApplicantCreator, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': component.kind,
          'Target Field': component.targetField,
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          Translation: [add.Translation(component.buttonText as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify(component),
        }
      })
    },
    getSlugs: (component: v0.ApplicantCreator) => {
      const slugs: Array<v0.StandaloneSlug | v0.SuffixSlug> = [] as v0.StandaloneSlug[];
      for (const otherField of component.newApplicantInitialInfo.otherFields || []) {
        slugs.push(otherField.destinationField as v0.StandaloneSlug);
      }
      slugs.push(component.newApplicantInitialInfo.existingApplicantReferencePrefix + '_id' as v0.StandaloneSlug);
      slugs.push(component.newApplicantInitialInfo.existingApplicantReferencePrefix + '_name' as v0.StandaloneSlug);

      return { slugs };
    },
    getRequirements: (component: v0.ApplicantCreator) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Assignee': {
    addFunc: (component: v0.Assignee, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Assignee',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
        }
      })
    },
    getSlugs: (component: v0.Assignee) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.Assignee) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Attachment': {
    addFunc: (component: v0.Attachment, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Attachment',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': [
            'Formatted',
            ...(component.detectFaces ? ['Detect Faces'] : []),
            ...(component.extractText ? ['Extract Text'] : []),
            ...(component.directUploadOnly ? ['Direct Upload Only'] : [])
          ],
          Metadata: JSON.stringify({
            limitOne: component.limitOne,
            realtimeVerifications: component.realtimeVerifications,
            allowSendingLinkToUpload: component.allowSendingLinkToUpload,
            enableCamera: component.enableCamera,
          }),
        }
      })
    },
    getSlugs: (component: v0.Attachment) => {
      const slugs = [] as v0.SuffixSlug[];
      if (component.realtimeVerifications?.length) {
        slugs.push(...['_video', '_metadata', '_queue_id'] as v0.SuffixSlug[]);
      }
      if (component.detectFaces) {
        slugs.push(...[
          '_face',
          '_duplicates',
          '_related',
          '_unindexed_reason',
          '_conversion'] as v0.SuffixSlug[]);
      }
      if (component.extractText) {
        slugs.push('_text' as v0.SuffixSlug);
        slugs.push('_text_error' as v0.SuffixSlug);
      }
      return { slugs };
    },
    getRequirements: (component: v0.Attachment) => [{ kind: 'Exists', field: component.targetField }]
  },
  'BankRoutingNumber': {
    addFunc: (component: v0.BankRoutingNumber, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Bank Routing Number',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': ['Formatted'],
        }
      })
    },
    getSlugs: (component: v0.BankRoutingNumber) => {
      return { slugs: ['_bankname' as v0.SuffixSlug] };
    },
    getRequirements: (component: v0.BankRoutingNumber) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Benefits Calculator': {
    addFunc: (component: v0.BenefitsCalculator, add: Add) => {
      add.Question({
        id: v4(),
        fields: {
          'Field Type': 'Benefits Calculator',
          'Target Field': component.targetField,
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          modern: true,
          definition: component,
          Metadata: JSON.stringify({
            flattenIntoFieldsWithPrefix: component.flattenIntoFieldsWithPrefix,
          }),
        }
      } as any);
    },
    getSlugs: (component: v0.BenefitsCalculator) => {
      let slugs: Array<v0.StandaloneSlug | v0.SuffixSlug> = [];
      if (component.flattenIntoFieldsWithPrefix) {
        slugs = slugs.concat([
          '_pre_foodstamps_Basic_Food_Program_eligible',
          '_pre_foodstamps_Basic_Food_Program_amount',
          '_pre_foodstamps_CalFresh_eligible',
          '_pre_foodstamps_CalFresh_amount',
          '_pre_wic_WIC_eligible',
          '_pre_wic_WIC_amount',
          '_pre_welfare_WorkFirst_eligible',
          '_pre_welfare_WorkFirst_amount',
          '_pre_welfare_CalWorks_eligible',
          '_pre_welfare_CalWorks_amount',
          '_pre_welfare_General_Relief_eligible',
          '_pre_welfare_General_Relief_amount',
          '_pre_healthcare',
          '_pre_childcare_Child_Care_Assistance_eligible',
          '_pre_childcare_Child_Care_Assistance_amount',
          '_pre_utilities_HEAP_eligible',
          '_pre_utilities_HEAP_amount',
          '_pre_utilities_Lifeline_eligible',
          '_pre_utilities_Lifeline_amount',
          '_pre_utilities_FERA-CARE_eligible',
          '_pre_utilities_FERA-CARE_amount',
          '_pre_utilities_CARE_eligible',
          '_pre_utilities_CARE_amount',
          '_pre_taxes',
          '_pre_eitc_eitc_eligible',
          '_pre_eitc_eitc_amount',
          '_pre_cctc_Child_Care_Tax_Credit_eligible',
          '_pre_cctc_Child_Care_Tax_Credit_amount',
          '_pre_ctc_Child_Tax_Credit_eligible',
          '_pre_ctc_Child_Tax_Credit_amount',
          '_pre_hsi_hsi_eligible',
          '_pre_hsi_hsi_amount',
          '_pre_hsi_Not_Eligible_eligible',
          '_pre_hsi_Not_Eligible_amount',
          '_pre_hsi__eligible',
          '_pre_hsi__amount',
          '_pre_hsi_Affordable_Connectivity_Program_eligible',
          '_pre_hsi_Affordable_Connectivity_Program_amount',
          '_pre_sdoh',
          '_pre_unemployment_Unemployment_Insurance_eligible',
          '_pre_unemployment_Unemployment_Insurance_amount',
          '_pre_ssissd_ssissd_eligible',
          '_pre_ssissd_ssissd_amount',
          '_pre_section8',
          '_post_foodstamps_Basic_Food_Program_eligible',
          '_post_foodstamps_Basic_Food_Program_amount',
          '_post_foodstamps_CalFresh_Program_eligible',
          '_post_foodstamps_CalFresh_Program_amount',
          '_post_wic_WIC_eligible',
          '_post_wic_WIC_amount',
          '_post_welfare_WorkFirst_eligible',
          '_post_welfare_WorkFirst_amount',
          '_post_welfare_CalWorks_eligible',
          '_post_welfare_CalWorks_amount',
          '_post_welfare_General_Relief_eligible',
          '_post_welfare_General_Relief_amount',
          '_post_healthcare',
          '_post_childcare_Child_Care_Assistance_eligible',
          '_post_childcare_Child_Care_Assistance_amount',
          '_post_utilities_HEAP_eligible',
          '_post_utilities_HEAP_amount',
          '_post_utilities_Lifeline_eligible',
          '_post_utilities_Lifeline_amount',
          '_post_utilities_FERA-CARE_eligible',
          '_post_utilities_FERA-CARE_amount',
          '_post_utilities_CARE_eligible',
          '_post_utilities_CARE_amount',
          '_post_taxes',
          '_post_eitc_eitc_eligible',
          '_post_eitc_eitc_amount',
          '_post_cctc_Child_Care_Tax_Credit_eligible',
          '_post_cctc_Child_Care_Tax_Credit_amount',
          '_post_ctc_Child_Tax_Credit_eligible',
          '_post_ctc_Child_Tax_Credit_amount',
          '_post_hsi_hsi_eligible',
          '_post_hsi_hsi_amount',
          '_post_hsi_Not_Eligible_eligible',
          '_post_hsi_Not_Eligible_amount',
          '_post_hsi__eligible',
          '_post_hsi__amount',
          '_post_hsi_Affordable_Connectivity_Program_eligible',
          '_post_hsi_Affordable_Connectivity_Program_amount',
          '_post_sdoh',
          '_post_unemployment_Unemployment_Insurance_eligible',
          '_post_unemployment_Unemployment_Insurance_amount',
          '_post_ssissd_ssissd_eligible',
          '_post_ssissd_ssissd_amount',
          '_post_section8',
        ].map((fieldSuffix) => (component.flattenIntoFieldsWithPrefix + fieldSuffix) as v0.StandaloneSlug));
      }

      return { slugs }
    },
    getRequirements: (component: v0.BenefitsCalculator) => {
      if (component.targetField) {
        return [{ kind: 'Exists', field: component.targetField }];
      }
      return [];
    }
  },
  'Card Management': {
    addFunc: (component: v0.CardManagement, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Card Management',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField || 'card_id',
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': [
            ...(component.viewOnly ? ['View Only'] : [])
          ],
          Metadata: JSON.stringify(component),
        }
      })
      const extraFields = ['legal_name', 'birth_date', 'home_address', 'email', 'phone'];
      for (const field of extraFields) {
        add.Question({
          id: v4(),
          createdTime: '',
          fields: {
            Question: id,
            'Field Type': 'Single Line Text Entry',
            'Who Can Edit': [add.Role('Screener', ['Screener'])],
            'Target Field': field,
            'English Content': '--',
            'Spanish Content': '--',
            'Additional Options': ['Hidden']
          }
        })
      }
    },
    getSlugs: (component: v0.CardManagement) => {
      const slugs: Array<v0.StandaloneSlug | v0.SuffixSlug> = [];
      const suffixes = ['_id', '_serial', '_last4', '_id_setup_link'];
      if (component.targetField) {
        slugs.push(...suffixes as v0.SuffixSlug[])
      } else {
        for (const suffix of suffixes) {
          slugs.push('card' + suffix as v0.StandaloneSlug);
        }
      }
      return { slugs };
    },
    getRequirements: (component: v0.CardManagement) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Collection': {
    addFunc: (component: v0.Collection, add: Add) => {
      for (const c of component.components) {
        if (c.kind === 'Section') {
          add.Section(c, add.Depth + 1);
          continue;
        }
        const t = TRANSLATORS[c.kind as keyof typeof TRANSLATORS];
        if (t) t.addFunc(c as any, add);
      }
    },
    getSlugs: (component: v0.Collection) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.Collection) => []
  },
  'Computed': {
    addFunc: (component: v0.Computed, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Computed',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': ['Formatted'],
          Metadata: JSON.stringify({
            formula: typeof component.formula === 'string' ? component.formula : CompileExpressionToJS(component.formula as any),
            format: component.format,
          }),
        }
      })
    },
    getSlugs: (component: v0.Computed) => {
      return {
        slugs: [ '_error' as v0.SuffixSlug ]
      };
    },
    getRequirements: (component: v0.Computed) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Confirmation': {
    addFunc: (component: v0.Confirmation, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Confirmation',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': ['Formatted'],
        }
      })
    },
    getSlugs: (component: v0.Confirmation) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.Confirmation) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Consent': {
    addFunc: (component: v0.Consent, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Consent',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': '_consent',
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify(component),
        }
      })
    },
    getSlugs: (component: v0.Consent) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.Consent) => []
  },
  'ContactConfirmation': {
    addFunc: (component: v0.ContactConfirmation, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Contact Confirmation',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          // Don't Resume by default
          'Additional Options': ['Formatted', ...[component.resume ? '' : 'Don\'t Resume']],
          Metadata: JSON.stringify({
            contact_field: component.contactField,
            ...filterDict(component, (k, v) => k !== 'content' && k !== 'targetField' && k !== 'resume')
          }),
        }
      })
    },
    getSlugs: (component: v0.ContactConfirmation) => {
      // Trailing underscore used to avoid a collision with field names
      return { slugs: [(component.contactField || 'phone_number') + '_confirmed_info_' as v0.StandaloneSlug] };
    },
    getRequirements: (component: v0.ContactConfirmation) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Date': {
    addFunc: (component: v0.Date, add: Add) => {
      const id = v4();

      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Date',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': ['Formatted'],
        }
      })
    },
    getSlugs: (component: v0.Date) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.Date) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Duplicate Review': {
    addFunc: (component: v0.DuplicateReview, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Duplicate Review',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          ...(component.content ? {Translation: [add.Translation(component.content as v0.Text)]} : {}),
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify(component),
        }
      })
    },
    getSlugs: (component: v0.DuplicateReview) => {
      return { slugs: [
        ...(component.customDecisions?.map(c => (c as any).sharedKeyField || (c as any).individualDecisionField || '').filter(f => f) || [])
      ] };
    },
    getRequirements: (component: v0.DuplicateReview) => [{
      kind: 'Or',
      clauses: [{
        kind: 'Not',
        clause: {
          kind: 'Exists',
          field: component.flagField
        }
      }, {
        kind: 'Exists',
        field: component.targetField
      }]
    }]
  },
  'Encrypted Value Verification': {
    addFunc: (component: v0.EncryptedValueVerification, add: Add) => {
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          'Field Type': 'Encrypted Value Verification',
          Translation: [add.Translation(component.content)],
          'Target Field': component.targetField,
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Metadata': JSON.stringify({
            sourceField: component.sourceField,
            lastFourOnly: component.lastFourOnly,
          }),
        }
      })
    },
    getSlugs: (component: v0.EncryptedValueVerification) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.EncryptedValueVerification) => []
  },
  'Explanation': {
    addFunc: (component: v0.Explanation, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Explanation',
          Translation: [add.Translation(Array.isArray(component.content) ? { 'en': '' } : component.content)],
          'English Content': '--',
          'Spanish Content': '--',
          ...(Array.isArray(component.content) ? {
            'Metadata': JSON.stringify({
              modernType: component,
            })
          } : {}),
          'Additional Options': [
            'Formatted',
            ...(component.collapsible ? ['Collapsible'] : []),
          ],
        }
      })
    },
    getSlugs: (component: v0.Explanation) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.Explanation) => []
  },
  'Fillable Form': {
    addFunc: (component: v0.FillableForm, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Fillable Form',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          'English Content': '--',
          Metadata: JSON.stringify({ ...component, instructions: w9.instructions } as (v0.FillableForm & v0.CommonProperties))
        }
      })
    },
    getSlugs: (component: v0.FillableForm) => {
      return { slugs: [
        component.form.signatureStorageField as v0.StandaloneSlug,
        '_irs_flag' as v0.SuffixSlug
      ] };
    },
    getRequirements: (component: v0.FillableForm) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Flag': {
    addFunc: (component: v0.Flag, add: Add) => {
      const id = v4();

      type FlagMetadata = {
        unique?: {
          [program: string]: string[]
        },
        fuzzy_unique?: {
          [program: string]: string[]
        },
        required_info?: {
          [program: string]: { [key: string]: string }
        },
        'max_count'?: number,
        'max_sum'?: number,
        'sum_target'?: string,
        'async'?: boolean,
        query?: {
          [deployment: string]: {
            'params': string[],
            'sql': string,
            'depends_on': string[]
          }
        }
      }

      let metadata: FlagMetadata = {
        ...(component.flag.maxCount ? { max_count: component.flag.maxCount } : {}),
      };

      if (component.flag.kind === 'Unique') {
        let unique: FlagMetadata['unique'] = {};
        let fuzzy: FlagMetadata['fuzzy_unique'] = {};
        let requiredInfo: FlagMetadata['required_info'] = {};
        for (const programDef of component.flag.programs) {
          let thisProgram = programDef.program;
          let exactFields = programDef.fields.filter(f => f.mode === 'exact').map(f => f.field);
          let fuzzyFields = programDef.fields.filter(f => f.mode === 'fuzzy').map(f => f.field);
          if (exactFields.length) unique[thisProgram] = exactFields;
          if (fuzzyFields.length) fuzzy[thisProgram] = fuzzyFields;
          let requiredKeys = (programDef.requiredInfo || []).filter(f => f.key);
          if (requiredKeys.length) requiredInfo[thisProgram] = requiredKeys.reduce((acc, cur) => {
            acc[cur.key] = cur.value || '';
            return acc;
          }, {} as Record<string, string>);
        }
        if (Object.keys(unique).length) metadata.unique = unique;
        if (Object.keys(fuzzy).length) metadata.fuzzy_unique = fuzzy;
        if (Object.keys(requiredInfo).length) metadata.required_info = requiredInfo;
      }

      if (component.flag.kind === 'Query Flag') {
        if (!metadata.query) metadata.query = {};
        for (const programDef of component.flag.programs) {
          metadata.query[programDef.program] = {
            sql: programDef.query.sql,
            params: programDef.params || [],
            depends_on: programDef.depends_on || [],
          };
        }
      }

      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Flag',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify({ 
            ...metadata,
            async: component.async
          } as FlagMetadata),
          Translation: [add.Translation(component.content)],
          'Additional Options': [...(component.advisory ? ['Advisory'] : [])]
        }
      })
    },
    getSlugs: (component: v0.Flag) => {
      return { slugs: ['_comment' as v0.SuffixSlug, component.targetField as v0.StandaloneSlug] };
    },
    getRequirements: (component: v0.Flag) => []
  },
  'Household Calculator': {
    addFunc: (component: v0.HouseholdCalculator, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Household Calculation',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': ['Formatted'],
          Metadata: JSON.stringify({
            roles: component.roles,
            title: component.title,
            memberCountLabel: component.memberCountLabel,
            showYou: component.showYou,
            referenceDate: component.referenceDate,
          })
        }
      })
    },
    getSlugs: (component: v0.HouseholdCalculator) => {
      return { slugs: ['_count' as v0.SuffixSlug] };
    },
    getRequirements: (component: v0.HouseholdCalculator) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Income Calculator': {
    addFunc: (component: v0.IncomeCalculator, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Income Calculation',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': ['Formatted'],
          Metadata: JSON.stringify(component)
        }
      })
    },
    getSlugs: (component: v0.IncomeCalculator) => {
      return { slugs: ['_details' as v0.SuffixSlug] };
    },
    getRequirements: (component: v0.IncomeCalculator) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Inline Language Selector': {
    addFunc: (component: v0.InlineLanguageSelector, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          Translation: [add.Translation(component.content)],
          'Field Type': 'Inline Language Selector',
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': ['Formatted'],
        }
      })
    },
    getSlugs: (component: v0.InlineLanguageSelector) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.InlineLanguageSelector) => []
  },
  'InlineNotification': {
    addFunc: (component: v0.InlineNotification, add: Add) => {

      let contentIsArray = {
        'message': Array.isArray(component.initial_notification.message),
        'email_message': Array.isArray(component.initial_notification.email_message)
      };

      if (component.contactMethod !== 'email') {
        add.Question({
          id: v4(),
          createdTime: '',
          fields: {
            Question: component.name + ' - SMS', // This doesn't appear to do anything...
            'Field Type': 'Notification',
            'Who Can Edit': [add.Role('Screener', ['Screener'])],
            'Target Field': component.targetPrefix + '_sms',
            'Translation': [add.Translation(component.initial_notification.message)],
            'English Content': '--',
            'Spanish Content': '--',
            'Additional Options': ['Formatted', 'Optional'],
            Metadata: JSON.stringify({
              links: (component.initial_notification.subsurveys || []).reduce((o, s) => {
                o[s.variable] = s.name;
                return o;
              }, {} as Record<string, string>),
              contact_method: component.contactMethod,
              notify_applicant_phone: true,
              service_sid: component.smsService,
              timezone: component.timezone,
              notify_applicant_phone_key: (component.recipient as v0.CustomContact)?.phoneField,
              notify_applicant_email_key: undefined,
              ...(contentIsArray['message']
                ? { messageBlocks: component.initial_notification.message }
                : {}),
              sql: component.initial_notification.enabled_when.kind === 'SQL'
                ? component.initial_notification.enabled_when.sql
                : CompileExpressionToSQL({
                  cond: {
                    kind: 'And',
                    clauses: [
                      {
                        field: (component.recipient as v0.CustomContact)?.phoneField || 'phone_number',
                        kind: 'Exists'
                      },
                      component.initial_notification.enabled_when.expr
                    ]
                  },
                  orderBy: component.initial_notification.enabled_when.orderBy }),
              js: component.initial_notification.enabled_when.kind === 'Click'
                ? CompileExpressionToJS(component.initial_notification.enabled_when.expr)
                : '',
              name: component.name + ' - SMS',
              inline: true,
              allowedUserTags: component.allowedUserTags,
            }),
          }
        });
      }

      if (component.contactMethod !== 'sms') {
        add.Question({
          id: v4(),
          createdTime: '',
          fields: {
            Question: component.name + ' - Email', // This doesn't appear to do anything...
            'Field Type': 'Notification',
            'Who Can Edit': [add.Role('Screener', ['Screener'])],
            'Target Field': component.targetPrefix + '_email',
            'Translation': [add.Translation(component.initial_notification.email_message || component.initial_notification.message)],
            'English Content': '--',
            'Spanish Content': '--',
            'Additional Options': ['Formatted', 'Optional'],
            Metadata: JSON.stringify({
              links: (component.initial_notification.subsurveys || []).reduce((o, s) => {
                o[s.variable] = s.name;
                return o;
              }, {} as Record<string, string>),
              contact_method: component.contactMethod,
              notify_applicant_email: true,
              subject: component.initial_notification.email_subject,
              from: component.emailSender,
              notify_applicant_phone_key: undefined,
              notify_applicant_email_key: (component.recipient as v0.CustomContact)?.emailField,
              timezone: component.timezone,
              ...(contentIsArray['email_message']
                ? { messageBlocks: component.initial_notification.email_message }
                : contentIsArray['message']
                  ? { messageBlocks: component.initial_notification.message }
                  : {}),
              sql: component.initial_notification.enabled_when.kind === 'SQL'
                ? component.initial_notification.enabled_when.sql
                : CompileExpressionToSQL({
                  cond: {
                    kind: 'And',
                    clauses: [
                      {
                        field: (component.recipient as v0.CustomContact)?.emailField || 'email',
                        kind: 'Exists'
                      },
                      component.initial_notification.enabled_when.expr
                    ]
                  },
                  orderBy: component.initial_notification.enabled_when.orderBy }),
              js: component.initial_notification.enabled_when.kind === 'Click'
                ? CompileExpressionToJS(component.initial_notification.enabled_when.expr)
                : '',
              name: component.name + ' - Email',
              inline: true,
              allowedUserTags: component.allowedUserTags,
            }),
          }
        });
      }
    },
    getSlugs: (component: v0.InlineNotification) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.InlineNotification) => [{
      kind: 'Or',
      clauses: [{
        kind: 'Exists',
        field: component.targetPrefix + '_sms'
      }, {
        kind: 'Exists',
        field: component.targetPrefix + '_email'
      }]
    }]
  },
  'InlineSignature': {
    addFunc: (component: v0.InlineSignature, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Inline Signature',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Content)],
          'English Content': '--',
          'Spanish Content': '--',
          'Metadata': JSON.stringify({
            'anonymous': component.anonymous,
            'signer_name': component.signerNameTargetField,
            ...(Array.isArray(component.content) ? {
              'modernType': component
            } : {}),
            'captureRawSignature': component.captureRawSignature
          }),
        }
      })
    },
    getSlugs: (component: v0.InlineSignature) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.InlineSignature) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Likert': {
    addFunc: (component: v0.Likert, add: Add) => {
      const id = v4();
      add.Question({
        id,
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Likert',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': Array.isArray(component.questions) ? 'foobar' : (component.questions.targetField + '_output'),
          Translation: [add.Translation(component.content as v0.Text)],
          'Options (if relevant)': component.choices.map(add.Choice),
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': [
            'Formatted',
            ...(component.showValueScale ? ['Show Value Scale'] : []),
            ...(component.display === 'horizontal' ? ['Horizontal Display'] : []),
          ],
          Metadata: JSON.stringify(component)
        }
      });
      // Hack for saving multiple target fields
      for (const q of Array.isArray(component.questions) ? component.questions : []) {
        add.Question({
          id,
          createdTime: '',
          fields: {
            Question: id,
            'Field Type': 'Single Select',
            Translation: [add.Translation(q.label as v0.Text)],
            'Who Can Edit': [add.Role('Screener', ['Screener'])],
            'Target Field': q.targetField,
            'Options (if relevant)': component.choices.map(add.Choice),
            'English Content': '--',
            'Spanish Content': '--',
            'Additional Options': ['Formatted', 'Horizontal', 'Hidden'],
          }
        })
      }
    },
    getSlugs: (component: v0.Likert) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.Likert) => [{
      kind: 'And',
      clauses: Array.isArray(component.questions) ? component.questions.map(q => ({
        kind: 'Exists',
        field: q.targetField
      })) : []
    }]
  }, 
  'Liveness Detection': {
    addFunc: (component: v0.LivenessDetection, add: Add) => {
      const id = v4();

      let livenessFieldMap = {
        [component.selfie.targetField]: {
          options: ["Detect Faces"]
        },
        ...(component.identification ? {
          [component.identification.front.targetField]: {
            options: ["Detect Faces", "Extract Text"]
          },
          [component.identification.back.targetField]: {
            options: ["Scan Barcode"]
          }
        } : {})
      }

      for (let field in livenessFieldMap) {
        add.Question({
          id: v4(),
          createdTime: '',
          fields: {
            Question: id,
            "Field Type": 'Attachment',
            'Who Can Edit': [add.Role('Screener', ['Screener'])],
            "Target Field": field,
            Translation: [add.Translation({ en: '--' })],
            'English Content': '--',
            'Spanish Content': '--',
            'Additional Options': [...livenessFieldMap[field].options, 'Hidden', 'Live Attachment'],
          }
        });
      }

      // Add the liveness question (technically is filled out AFTER the previous 3 have been answered)
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Liveness Detection',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': [],
          Metadata: JSON.stringify(component)
        }
      });
    },
    getSlugs: (component: v0.LivenessDetection) => {
      const slugs: Array<v0.StandaloneSlug | v0.SuffixSlug> = [];
      const fields = component.identification ? [component.selfie.targetField, component.identification.front.targetField, component.identification.back.targetField] : [component.selfie.targetField];
      for (const field of fields) {
        slugs.push(
          (field + '_video') as v0.StandaloneSlug,
          (field + '_metadata') as v0.StandaloneSlug,
          (field + '_queue_id') as v0.StandaloneSlug,
        );
      }
      if (component.identification) {
        slugs.push(
          (component.identification.back.targetField + '_barcode_results') as v0.StandaloneSlug,
          (component.identification.back.targetField + '_barcode_results_error') as v0.StandaloneSlug,
          (component.identification.back.targetField + '_barcode_queue_id') as v0.StandaloneSlug,
        );
      }
      return {
        slugs: slugs.concat([
          '_text', // This is from 'Extract Text'
          '_face', // This and below are from 'Detect Faces'
          '_duplicates',
          '_related',
          '_unindexed_reason',
          '_conversion'] as v0.SuffixSlug[])
      };
    },
    getRequirements: (component: v0.LivenessDetection) => [{
      kind: 'And',
      clauses: [...(component.identification ? [{
        kind: 'Exists',
        field: component.identification.front.targetField
      }, {
        kind: 'Exists',
        field: component.identification.back.targetField
      }] : []), {
        kind: 'Exists',
        field: component.selfie.targetField
      }, {
        kind: 'Exists',
        field: component.targetField
      }]
    }] as v0.BooleanExpr[]
  },
  'LoginWidget': {
    addFunc: (component: v0.LoginWidget, add: Add) => {
      const id = v4();
      if ('version' in component && component.version > 1) {
        add.Question({
          id: v4(),
          createdTime: '',
          fields: {
            Question: id,
            'Field Type': 'Login Widget',
            Translation: [add.Translation(component.buttonText as v0.Text)],
            'English Content': 'Check your status',
            'Spanish Content': 'Consulta tu estado',
            Metadata: JSON.stringify(removeContent(component)),
          }
        })
      } else {
        add.Question({
          id: v4(),
          createdTime: '',
          fields: {
            Question: id,
            'Field Type': 'Login Widget',
            'English Content': '--',
            'Spanish Content': '--',
            Metadata: JSON.stringify({
              page: component.page,
            })
          }
        })
      }
    },
    getSlugs: (component: v0.LoginWidget) => {
      return {
        slugs: ('saveContactInfo' in component && component.saveContactInfo)
          ? [component.saveContactInfo.contactInfoField] as v0.SuffixSlug[]
          : []
      };
    },
    getRequirements: (component: v0.LoginWidget) => []
  },
  'Lookup': {
    addFunc: (component: v0.Lookup, add: Add) => {
      const id = v4();

      // Convert sensitive info from an array of field+action to key: value dict
      const sensitive_info: Record<string, 'hide' | 'encrypt' | 'hash'> = {};
      if (component.lookup.kind === 'Dynamo') {
        for (const info of component.lookup.sensitiveInfo || []) {
          sensitive_info[info.field] = info.action;
        }
      }

      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Lookup',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          ...(component.content ? { Translation: [add.Translation(component.content as v0.Text)] } : {}),
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify({
            ...(component.lookup.kind === 'Dynamo' ? {
              kind: 'dynamo_hash',
              // convert queryFields which is a { fieldInDynamo, fieldInAidKit }[] to a string
              query: component.lookup.queryFields.map(q => `${q.fieldInDynamo}:${q.fieldInAidKit}`).join(','),
              config_path: component.lookup.configPath,
              sensitive_info,
              contact_confirmation: component.lookup.contactConfirmationField,
            } : { kind: component.lookup.key, query: component.lookup.queryField }),
            ...(component.lookup.kind === 'Geo' ? { geo: true } : {})
          })
        }
      })
    },
    getSlugs: (component: v0.Lookup) => {
      const slugs: Array<v0.StandaloneSlug | v0.SuffixSlug> = [component.targetField as v0.StandaloneSlug];
      if (component.lookup.kind === 'Dynamo' && component.lookup.queryFields) {
        slugs.push('_decrypted' as v0.SuffixSlug);
      }
      return { slugs };
    },
    getRequirements: (component: v0.Lookup) => []
  },
  'Milestone': {
    addFunc: (component: v0.Milestone, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: component.name,
          'Field Type': 'Stage',
          Translation: [add.Translation({ en: component.name } as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
        }
      })
    },
    getSlugs: (component: v0.Milestone) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.Milestone) => []
  },
  'Number': {
    addFunc: (component: v0.Number, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Number',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': component.encrypted ? ['Formatted', 'Encrypted'] : ['Formatted'],
          Metadata: JSON.stringify(component)
        }
      })
    },
    getSlugs: (component: v0.Number) => {
      return { slugs: component.encrypted ? ['_hash' as v0.SuffixSlug] : [] };
    },
    getRequirements: (component: v0.Number) => [{ kind: 'Exists', field: component.targetField }]
  },
  'NumberWithUnit': {
    addFunc: (component: v0.NumberWithUnit, add: Add) => {
      const id = v4();
      add.Question({
        id,
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Number With Unit',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': 'foobar', // only needed for field validation
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': ['Formatted'],
          Metadata: JSON.stringify({
            questions: [
              {
                type: 'number',
                targetField: component.numberQuestion.targetField
              },
              {
                type: 'unit',
                targetField: component.unitQuestion.targetField,
                choices: component.unitQuestion.choices.map(choice => (
                  {
                    Name: choice.value,
                    ...choice.label
                  }
                ))
              },
            ],
            format: component.format,
            integers_only: component.integers_only,
          })
        }
      });
      // Hidden field hack to make RS happy
      add.Question({
        id,
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Number',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.numberQuestion.targetField,
          'Additional Options': ['Hidden'],
        }
      })
      add.Question({
        id,
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Single Select',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.unitQuestion.targetField,
          'Options (if relevant)': (component.unitQuestion.choices || []).map(add.Choice),
          'Additional Options': ['Hidden'],
        }
      })
    },
    getSlugs: (component: v0.NumberWithUnit) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.NumberWithUnit) => [{
      kind: 'And',
      clauses: [{
        kind: 'Exists',
        field: component.numberQuestion.targetField
      }, {
        kind: 'Exists',
        field: component.unitQuestion.targetField
      }]
    }]
  },
  'Plaid Bank Account': {
    addFunc: (component: v0.PlaidBankAccount, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Plaid Bank Account',
          'Target Field': component.targetField,
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Translation': [add.Translation((component as any).content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify(component)
        }
      })
    },
    getSlugs: (component: v0.PlaidBankAccount) => {
      return {
        slugs: [
          ...[
            '_income',
            '_error',
            '_assets_token',
            '_assets',
            '_assets_error',
            '_assets_ready'
          ] as v0.SuffixSlug[],
          'plaid_user' as v0.StandaloneSlug,
          'plaid_user_income' as v0.StandaloneSlug
        ]
      };
    },
    getRequirements: (component: v0.PlaidBankAccount) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Plaid Income': {
    addFunc: (component: v0.PlaidIncome, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Plaid Income',
          'Target Field': component.targetField,
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify(component)
        }
      })
    },
    getSlugs: (component: v0.PlaidIncome) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.PlaidIncome) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Ranking': {
    addFunc: (component: v0.RankingQuestion, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Ranking',
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Options (if relevant)': component.choices.map(add.Choice),
          Metadata: JSON.stringify({
            num_choices: (component.number_of_required_choices || component.choices.length),
          })
        }
      })
    },
    getSlugs: (component: v0.RankingQuestion) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.RankingQuestion) => [{ kind: 'Exists', field: component.targetField }]
  },
  'ResumeWidget': {
    addFunc: (component: v0.ResumeWidget, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Resume Widget',
          'English Content': '--',
          'Spanish Content': '--',
          'Metadata': JSON.stringify({
            ingestedResumesToSubsurvey: component.ingestedResumesToSubsurvey,
            customButtonText: component.customButtonText
          })
        }
      })
    },
    getSlugs: (component: v0.ResumeWidget) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.ResumeWidget) => []
  },
  'SearchSelect': {
    addFunc: (component: v0.SearchSelect, add: Add) => {
      const id = v4();
      if ('version' in component && component.version > 0) {
        add.Question({
          id: v4(),
          createdTime: '',
          fields: {
            Question: id,
            'Field Type': component.searchType?.kind === 'ServerSide' ? 'Search Select Large Dictionary' : 'Search Select',
            Translation: [add.Translation(component.content as v0.Text)],
            'Who Can Edit': [add.Role('Screener', ['Screener'])],
            'Target Field': component.targetField,
            'English Content': '--',
            'Spanish Content': '--',
            'Additional Options': ['Formatted'],
            Metadata: JSON.stringify({
              max_options: component.maxOptions,
              search_type: component.searchType,
            })
          }
        })
      } else {
        const legacyComponent = component as v0.LegacySearchSelectV0;
        add.Question({
          id: v4(),
          createdTime: '',
          fields: {
            Question: id,
            'Field Type': 'Search Select',
            Translation: [add.Translation(legacyComponent.content as v0.Text)],
            'Who Can Edit': [add.Role('Screener', ['Screener'])],
            'Target Field': legacyComponent.targetField,
            'English Content': '--',
            'Spanish Content': '--',
            'Additional Options': ['Formatted'],
            Metadata: JSON.stringify({
              max_options: legacyComponent.maxOptions,
              search_type: {
                kind: 'ClientSide',
                options_file_url: legacyComponent.optionsFileUrl,
              },
            })
          }
        })
      }
    },
    getSlugs: (component: v0.SearchSelect) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.SearchSelect) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Section': {
    addFunc: (component: v0.Section, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Section',
          Translation: [add.Translation(component.title as v0.Text)],
          Metadata: JSON.stringify({})
        }
      })
    },
    getSlugs: (component: v0.Section) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.Section) => []
  },
  'Select': {
    addFunc: (component: v0.Select, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': component.multiple ? 'Multiple Select' : 'Single Select',
          Translation: [add.Translation(component.content as v0.Text)],
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          'Options (if relevant)': component.choices.map(add.Choice),
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': ['Formatted'],
          Metadata: JSON.stringify({
            max_allowed_selections: component.maxAllowedSelections,
            component,
          })
        }
      })
    },
    getSlugs: (component: v0.Select) => {
      const slugs: Array<v0.StandaloneSlug | v0.SuffixSlug> = [];
      for (const option of (component.choices || [])) {
        // Note: this has changed from its previous logic in RS Other.ts
        if (option.otherField || option.value === 'other' || option.label.en.toLowerCase() === 'other') {
          if (option.value === 'other') {
            slugs.push('_other' as v0.SuffixSlug);
          }
          if (option.otherField && option.value !== 'other') {
            slugs.push('_' + option.value as v0.SuffixSlug);
          }
        }
      }

      return { slugs };
    },
    getRequirements: (component: v0.Select) => [{ kind: 'Exists', field: component.targetField }]
  },
  'SelectFromData': {
    addFunc: (component: v0.SelectFromData, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': component.multiple ? 'Multiple Select' : 'Single Select',
          Translation: [add.Translation(component.content as v0.Text)],
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': ['Formatted'],
          Metadata: JSON.stringify({
            max_allowed_selections: component.maxAllowedSelections,
            source_field: component.sourceField,
          })
        }
      })
    },
    getSlugs: (component: v0.SelectFromData) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.SelectFromData) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Show Field': {
    addFunc: (component: v0.ShowField, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Show Field',
          Translation: [add.Translation(component.headerText as v0.Text)],
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          'Additional Options': [
            ...(component.showsVersions ? ['Shows Versions'] : []),
            ...(component.allowDecrypting ? ['Allow Decrypting'] : [])
          ],
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify({
            keepVisibleForReview: component.keepVisibleForReview
          }),
        }
      })
    },
    getSlugs: (component: v0.ShowField) => {
      return { slugs: [
        ...(component.allowDecrypting ? ['_viewed_by'] : [])
      ] };
    },
    getRequirements: (component: v0.ShowField) => []
  },
  'Similar Document Review': {
    addFunc: (component: v0.SimilarDocumentReview, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Similar Document Review',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          ...(component.content ? {Translation: [add.Translation(component.content as v0.Text)]} : {}),
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify(component),
        }
      })
    },
    getSlugs: (component: v0.SimilarDocumentReview) => {
      return { slugs: [
        '_documentid' as v0.SuffixSlug
      ] };
    },
    getRequirements: (component: v0.SimilarDocumentReview) => [{
      kind: 'Or',
      clauses: [{
        kind: 'Not',
        clause: {
          kind: 'Exists',
          field: component.documentField
        }
      }, {
        kind: 'Exists',
        field: component.targetField
      }]
    }]
  },
  'Similar Document Check': {
    addFunc: (component: v0.SimilarDocumentCheck, add: Add) => {
      if (component.showStatus) {
        const id = v4();
        add.Question({
          id: v4(),
          createdTime: '',
          fields: {
            Question: id,
            'Field Type': 'Similar Document Check',
            'Who Can Edit': [add.Role('Screener', ['Screener'])],
            'Target Field': component.targetField,
            'English Content': '--',
            'Spanish Content': '--',
            Metadata: JSON.stringify(component),
          }
        })
      }
    },
    getSlugs: (component: v0.SimilarDocumentCheck) => {
      return { slugs: ['_timestamp', '_depshash'] };
    },
    getRequirements: (component: v0.SimilarDocumentCheck) => []
  },
  'SubmitButton': {
    addFunc: (component: v0.SubmitButton, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Submit Button',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify({
            custom_text: component.customText,
            hide_request_changes_button: component.hideRequestChangesButton,
          })
        },
      })
    },
    getSlugs: (component: v0.SubmitButton) => {
      return { slugs: ['_server_time']}
    },
    getRequirements: (component: v0.SubmitButton) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Subsurvey': {
    addFunc: (component: v0.Subsurvey, add: Add) => {
      let sections = component.sections.map((s) => add.Section(s, add.Depth + 1));
      // Add the final subsurvey question

      const expiration = component.defaultExpiration;
      let legacyExpiration: [number, 'days'] = [365, 'days'];
      if (expiration) {
        legacyExpiration = [expiration.days + expiration.weeks * 7 + expiration.months * 30, 'days'];
      }

      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: component.path,
          ...(component.title ? { Translation: [add.Translation(component.title as v0.Text)] } : {}),
          'Field Type': 'Subsurvey',
          'English Content': '--',
          'Spanish Content': '--',
          Subquestions: sections,
          Metadata: JSON.stringify({
            autofill_sections: true,
            needs_key: component.anonymous ? false : true,
            conditional: 'false',
            default_expiration: legacyExpiration,
            custom_submitted_modal: component.customSubmittedModal,
            ...(component.redirectToSubsurvey ? { redirect_on_submit: '/p/' + component.redirectToSubsurvey } : {}),
            branding: component.branding,
            should_fast_forward: component.shouldFastForward,
            hideFromNavigation: component.hideFromNavigation,
            block_further_edits: component.blockFurtherEdits,
            accessConditional: component.accessConditional,
            sectionBottomButtons: component.sectionBottomButtons,
          })
        }
      });

      if (component.protectedSearch) {
        add.Configuration({
          kind: 'update',
          key: 'protected_search',
          update: (old) => {
            let parsed = JSON.parse(old || '{}');
            parsed[component.path] = {
              'sql': CompileExpressionToSQL({ cond: component.protectedSearch!.condition.expr, 
                orderBy: component.protectedSearch!.condition.orderBy }),
              'roles': component.protectedSearch!.roles.join(','),
              ...(component.protectedSearch!.sendLink ? { 'send_link': component.protectedSearch?.sendLink } : {}),
              ...(component.protectedSearch!.viewInfo ? {
                'view_info': component.protectedSearch!.viewInfo.reduce((acc, cur) => {
                  if (!cur.key) return acc;
                  acc[cur.key] = cur.value || '';
                  return acc;
                }, {} as Record<string, string>)
              } : {}),
              ...(component.protectedSearch!.assigns ? { 'assigns': component.protectedSearch!.assigns } : {}),
              ...(component.protectedSearch!.mode ? { 'mode': component.protectedSearch!.mode } : {}),
              ...(component.protectedSearch!.displayFields ? { 'displayFields': component.protectedSearch!.displayFields } : {})
            }
            return JSON.stringify(parsed);
          }
        })
      }
    },
    getSlugs: (component: v0.Subsurvey) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.Subsurvey) => []
  },
  'SupportButton': {
    addFunc: (component: v0.SupportButton, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Support Button',
          Translation: [
            add.Translation(component.messageTextOverride as v0.Text),
            add.Translation(component.buttonTextOverride as v0.Text),
          ],
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify(component)
        }
      })
    },
    getSlugs: (component: v0.Consent) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.Consent) => []
  },
  'TextEntry': {
    addFunc: (component: v0.TextEntry, add: Add) => {
      const id = v4();
      let fieldType = 'Single Line Text Entry';
      const additionalOptions = ['Formatted'];
      if (component.format === 'legalName') {
        additionalOptions.push('Legal Name');
      } else if (component.format === 'email') {
        fieldType = 'Email';
      } else if (component.format === 'phone') {
        fieldType = 'Phone Number';
      } else if (component.multiline) {
        fieldType = 'Multiline Text Entry';
      }
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': fieldType,
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          'Additional Options': additionalOptions,
          Metadata: JSON.stringify({
            min_length: component.minLength,
            max_length: component.maxLength,
            confirmation_field: component.confirmationField, // for phone and email questions
          })
        }
      })
    },
    getSlugs: (component: v0.TextEntry) => {
      return { slugs: [] };
    },
    getRequirements: (component: v0.TextEntry) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Usio Mailing': {
    addFunc: (component: v0.UsioMailing, add: Add) => {
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: 'Usio Mailing',
          'Field Type': 'Payment',
          'Target Field': component.targetField,
          'English Content': '--',
          'Spanish Content': '--',
          'Metadata': JSON.stringify({
            amount: '0',
            type: 'usio_mail',
            address: component.mailingAddress,
            sql: CompileExpressionToSQL({ cond: component.condition.expr, orderBy: component.condition.orderBy }),
            autoAssociate: component.autoAssociate
          }),
        }
      });
    },
    getSlugs: (component: v0.UsioMailing) => {
      return {
        slugs: ['_issued' as v0.SuffixSlug, '_card_id' as v0.SuffixSlug]
      };
    },
    getRequirements: (component: v0.UsioMailing) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Validated': {
    addFunc: (component: v0.Validated, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Validated',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'Target Field': component.targetField,
          Translation: [add.Translation(component.content as v0.Text)],
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify({
            formula: (component.formula as any) || ''
          }),
          'Additional Options': [
            ...(component.advisory ? ['Advisory'] : [])
          ]
        }
      })
    },
    getSlugs: (component: v0.Validated) => {
      return {
        slugs: [ '_error' as v0.SuffixSlug ]
      };
    },
    getRequirements: (component: v0.Validated) => [{ kind: 'Exists', field: component.targetField }]
  },
  'Related Applicants': {
    addFunc: (component: v0.RelatedApplicants, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Related Applicants',
          'Who Can Edit': [add.Role('Screener', ['Screener'])],
          'English Content': '--',
          'Spanish Content': '--',
          Metadata: JSON.stringify(component),
        }
      })
    },
    getSlugs: () => ({ slugs: [] }),
    getRequirements: (component: v0.RelatedApplicants) => component.byKeys.map(key => ({ kind: 'Exists', field: key }))
  },
  'Third Party Check': {
    addFunc: (component: v0.ThirdPartyCheck, add: Add) => {
      const id = v4();
      add.Question({
        id: v4(),
        createdTime: '',
        fields: {
          Question: id,
          'Field Type': 'Third Party Check',
          'English Content': '--',
          'Spanish Content': '--',
        }
      })
    },
    getSlugs: (component: v0.ThirdPartyCheck) => ({ 
      slugs: [
        '_status',
        '_depshash',
        '_depshash1',
        '_depshash2',
      ]
    }),
    getRequirements: (component: v0.RelatedApplicants) => []
  }
}
