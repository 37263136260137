import React, { Dispatch, useState } from "react";
import { QuestionProps } from "./Questions/Props";

import type { SupportedLanguage } from '@aidkitorg/i18n/lib';
import type { PublicConfig, LoggedInConfig } from "@aidkitorg/types/lib/config";
import { Call, Device } from "@twilio/voice-sdk";
import { SyncState, SyncStatus } from "./offline/routes";
export { SupportedLanguage }

export type ForbiddenCopy = undefined | Record<string, Record<SupportedLanguage, string | undefined>>;

const initialInterfaceContext: {
  activeRequests: Set<string>;
  setRequestActive: (req: string, active: boolean) => void;
  lang: SupportedLanguage;
  setLanguage: (lang: SupportedLanguage) => void;
  textAlign: "left" | "right" | "center";
  banner?: Record<SupportedLanguage | '__style', string>
  staffBanner?: Record<SupportedLanguage | '__style', string>
  setBanner: (banner: Record<SupportedLanguage, string>) => void;
  setStaffBanner: (banner: Record<SupportedLanguage, string>) => void;
  forbiddenCopy?: ForbiddenCopy;
  setForbiddenCopy: React.Dispatch<React.SetStateAction<ForbiddenCopy>>;
  audioPlayer?: HTMLAudioElement;
  setAudioPlayer: React.Dispatch<React.SetStateAction<HTMLAudioElement | undefined>>;
} = {
  activeRequests: new Set(),
  setRequestActive: (req, active) => {},
  lang: "en",
  setLanguage: (lang: string) => {},
  textAlign: "left",
  setBanner: (banner: Record<SupportedLanguage, string>) => {},
  setStaffBanner: (banner: Record<SupportedLanguage, string>) => {},
  setForbiddenCopy: () => {},
  setAudioPlayer: () => {}
};
const InterfaceContext = React.createContext(initialInterfaceContext);

export const PublicConfigurationContext = React.createContext({} as PublicConfig);
// The value for loggedIn context will be undefined if the user is not logged in.
export const LoggedInConfigurationContext = React.createContext<LoggedInConfig | undefined>(undefined);

const initialConfigurationContext: {
  [key: string]: any;
} = {};
export const ConfigurationContext = React.createContext(initialConfigurationContext)

export const TwilioDeviceContext = React.createContext({} as {
  twilioDevice: Device | null;
  twilioCall: Call | null,
  twilioCaller: string | null,
  setTwilioCall: (call: Call | null) => void;
  twilioVoiceChannel: string,
  resetTwilioVoiceChannel: (channel: string) => Promise<void>;
});

export const SurveyContext = React.createContext({} as { 
  sections: Array<QuestionProps & { 'Questions': QuestionProps[] }>, 
  stages?: string[],
  expire_date?: string,
  is_dashboard?: boolean
});

export const AuthContext = React.createContext({} as {
  token?: () => string;
  setToken: (token: string | null) => void;
  localId?: () => string;  
  setLocalId: (localId: string) => void;
});

export const UserInfoContext = React.createContext({} as {
  uid?: string,
  tags?: string[],
  refreshContext?: () => void,
});

export type OfflineStatus = 'unloaded'|'warming'|'warmed'|'syncing'|'synced'|'failed';

export const OnlineAwarenessContext = React.createContext({ 
  online: true,
  status: 'unloaded',
} as { 
  online: boolean,
  status: OfflineStatus
});

export const OfflineSyncContext = React.createContext({
  statuses: {}
} as {
  statuses: Record<string, SyncState>
});

export default InterfaceContext;
