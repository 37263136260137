import { Block, Collection, DashboardSection, ExpandedSurvey, Field, RichText, Root, Subsurvey, SubsurveySummary, Survey, ValueExpr } from "../survey";
import { TRANSLATORS } from "./questions";

export function jsonReplace(obj: any, substitutions: {key: string, value: string}[]): any {
  if (!(String.prototype as any).replaceAll) {
    (String.prototype as any).replaceAll = function(str: string, newStr: string){
	
      // If a regex pattern
      if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
        return this.replace(str, newStr);
      }
	
      // If a string
      return this.replace(new RegExp(str, 'g'), newStr);
    };
  }

  // Sort substitutions to make sure we replace the longest strings first
  const sortedSubstitutions = substitutions.sort((a, b) => b.key.length - a.key.length);

  if (Array.isArray(obj)) {
    return obj.map((o: any) => jsonReplace(o, sortedSubstitutions));
  } else if (typeof obj === 'object') {
    return Object.keys(obj).reduce((o: any, k: string) => {
      o[k] = jsonReplace(obj[k], sortedSubstitutions);
      return o;
    }, {});
  } else if (typeof obj === 'string'){
    if (sortedSubstitutions.length) {
      let toReturn = obj as any;
      for (const {key, value} of sortedSubstitutions) {
        toReturn = toReturn.replaceAll(key, value);
      }
      return toReturn;
    }
  }
  return obj;
}

export function expandGenericTemplates(obj: any): any {
  if (Array.isArray(obj)) {
    const toReturn = obj.map((o: any) => expandGenericTemplates(o));
    return toReturn.reduce((acc, o) => {
      if(!o) {
        return acc;
      }
      else if (o.kind === 'Templated Block') {
        for (const iter of o.iterations) {
          const toInsert = jsonReplace(o.components, iter.substitutions);
          acc.push(...toInsert);
        }
      } else {
        acc.push(o);
      }
      return acc;
    }, []);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((o: any, k: string) => {
      o[k] = expandGenericTemplates(obj[k]);
      return o;
    }, {});
  }
  return obj;
}

export function addGeneratedSubsurveys(obj: Root): Root {
  function processCollection(collection: Collection): Collection {
    // Permissions are based on Collections, so we need to append subsurveys to their nearest parent Collection
    const localSubsurveysToAppend: Subsurvey[] = [];
    let newComponents = collection.components.flatMap((component: any) => {
      return processObjectOrArray(component, localSubsurveysToAppend);
    });

    // Filter subsurveys that don't already exist
    const subsurveysToAdd = localSubsurveysToAppend.filter(subsurvey =>
      !newComponents.some((component: any) => component.kind === 'Subsurvey' && component.path === subsurvey.path)
    );

    return { ...collection, components: [...newComponents, ...subsurveysToAdd] };
  }
  function processObjectOrArray(item: any, subsurveysToAppend: Subsurvey[]): any {
    // Create a subsurvey for each attachment that has allowSendingLinkToUpload set to true
    // these can be shared with applicants via linked subsurveys.
    if (item.kind === 'Attachment' && item.allowSendingLinkToUpload === true) {
      const subsurvey: Subsurvey = {
        kind: 'Subsurvey',
        path: item.targetField + '_automated_collection_form',
        defaultExpiration: {days: 1, weeks: 0, months: 0},
        hideFromNavigation: true,
        sections: [{
          kind: 'Section',
          // TODO: use localization once it is moved to it's own package so we don't have to maintain these translations here.
          title: {
            'en': 'Upload Document',
            'es': 'Subir documento',
            'zh_CN': '上传文档',
            'ar': 'تحميل الوثيقة',
            'fr': 'Télécharger le document',
          },
          components: [{
            ...item, // duplicate the attachment component
            allowSendingLinkToUpload: false,
          }]
        }]
      };
      subsurveysToAppend.push(subsurvey);
      return { ...item };
    } else if (item.kind === 'Collection') {
      return processCollection(item);
    } else if (Array.isArray(item)) {
      return item.map(i => processObjectOrArray(i, subsurveysToAppend));
    } else if (item.survey) {
      item.survey = processObjectOrArray(item.survey, subsurveysToAppend);
    } else if (['Section', 'Conditional Block'].includes(item.kind)) {
      item.components = processObjectOrArray(item.components, subsurveysToAppend);
    } else if (['Subsurvey'].includes(item.kind)) {
      item.sections = processObjectOrArray(item.sections, subsurveysToAppend);
    }
    return item;
  }

  const subsurveysToAppend: Subsurvey[] = [];
  const expanded = processObjectOrArray(obj, subsurveysToAppend);

  if (subsurveysToAppend.length) {
    // append the subsurveys to the root of the expanded survey if they aren't in a Collection
    if (Array.isArray(expanded)) {
      return [
        ...expanded,
        ...subsurveysToAppend.filter(subsurvey => !expanded.some((component: any) => component.kind === 'Subsurvey' && component.path === subsurvey.path))
      ];
    } else {
      expanded.survey = [
        ...expanded.survey,
        ...subsurveysToAppend.filter(subsurvey => !expanded.survey.some((component: any) => component.kind === 'Subsurvey' && component.path === subsurvey.path))];
      return expanded;
    }
  }

  return expanded;
}

function snakeToEnglish(str: string) {
  return str
    .replace(/_([a-z])/g, (c) => " " + c.slice(1).toUpperCase())
    .replace(/^[a-z]/g, (c) => c.toUpperCase());
}

export function expandSurveyDashboards(root: Survey) {
  const subsurveys: Record<string, Subsurvey> = {};
  function collectSubsurveys(s: Survey | Block[]) {
    for (const component of s) {
      if (component.kind === 'Collection') {
        collectSubsurveys(component.components);
      }
      if (component.kind === 'Section') {
        collectSubsurveys(component.components);
      }
      if (component.kind === 'Subsurvey') {
        subsurveys[component.path] = component;
      }
    }
  }
  collectSubsurveys(root);

  function addTargetField(targetField: string, targetFields: string[], summary: SubsurveySummary) {
    if (targetFields.includes(targetField)) {
      return;
    }
    if (summary.excludeFields?.includes(targetField)) {
      return;
    }
    targetFields.push(targetField);
  }

  // Any question type included in this array will have its derived fields included in the
  // subsurvey summary downloadable CSV
  const includeDerivedFieldsOf: Block['kind'][] = [
    'Select',
  ];

  function traverse(obj: any): any {
    if (Array.isArray(obj)) {
      const toReturn = obj.map((o: any) => traverse(o));
      return toReturn.reduce((acc, o) => {
        if (o.kind !== 'Subsurvey Summary') {
          acc.push(o);
        } else {
          // Everything in this else-clause is only for subsurvey summaries.
          const summary = o as SubsurveySummary;
          const subsurvey = subsurveys[summary.path];

          let toAnalyze = {
            submit: '',
            toFilter: summary.populationCrossSections || [],
            crossSectionType: {} as Record<string, 'categorical' | 'income' | 'age'>,
          }

          if (!subsurvey) return [];

          // First we want to look for any submit buttons to use for completion analysis
          function exploreForAnalysis(chunk: (typeof subsurvey)['sections'][number]['components']) {
            for (const component of chunk) {
              if (component.kind === 'Templated Block') {
                throw new Error('Templated blocks haven\'t been expanded');
              } else if (component.kind === 'Conditional Block' || component.kind === 'Section') {
                exploreForAnalysis(component.components);
              } else if (component.kind === 'Subsurvey') {
                for (const section of component.sections) {
                  exploreForAnalysis(section.components);
                };
              } else {
                if (component.hidden === true || summary.excludeFields?.includes((component as any).targetField)) {
                  continue;
                }
                if ((component as any).targetField) {
                  if (toAnalyze.toFilter.includes((component as any).targetField)) {
                    if (component.kind === 'Select') {
                      toAnalyze.crossSectionType[(component as any).targetField] = 'categorical';
                    }
                    if (component.kind === 'Number') {

                    }
                    if (component.kind === 'Date') {
                      if (component.targetField.toLowerCase().includes('age')
												|| component.targetField.toLowerCase().includes('birth')
												|| component.targetField.toLowerCase().includes('dob')) {
                        toAnalyze.crossSectionType[(component as any).targetField] = 'age';
                      }
                    }
                  }
                  if (component.kind == 'SubmitButton') {
                    toAnalyze.submit = component.targetField;
                  }
                } else if (component.kind === 'Likert' && Array.isArray(component.questions)) {
                  for (const subQuestion of component.questions) {
                    if (toAnalyze.toFilter.includes(subQuestion.targetField)) {
                      toAnalyze.crossSectionType[subQuestion.targetField] = 'categorical';
                    }
                  }
                }
              }
            }
          }
          subsurvey.sections.map((s) => exploreForAnalysis(s.components));

          const populationSection: DashboardSection = {
            kind: 'Dashboard Section',
            title: 'Completion Rate Analysis',
            components: [],
            showAllSimultaneously: false,
            filters: [],
          };
          for (const crossSection of summary.populationCrossSections || []) {
            // For now just assume that everything is categorical
            toAnalyze.crossSectionType[crossSection] ||= 'categorical';
            if (!toAnalyze.crossSectionType[crossSection] || !toAnalyze.submit) {
              continue;
            }
						
            let group: ValueExpr; 
            if (toAnalyze.crossSectionType[crossSection] === 'categorical') {
              group = {
                kind: 'Field',
                field: crossSection,
              }
            } else {
              continue; 
            }

            if (!o.downloadOnly) {
              populationSection.components.push({
                kind: 'Dashboard Section',
                title: snakeToEnglish(crossSection),
                showAllSimultaneously: false,
                filters: [],
                components: [
                  {
                    "kind":"Bar Chart",
                    "title": { en: "Completion Rate By Category" },
                    "groups":[group],
                    "filter":{
                      "kind":"Exists",
                      'field': crossSection,
                    },
                    "summary":{
                      "kind":"Mean",
                      "value":{
                        "kind":"When",
                        "when":[
                          {
                            "cond":{
                              "field":toAnalyze.submit,
                              "kind":"Exists"
                            },
                            "then":{
                              "kind":"NumericValue",
                              "value":1
                            }
                          }
                        ],
                        "otherwise":{
                          "kind":"NumericValue",
                          "value":0
                        }
                      }
                    }
                  },
                  {
                    "kind":"Bar Chart",
                    "title": { en: "Completion Count By Category" },
                    "groups":[group],
                    "filter":{
                      kind: 'And',
                      clauses: [{
                        "kind":"Exists",
                        'field': crossSection,
                      }, {
                        "kind":"Exists",
                        'field': toAnalyze.submit,
                      }]
                    },
                    "summary":{
                      "kind":"Count"
                    }
                  },
                  {
                    "kind":"Pie Chart",
                    "title": { en: "Population Distribution" },
                    "summary":{
                      "kind":"Count"
                    },
                    "groups":[group],
                    "filter":{
                      "kind":"Exists",
                      'field': crossSection,
                    }
                  }
                ]});
            }
						
            if(populationSection.components?.length){
              acc.push(populationSection);
            }

          }

          // Response summaries
          const dashboardSection: DashboardSection = {
            kind: 'Dashboard Section',
            title: 'Question Analysis',
            components: [],
            showAllSimultaneously: false,
            filters: [],
          };
          const targetFields: string[] = [];
          function traverseChunk(chunk: (typeof subsurvey)['sections'][number]['components'], parent: DashboardSection, title?: string) {
            if (title) {
              const dashboardSection: DashboardSection = {
                kind: 'Dashboard Section',
                title: 'Section - ' + title,
                components: [],
                showAllSimultaneously: false,
                filters: [],
              };
              parent.components.push(dashboardSection);
              parent = dashboardSection;
            }

            for (const component of chunk) {
              if (component.kind === 'Templated Block') {
                throw new Error('Templated blocks haven\'t been expanded');
              } else if (component.kind === 'Conditional Block' || component.kind === 'Section') {
                traverseChunk(component.components, parent);
              } else {
                if (component.hidden === true) {
                  continue;
                }
                if ((component as any).targetField) {
                  addTargetField((component as any).targetField, targetFields, summary);
                  // Add derived fields if question type is included in includeDerivedFieldsOf
                  if (includeDerivedFieldsOf.includes(component.kind) && Object.keys(TRANSLATORS).includes(component.kind)) {
                    TRANSLATORS[component.kind as keyof typeof TRANSLATORS]
                      .getSlugs(component as any).slugs
                      .forEach(slug => addTargetField(slug.startsWith('_') ? (component as any).targetField + slug : slug, targetFields, summary));
                  }
                } else if (component.kind === 'Likert' && Array.isArray(component.questions)) {
                  for (const question of component.questions) {
                    if (question.targetField) {
                      addTargetField(question.targetField, targetFields, summary);
                    }
                  }
                }
                if (summary.excludeFields?.includes((component as any).targetField)) {
                  continue;
                }
                if (!summary.downloadOnly) {
                  switch (component.kind) {
                    case 'Select':
                      if (component.multiple) {
                        parent.components.push({
                          kind: 'Bar Chart',
                          title: { en: snakeToEnglish(component.targetField) },
                          groups: [{
                            kind: 'Field',
                            field: component.targetField,
                          }],
                          summary: {
                            kind: 'Count',
                          },
                          filter: {
                            field: component.targetField,
                            kind: 'Exists',
                          }
                        });
                      } else {
                        parent.components.push({
                          kind: 'Pie Chart',
                          title: { en: snakeToEnglish(component.targetField) },
                          groups: [{
                            kind: 'Field',
                            field: component.targetField,
                          }],
                          summary: {
                            kind: 'Count',
                          },
                          filter: {
                            field: component.targetField,
                            kind: 'Exists',
                          }
                        });
                      }
                      break;
                    case 'Likert':
                      for (const subQuestion of Array.isArray(component.questions) ? component.questions : []) {
                        if (summary.excludeFields?.includes((subQuestion as any).targetField)) {
                          continue;
                        }
                        parent.components.push({
                          kind: 'Pie Chart',
                          title: { en: snakeToEnglish(subQuestion.targetField) },
                          groups: [{
                            kind: 'Field',
                            field: subQuestion.targetField,
                          }],
                          summary: {
                            kind: 'Count',
                          },
                          filter: {
                            field: subQuestion.targetField,
                            kind: 'Exists',
                          }
                        })
                      }
                      break;
                    case 'SubmitButton':
                      parent.components.push({
                        kind: 'Pie Chart',
                        title: { en: snakeToEnglish(component.targetField) },
                        groups: [
                          {"kind":"When","when":[
                            {"cond":{"field":component.targetField,"kind":"Exists"},
                              "then":{"kind":"StringValue","value":"yes"}}],
                          "otherwise":{"kind":"StringValue","value":"no"}}
                        ],
                        summary: {
                          kind: 'Count',
                        }
                      });
                      break;
                    case 'Confirmation':
                      break;
                  }
                }
              }
            }
          }
          subsurvey.sections.map((s) => traverseChunk(s.components, dashboardSection, s.title.en));
          // Filter any sections that don't have anything
          dashboardSection.components = dashboardSection.components.filter((c) => c.kind !== 'Dashboard Section' || c.components.length > 0);
          if (!summary.hideDownload) {
            dashboardSection.components.push({
              kind: 'Applicant Table',
              columns: [...summary.additionalColumns || [], 
                ...targetFields.map((f) => ({
                  'kind': 'Field',
                  field: f
                })) as Field[]],
              filter: {
                kind: 'Not',
                clause: {
                  kind: 'Never'
                }
              },
              download: {
                filename: summary.path,
                downloadOnly: true,
              }
            });
          }
          acc.push(dashboardSection);
        }
        return acc;    
      }, []);
    } else if (typeof obj === 'object') {
      return Object.keys(obj).reduce((o: any, k: string) => {
        o[k] = traverse(obj[k]);
        return o;
      }, {});
    } 	
    return obj;
  }

  return traverse(root);
}
